.mobile-contact-section {
  padding: 50px 0;
}
.mobile-contact-section .card {
  border: 1px solid #0075bb;
  position: relative;
  padding: 10px;
  background: #0075bb;
  overflow: hidden;
  border-radius: 15px;
  color: hsl(54.9, 96.7%, 88%);
}

.mobile-contact-section .card::before {
  content: "";
  width: 1000px;
  height: 1000px;
  position: absolute;
  left: 50%;
  top: 50%;
  animation: rotating 2s infinite cubic-bezier(0.45, 0, 0.55, 1);
  transform-origin: center;
  background: conic-gradient(
    rgba(255, 255, 255, 0.6),
    transparent,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent,
    transparent,
    rgba(255, 255, 255, 0.6)
  );
}

.mobile-contact-section .card::after {
  content: "";
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  left: 4px;
  top: 4px;
  background: inherit;
  border-radius: 0.8rem;
  z-index: 1;
}

.mobile-contact-section .card-content {
  position: relative;
  z-index: 10;
  text-align: center;
}

@keyframes rotating {
  from {
    transform: translate(-500px, -500px) rotate(0deg);
  }
  to {
    transform: translate(-500px, -500px) rotate(360deg);
  }
}

.mobile-contact-section .logo img {
  width: 50px;
  height: auto;
}

.mobile-contact-section .name h4 {
  font-size: 15px;
  color: #ffffff;
}
.mobile-contact-section a {
  font-size: 14px !important;
  font-weight: 700;
  color: #3cff00;
}
.mobile-contact-section button {
  font-size: 14px !important;
  font-weight: 700;
  color: #3cff00;
}

.mobile-contact-section .name {
  text-align: start;
}
.mobile-contact-section span {
  display: block;
  font-size: 12px;
  color: #cbcaca;
}

.assets-area .mobile-contact-section {
  padding: 0;
}

.assets-area .card {
  padding: 0;
}

.mobile-contact-section h5{
  font-size: 3vw;
  line-height: 4vw;
  color: #FCD535 !important;
  padding: 2vw 0;
}