.user-loan-confirm-page-section h5{
    font-size: 20px;
    color: #fff;
}
.user-loan-confirm-page-section h6{
    font-size: 20px;
    text-align: center;
    padding: 5px;
}
.user-loan-details-area .card{
    background-color: transparent;
}

.user-details-card {
    padding-bottom: 5px;
}
.user-loan-details-area ul{
    padding-top: 20px;
}
.user-details-card span:nth-child(1){
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding-right: 10px;
}
.user-details-card span:nth-child(2){
    font-size: 12px;
    font-weight: 400;
    color: #bab8b8;
}

.user-loan-confirm-page-section{
    padding: 20px 0 50px 0;
}
.user-loan-confirm-page-section input{
   width: 100%;
}