.mobile-home-main{
    /* background-color: #181A20; */
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    padding-bottom: 90px;
    min-height: 100vh;
}

.mobile-home-main  .h1, .mobile-home-main .h2, .mobile-home-main .h3, .mobile-home-main .h4, .mobile-home-main .h5, .mobile-home-main .h6, .mobile-home-main h1, .mobile-home-main h2, .mobile-home-main h3, .mobile-home-main h4, .mobile-home-main h5, .mobile-home-main h6 {
    color: #fff !important;
}

/* --color-BtnBg: #FCD535; */
/* #2EBD7C */

.mobile-home-main  .mining-area .card-custom {
background-color: #179C85;
border: 2px solid #0df28b;
}
.mobile-home-main  .mining-area .form-control{
    background-color: #181A20 !important;
    color: #fff !important;
    border: 1px solid transparent;
}
.mobile-home-main  .mining-area .form-control::placeholder{
    color: #fff !important;
}
.mobile-home-main  .mining-area .form-control:focus{
border: 1px solid #FCD535;
}
.mobile-home-main  .expired_time-css{
background-color: rgb(8, 178, 8);
}
.mobile-home-main  .mining-area .text-box{
background-color: rgb(8, 178, 8);
}
.mobile-home-main  .mining-area .badge-custom {
background-color: rgb(8, 178, 8);
}
.mobile-home-main  .hvr-shutter-in-horizontal:before {
    background: linear-gradient(45deg, rgb(8, 178, 8), rgb(4, 117, 4)) !important;
}
.mobile-home-main  .investment-plan-section .pricingTable:before {
    background: rgb(8, 178, 8);
}
.mobile-home-main  .investment-plan-section .pricingTable .pricingTable-header:after {
    background: linear-gradient(to bottom, #136520 0%, #179C85 100%);
}
.mobile-home-main  .investment-plan-section .pricingTable .pricingTable-header {
    background: linear-gradient(to bottom, rgb(8, 178, 8) 0%, #409c17 100%);
}
.mobile-home-main  .investment-plan-section .pricingTable .pricingTable-signup button {
    background: #FCD535;
    color: #000;
}
.mobile-home-main  .investment-plan-section .pricingTable {
    background: linear-gradient(to bottom, rgb(8, 178, 8) 0%, #409c17 100%);
}
.mobile-home-main  .investment-plan-section .pricingTable .price-value span {
    background: linear-gradient(to bottom, rgb(8, 178, 8) 0%, #409c17 100%);
}
.mobile-home-main  .investment-plan-section .pricingTable .pricing-content li {
    line-height: 18px;
}
.mobile-home-main  .pricingTable-signup{
    margin-top: 15px;
}
.mobile-home-main  .user-loan-area .mobile-loan-card-tem-one .card, .user-loan-area .mobile-loan-card-tem-one .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, rgb(8, 178, 8), rgb(29 86 23));
}
.mobile-home-main  .user-loan-area .title h2{
    padding-top: 15px;
}

.mobile-home-main  .user-loan-area .loan-card form {
    width: 95%;
    margin: auto;
    padding-bottom: 20px;
}
.mobile-home-main  .user-loan-area .input-group-text {
    color: #000;
    background-color: #FCD535;
 
}
.mobile-home-main  .user-loan-area .mobile-loan-card-tem-two .card, .user-loan-area .mobile-loan-card-tem-two .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, rgb(29 86 23) , rgb(8, 178, 8));
}
.mobile-home-main  .user-loan-area .mobile-loan-card-tem-three .card, .user-loan-area .mobile-loan-card-tem-three .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, rgb(23 86 68), rgb(127 178 18));
}
.mobile-home-main .flex-grow-1   .text-base{
    color: #aba9a9;
} 


@media (min-width: 355px) and (max-width: 400px) {
.mobile-home-main{
    padding: 0 3vw 70px 3vw;
    margin: auto;
}
}
@media (min-width: 400.1px) and (max-width: 600px) {
.mobile-home-main{
    padding: 0 3vw 70px  3vw;
    margin: auto;
}
}

@media (min-width: 0px) and (max-width: 354.9px) {
.mobile-home-main{
    padding: 0 2vw 70px  2vw;
    margin: auto;
}
}

