/* @media (min-width: 768px) and (max-width: 1199.98px) { ... } */

@media (min-width: 980px) and (max-width: 1205px) {
  .intro-block .intro-label {
    font-size: 12px !important;
  }
}

@media (min-width: 0px) and (max-width: 980px) {
  .mobile-page-number {
    display: none;
}
  .search-bar-area .row{
    display: block;
  }
  .calci_bg {
    padding: 20px 0px 0px !important;
}
  .calci_bg .col-lg-4{
    padding-bottom: 10px;
  }
  .logo_container {
    text-align: center;
  }
  .logo_container a {
    font-size: 28px;
  }
  /* {<!-- diligent area  -->} */

  .et_pb_row_full_witdh {
    width: 100%;
  }

  .intro-section .intro-block {
    width: 100%;
  }
 
  .et_pb_section {
    padding: 150px 0 !important;
  }

  .py-80{
    padding: 80px 0px !important;
  }

  
  .py-50 {
    padding: 50px 0 !important;
}
  .intro-block .intro-label {
    font-size: 10px !important;
  }

  .intro-stat-block {
    padding: 0 15px;
  }

  .intro-section .intro-block {
    padding-bottom: 30px;
  }

  .intro-statement {
    text-align: center;
  }
  .intro-statement h1 {
    color: #000;
  }

  /* {<!-- ABOUT PROSPECT CAPITAL -->} */

  .et_pb_section.et_pb_section_1.et_section_regular.et_section_transparent {
    background: #fff;
  }

  .about-prospect-capital-card-area {
    --bs-gutter-x: 0 !important;
  }
  .about-prospect-capital-card-area .col-3 {
    width: 100% !important;
    margin-bottom: 25px;
  }

  /* {<!-- -----------------------------------QUOTE  ----------------------------------- -->} */

  .testimonial-quote {
    margin: auto 5%;
    font-size: 18px;
  }

  .testimonial-sig {
    margin-top: 25px;
    font-size: 14px;
  }

  /* {<!-- ----------------------------------- CHARTS  ----------------------------------- -->} */

  .chart-block .et_pb_column:first-child {
    border-right: none;
  }

  .charts-area .col-6 {
    width: 100% !important;
  }

  .news-details-area {
    padding: 120px 0 !important;
  }
  .news-section {
    padding: 120px 0 !important;
  }

  .chats-last-img {
    margin-top: 0px !important;
  }

  .chart-section .chart {
    margin-top: 0px;
  }
  .chart-block .chart-label h1 {
    font-size: 3.9vw;
  }

  .full-portfolio-btn {
    text-align: center;
    padding: 30px 0;
    display: block;
  }

  .full-portfolio-btn {
    font-size: 20px;
    color: #2ea3f2;
    text-decoration: underline;
  }

  .py-35 {
    padding: 35px 0 !important;
  }

  .ultimate-bannar-img img {
    padding-top: 20px;
  }
  .UltimatePlatform-row h3 {
    font-size: 25px !important;
    line-height: 35px !important;
  }

  .trading-platform-title h4 {
    font-size: 28px;
    line-height: 45px;
  }
  .quote-area {
    margin-top: 20px !important;
  }
  .et_pb_row {
    width: 90% !important;
    margin: auto;
  }
  .m-980-auto {
    margin: auto !important;
  }
  .text-980-center {
    text-align: center !important;
  }
  .mt-980-20 {
    margin-top: 20px !important;
  }
  .why-invest .why-invest-blurb p {
    margin: auto;
  }

  .platform-apps-page-section .ultimate-platform-section{
    padding: 65px 0;
  }
  .about-title-area{
    padding: 50px 0 !important;
  }
  
  .about-short-dis p{
    width: 98% !important;
    font-size: 16px !important;
  }

  .announcement-page-area {
    padding: 80px 0 40px 0 !important;
}

.privacy-policy-section{
  padding: 100px 0 40px 0!important;
}
.contact-us-area{
  
  padding: 135px 0 60px 0 !important;
}

.diligent-signup-desktop{
  display: none;
}

.diligent-signup-mobile{
  display: inline-block;
}
.mobile-trade-section{
  padding-top: 0px !important;
}


.mobile-trade-results-area .trade-results p:nth-child(1){
  font-weight: 600 !important;
}

.posce-trade-modal .btn-close {
  right: 20px !important;
  top: 25px !important;
}

.exchange-today-section .about_block .plans-title {
  font-size: 25px !important;
}

.exchange-today-section .about_block {
  padding-top: 30px !important;

}
.mobile-investment-plan-section{
  display: block;
  padding-top: 65px;
}
.mobile-user-investment-plan-section{
  display: block;
}
.mobile-user-investment-plan-section .planwrap{
  padding: 20px 0 100px !important;

}

.desktop-invest-plan{
  display: none;
}

.mobile-invest-plan{
  display: block;
}

}

@media (min-width: 469px) and (max-width: 635px) {
  .intro-statement h1 span::before {
    content: " ";
    display: inline !important;
  }
}

@media (min-width: 636px) and (max-width: 980px) {
  .sub-btn {
    display: grid !important;
    justify-content: center;
  }
  .email-top {
    font-size: 15px !important;
  }
}
@media (min-width: 0px) and (max-width: 635px) {
  .email-top {
    text-align: center !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .market-area {
    padding: 120px 0 80px 0;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .market-area {
    padding: 130px 0 80px 0;
  }
  .diligent-section {
    padding: 130px 0 100px 0 !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .diligent-section {
    padding: 125px 0 80px 0 !important;
  }
}
@media (min-width: 0px) and (max-width: 387px) {
  .logo_container a {
    font-size: 18px !important;
  }

  .ntro-block div.intro-statement h1 {
    font-size: 25px !important;
    line-height: 35px;
  }

  .devices-350 {
    float: none !important;
  }

  .intro-block div.intro-statement h1 {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
  }
  .intro-block div.intro-statement p {
    font-size: 14px;
    line-height: 20px;
  }

  .diligent-section {
    padding: 110px 0 70px 0 !important;
  }

  .intro-block .intro-number {
    font-size: 18px !important;
    font-weight: 300;
    margin-bottom: 0;
  }
  /* .sub-btn {
    display: grid !important;
    justify-content: center;
  } */



  .info-section .ir .icon {
    width: 40px !important;
  }

  .info-section .ir .label {
    font-size: 12px;
  }
  .investor-icon {
    padding: 10px 0 !important;
  }
  .email-top {
    font-size: 8px !important;
  }
}

/* {<!-- -----------------------------------WHY INVEST IN PROSPECT  ----------------------------------- -->} */

@media (min-width: 0px) and (max-width: 1240px) {
  .invest-col-4 {
    width: 100% !important;
    padding: 0 !important;
  }

  .invest-col-8 {
    width: 100% !important;
    padding: 0 !important;
  }
}

@media (min-width: 0px) and (max-width: 980px) {
  .invest-icon-row .col-3 {
    width: 50% !important;
    margin-bottom: 15px;
  }

  .invest-icon-row img {
    width: 50%;
    margin: auto;
  }
  .invest-icon-row p {
    font-size: 10px;
  }

  /* {<!-- -----------------------------------WHY INVEST IN PROSPECT   end----------------------------------- -->} */

  .UltimatePlatform-row {
    text-align: center;
  }

  .css-1wh3shw {
    width: 100%;
    text-align: center;
  }

  .UltimatePlatform-row .col-4 {
    width: 90% !important;
    margin: auto;
  }
  .UltimatePlatform-row .col-5 {
    width: 90% !important;
    margin: auto;
  }
  .UltimatePlatform-row .col-3 {
    width: 90% !important;
    margin: auto;
  }
  .css-12kwjz {
    width: 100%;
    text-align: center;
  }

  .platform-bottom {
    width: 100%;
    font-size: 18px;
  }

  .css-2cp6gk {
    padding-top: 20px;
  }

  .et_pb_text_inner h1 {
    padding-bottom: 20px;
  }

  .py-50 {
    padding: 50px 0 !important;
  }
  .et_pb_text_inner h4 {
    font-size: 25px;
    line-height: 35px;
    padding-bottom: 15px;
  }
  #main-header,
  #main-header .nav li ul,
  .et-search-form,
  #main-header .et_mobile_menu {
    padding: 8px 0;
  }

  .et_mobile_menu {
    padding: 5% !important;
  }

  .market-area .container {
    width: 90% !important;
    margin: auto;
    max-width: 100%;
  }

  .mobile-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
  #main-header.et-fixed-header {
    position: fixed;
    top: 0 !important;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  .trading-platform-market .col-6 {
    width: 100% !important;
    margin: auto !important;
    padding-bottom: 15px;
  }
  .trading-platform-market .pb-4 {
    padding-bottom: 0px !important;
  }
  .trading-platform-market .row .col-6 {
    padding-bottom: 1.3rem !important;
  }
  .trading-platform-market img {
    width: 60px !important;
  }
  .trading-platform-market p {
    margin: 0px !important;
  }
  .desktop-footer-menu{
    display: none;
  }
  .mobile-footer-menu{
    display: block;
  }

}
@media (min-width: 0) and (max-width: 700px) {
  .trading-platform-market img {
    width: 50px !important;
}
  /* .trading-platform-market-image-one .lazy-load-image-loaded {
    width: 65px;
  }
  .trading-platform-market-image-three .lazy-load-image-loaded {
    width: 100px;
  }
  .trading-platform-market-image-four .lazy-load-image-loaded {
    width: 80px;
  }
  .trading-platform-market-image-two .lazy-load-image-loaded {
    width: 70px;
  }
  .trading-platform-market-image-six .lazy-load-image-loaded {
    width: 100px;
  } */
}
@media (min-width: 1350px) and (max-width: 5000px) {
  .trading-platform-market img {
    width: 65px !important;
}
  /* .trading-platform-market-image-one .lazy-load-image-loaded {
    width: 75px;
  }
  .trading-platform-market-image-three .lazy-load-image-loaded {
    width: 110px;
  }
  .trading-platform-market-image-four .lazy-load-image-loaded {
    width: 90px;
  }
  .trading-platform-market-image-two .lazy-load-image-loaded {
    width: 80px;
  }
  .trading-platform-market-image-six .lazy-load-image-loaded {
    width: 110px;
  } */
}
@media (min-width: 981px) and (max-width: 1349px) {
  .trading-platform-market img {
    width: 65px !important;
}
  /* .trading-platform-market-image-one .lazy-load-image-loaded {
    width: 80px;
  }
  .trading-platform-market-image-three .lazy-load-image-loaded {
    width: 120px;
  }
  .trading-platform-market-image-four .lazy-load-image-loaded {
    width: 100px;
  }
  .trading-platform-market-image-two .lazy-load-image-loaded {
    width: 90px;
  }
  .trading-platform-market-image-six .lazy-load-image-loaded {
    width: 120px;
  } */
}

/* {<!-- -----------------------------------LATEST FROM PROSPECT  ----------------------------------- -->} */

@media (min-width: 480px) and (max-width: 635px) {
  .prospect-area .col-8 {
    width: 50% !important;
  }

  .prospect-area .col-4 {
    width: 50% !important;
  }

  .prospect-details .col-8 {
    width: 100% !important;
  }

  .prospect-details .col-4 {
    width: 100% !important;
  }

  .h1-25 {
    font-size: 14px !important;
  }
  .company-name {
    font-size: 12px;
    padding-bottom: 5px;
  }

  .et_pb_divider {
    margin: 0 0 5px 0;
  }

  .et_pb_space {
    height: 5px;
  }

  .stock-exchange-symbol {
    font-size: 10px;
    padding-top: 0px;
  }

  .stock-date {
    font-size: 12px;
    padding: 5px 0;
  }

  .stock-price {
    font-size: 42px;
    display: block;
  }

  .delta {
    font-size: 2vw;
  }
  .volume {
    font-size: 2vw;
  }

  .info-section .ir .icon {
    width: 40px !important;
    margin-bottom: 0 !important;
  }

  .info-section .ir .label {
    /* line-height: 78px; */
    font-size: 11px;
    padding-bottom: 0;
  }

  .investor-icon-area {
    padding: 0 !important;
  }

  .investor-icon-area p {
    margin: 0 !important;
  }

  .hp-news-widget-area .col-6 {
    width: 100% !important;
  }

  .et_pb_blog_0 .et_pb_post .entry-title {
    padding-top: 20px;
    font-size: 18px !important;
  }

  .et_pb_blog_0 .et_pb_post .post-meta,
  .et_pb_blog_0 .et_pb_post .post-meta a {
    font-size: 14px;
  }
  .post-content-inner p {
    font-size: 12px !important;
  }

  .et_pb_blog_0 .et_pb_post .more-link {
    font-size: 12px;
  }

  .SUBSCRIBE-area {
    border-top: 3px solid #fff;
  }

  .SUBSCRIBE-area .col-8 {
    width: 100% !important;
    padding: 10px 0 !important;
  }
  .SUBSCRIBE-area .col-4 {
    width: 100% !important;
  }

  .email-top {
    font-size: 18px;
    height: 45px;
  }

  .email-icon {
    width: 20%;
    left: 1%;
    bottom: 12%;
  }
  .email-button {
    width: 95% !important;
    float: right;
  }

  .sub-btn {
    padding-top: 15px;
    justify-content: space-around !important;
  }
}

@media (min-width: 0px) and (max-width: 479px) {
  .logo_container a {
    font-size: 20px;
  }
  .prospect-area .col-8 {
    width: 100% !important;
  }

  .prospect-area .col-4 {
    width: 100% !important;
  }

  .hp-news-widget-area .col-6 {
    width: 100% !important;
  }
  .investor-icon-area {
    padding: 20px 0 0 0 !important;
  }
  .et_pb_divider {
    margin: 0 !important;
  }

  .company-name {
    font-size: 20px;
  }

  .stock-date {
    font-size: 18px;
    padding: 10px 0 !important;
  }

  .stock-price {
    font-size: 40px;
  }
  .delta {
    font-size: 2vw;
  }
  .volume {
    font-size: 2vw;
  }
  .nvestor-icon {
    padding: 10px 0 !important;
  }

  .SUBSCRIBE-area {
    border-top: 3px solid #fff;
  }

  .SUBSCRIBE-area .col-8 {
    width: 100% !important;
    padding: 10px 0 !important;
  }
  .SUBSCRIBE-area .col-4 {
    width: 100% !important;
  }

  .email-top {
    font-size: 12px;
    height: 35px;
  }

  .email-icon {
    width: 20%;
    left: 1%;
    bottom: 25%;
  }
  .email-button {
    width: 95% !important;
    float: right;
  }

  .sub-btn {
    padding-top: 15px;
    justify-content: space-around !important;
  }
  
  .news-details-area {
    padding: 120px 0 !important;
  }
  .news-section {
    padding: 120px 0 !important;
  }
 
}

@media (min-width: 981px) and (max-width: 1190px) {
  .about-block .textwidget {
    min-height: 480px !important;
  }
}

/* faq */

@media (min-width: 0px) and (max-width: 767px) {
  .faq-area {
    display: block;
  }
  .footable-area {
    display: none;
  }
  .tablet-footable-area {
    display: none;
  }

  .faq-tablet {
    display: none;
  }
  .portfolio-area {
    padding-top: 120px;
  }

  .pane--breadcrumb {
    width: 90% !important;
    max-width: 100% !important;
  }

  .protfolio-data-area .col-3 {
    width: 100% !important;
  }
  .protfolio-data-area {
    max-width: 90% !important;
    margin: auto;
  }

  .pageTitle {
    padding-bottom: 15px !important;
  }

  .faq-title {
    color: #666;
    background-color: #f5f5f5;
    font-family: "niveau_grotesk";
    font-weight: normal;
    font-size: 20px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 50px;
    padding: 14px 10px;
  }

  .faq-answer-area {
    display: flex;
    padding: 5px 0;
  }

  .faq-answer-area h5 {
    width: 20%;
    font-size: 16px;
    color: #555;
    font-weight: 700;
    line-height: 20px;
    padding-right: 15px;
  }
  .faq-answer-area span {
    color: #796666;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}

@media (min-width: 0px) and (max-width: 633px) {
  .faq-answer-area h5 {
    width: 40% !important;
  }
}

@media (min-width: 768px) and (max-width: 880px) {
  .portfolio-area {
    padding-top: 120px !important;
  }
}

@media (min-width: 767.1px) and (max-width: 991px) {
  .tablet-footable-area {
    display: block;
  }

  .footable-area {
    display: none;
  }

  .portfolio-area {
    padding-top: 0px;
  }

  .pane--breadcrumb {
    width: 90% !important;
    max-width: 100% !important;
  }

  .protfolio-data-area {
    width: 90% !important;
    max-width: 100% !important;
  }

  .pageTitle {
    padding-bottom: 15px !important;
  }

  .faqs {
    width: 100%;
    max-width: 100%;
  }

  .faq-mobile {
    display: none;
  }

  .faq-answer-area {
    display: flex;
    padding: 5px 0;
  }

  .faq-answer-area h5 {
    width: 20%;
    font-size: 16px;
    color: #555;
    font-weight: 500;
    line-height: 20px;
    padding-right: 15px;
  }
  .faq-answer-area span {
    color: #796666;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .faq-question span {
    font-size: 14px;
    color: #796666;
  }
}

@media (min-width: 0px) and (max-width: 700px) {
  .all-market-title div {
    display: grid !important;
    grid-template-columns: auto auto;
  }
  .total-market-name {
    display: block !important;
  }

  .all-market-details {
    /* padding: 10px 5px; */
    display: none !important;
  }
  .market-trade-amount small {
    font-size: 12px;
  }

  .market-trade-amount p {
    font-size: 12px;
    line-height: 0px;
    margin-top: 10px;
  }

  .total-market-name a svg {
    font-size: 18px;
    margin-left: 0;
  }
  .total-market-name a {
    font-size: 12px;
  }

  .all-market-name p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .ant-table-content {
    width: 100%;
    overflow: scroll;
  }
  .ant-table-content table {
    table-layout: fixed;
  }

  .ant-table-content table td {
    width: 150px;
  }
  .td-hide {
    display: none !important;
  }
}

@media (min-width: 981px) and (max-width: 995px) {
  .custom-container {
    max-width: 95% !important;
    margin: auto !important;
  }
}

@media (min-width: 285px) and (max-width: 387px) {
  .button-87 {
    font-size: 10px;
    margin: 5px;
    padding: 10px 20px;
  }
}
@media (min-width: 0px) and (max-width: 284px) {
  .button-87 {
    font-size: 8px;
    margin: 5px;
    padding: 10px 13px;
  }
}
@media (min-width: 0px) and (max-width: 405px) {
.login-area .login-card{
  width:90% !important;
}
}




@media (min-width: 376px) and (max-width: 980px) {
  .posce-trade-modal .thradeSettingDataRadio label {
    padding: 8px 15px !important;
    font-size: 15px !important;
  }
  
}

@media (min-width: 0px) and (max-width: 375px) {
  .posce-trade-modal .thradeSettingDataRadio label {
      padding: 8px 10px !important;
      font-size: 10px !important;
  }

  .posce-trade-modal .thradeSettingDataRadio span {
    font-size: 12px !important;
}
}