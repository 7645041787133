.user-mobile-balance-section {
  padding: 10px 0;
}
.user-mobile-balance-section .user-balance p {
  font-size: 18px;
  color: #848E9C;
  line-height: 25px;
}
.user-mobile-balance-section .user-balance h5 {
  font-size: 25px;
  color: #fff;
  line-height: 30px;
}
.user-mobile-balance-section .user-balance span {
  font-size: 14px;
  color: #848E9C;
  line-height: 20px;
}


.user-btn1{
    font-size: 14px;
    padding: 5px 10px;
    color: #000;
    background-color: #FCD535;
    font-weight: 600;
    border-radius: 10px;
}


