@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0 !important;
}

/* body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
} */

a {
  color: var(--text-secondary);
  text-decoration: none !important;
}
p{
  margin-bottom:  0 !important;
}

.app {
  background-color: var(--background);
  color: var(--text-primary);
  transition: all .8s;
}

dl,
ol,
ul {
  margin: 0 !important;
  padding-left: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-weight: 700
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: hidden !important;
  border-width: 0;
}

.container-custom {
  margin: 0 !important;
  padding: 0 30px !important;
}

.border-custom {
  border: 1px solid var(--bs-border-color-2) !important;
}

/*------------------------------------------------ 
    >>>>>>>>>> 01. Header Section CSS <<<<<<<<<< 
--------------------------------------------------*/

.header-area {
  background-color: #0075bb;
  border-bottom: 1px solid var(--primary-base);
  display: grid;
  grid-template-columns: 10% 58% 30%;
  grid-gap: 10px;
  grid-template-rows: auto;
  width: 100%;
}

.logo img {
  height: 54px;
  width: 160px;
  padding: 10px 0px;
}

.menu-area {
  margin-right: 20px;
}

.menu-area>ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.menu-area ul>li {
  padding: 15px 8px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  margin-right: 12px;
}

.menu-area ul>li>a {
  position: relative;
  color: aliceblue;
  font-size: 14px;
  font-weight: 500;

}

.menu-area ul>li>a>svg {
  font-size: 15px;
  padding: 3px;
  margin-left: 5px;
  color: #fff;
  transition: all 0.4s ease;
}

.menu-area ul>li:hover>a {
  color: #d4d0d0;
}

.menu-area ul>li:hover>a>svg {
  color: rgb(38, 0, 255);
  transform: rotate(-180deg);
}

.menu-area ul>li>ul>li>a {
  display: flex;
  align-items: center;
  font-size: 14px
}

.menu-area ul>li>ul>li>a>svg {
  font-size: 16px;
  margin-right: 10px;

}

.menu-area ul>li>ul {
  position: absolute;
  background-color: #25272C;
  list-style: none;
  padding: 10px 0 !important;
  top: 100%;
  left: 10%;
  width: 200px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

}



.menu-area ul>li:hover>ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s
}

.submenu li {
  padding: 0 10px !important;
}

.submenu-item {
  padding: 15px 10px !important;
}

.submenu-item:hover {
  background-color: var(--gray-11);
  border-radius: 5px;
}

.submenu-img img {
  width: 30px;
  height: 30px;
  margin: 0 20px 0 10px;
}

.submenu-content h6 {
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--sub-menu-h6);
  margin: 0;
}

.submenu-content span {
  font-size: 12px;
  margin-top: 2px;
  color: var(--gray-9);
  white-space: normal;
}

.hot-menu {
  position: absolute;
  bottom: 85%;
  right: -4%;
  font-size: 10px;
  font-weight: 700;
  background: #cf2b31;
  border-radius: 1px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  color: #fff;
  transform: scale(.8);
  transform-origin: left center;
}

.menu-with-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-with-img img {
  margin-inline-start: 4px;
  width: 18px;
  height: 18px;
  -o-object-fit: cover;
  object-fit: cover;
}

.search {
  display: flex;
  height: 36px;
  width: 150px;
  border: 1px solid rgba(0, 0, 0, 0.276);
  align-items: stretch;
  border-radius: 50px;
  background-color: var(--gray-11);
  overflow: hidden;
  box-shadow: 2px 1px 5px 1px rgba(0, 0, 0, 0.273);
  margin-right: 20px;
}

.search-btn {
  border: none;
  height: var(--height-sm);
  padding: 4px 12px;
  font-size: 14px;
  line-height: 22px;
  background-color: var(--gray-11);
  font-size: 15px;

}

.search-btn::placeholder {
  color: var(--gray-9);
}

.search-btn:focus {
  outline: none;
}

.search:hover {
  border: 1px solid blue;
}

.search svg {
  font-weight: normal;
  margin: 10px;
  color: var(--gray-9);
}

.header-right svg {
  color: #fff;

}

/* SideBar  */

.sidebar-toggle-Btn {
  margin-left: 20px;
  padding: 5px 5px;
  cursor: pointer;
  background-color: transparent;
}

.sidebar-toggle-Btn:hover>svg {
  color: blue;
}


.sidebar {
  width: 350px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: var(--background);
  color: var(--text-primary);
  transition: all .8s;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  transition: 0.5s;
  display: none;
}

.sidebar.active {
  display: block;
  z-index:9999;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.close-btn {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  border-bottom: 1px solid var(--divider-modal-primary);
  padding: 17px 24px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.close-btn svg {
  padding: 4px 0;
  font-size: 20px;
  cursor: pointer;
}

.header_mobileMenuBtn__KBVfi {
  font-size: 14px;
  display: flex;
  padding: 13px 24px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
}
.sidebar-bg{
  background-color: #0075BB !important;
}
.sidebar-menu {
  height: 450px;
  overflow-y: scroll !important;
  color: var(--sidebar-menu-color);
}

.sidebar-menu a {
  color:  #fff;
}

.header_mobileMenuBtn__KBVfi>span {
  display: flex;
  align-items: center;
}

.sidebar-menu button {
  width: -webkit-fill-available;
}

.mx-icon,
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  display: inline-block;
}

.header_mobileMenuBtn__KBVfi:hover {
  background-color: #3a86e5;
}




/*------------------------------------------------ 
    >>>>>>>>>> 02. Videos Section CSS <<<<<<<<<< 
--------------------------------------------------*/


/* .videos-section{
  height: 100vh;
} */

/* .videos-section video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.video-content-section{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 10%;
  left: 10%;
}
.video-content-section h1{
  color: #fff !important;
} */
.videos-section {
  position: relative;
}

video {
  width: 100%;
  /* height: 100vh; */
  object-fit: cover;
  position: relative;
}

.video-content-section {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 25vh;
  left: 0;
  color: white;
}

.video-content-section h1 {
  font-weight: 600;
  font-size: 54px;
  line-height: 68px;
  word-break: break-all;
  margin-bottom: 20px;
  height: 136px;
  margin-left: 24px;
}

.video-content-section p {
  margin-left: 24px;
  min-height: 102px;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #87909f;
}

.btn {
  font-weight: 600 !important;
}

.video-btn {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.video-btn svg {
  font-size: 20px;
  padding: 10px 30px;
  background: #161c30;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  margin: 0 10px;

}

.video-btn svg:hover {
  color: var(--text-secondary);
}

/*------------------------------------------------ 
    >>>>>>>>>> 03. Bonus Section CSS <<<<<<<<<< 
--------------------------------------------------*/

.bonus-area {
  padding: 30px 20px;
  /* margin-top: -100px; */
  background-color: var(--background);
}

.bouns-items {
  margin-top: 40px;
}
 
.bonus-activity_item-bonus-img__CpShW {
  width: 130px;
  height: 130px;
  margin-right: 48px;
  margin-left: 24px;
  cursor: pointer;
  background-size: 180px;
  animation: bonus-activity_unAuthorize__1zbbY 3s steps(36) infinite;
}

.bonus-area h6 {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-primary);
  overflow: hidden;
}

.bonus-area .bonus-item-line {
  margin-top: 8px;
}

.bonus-area .bonus-item_line__DYqVA:before {
  display: block;
  content: " ";
  line-height: 0;
  font-size: 0;
  width: 100%;
  height: 4px;
  background-color: var(--text-disabled);
  background-clip: content-box;
  border-radius: 1px;
}

.bonus-item_bonus-item-detail__desc___0DDK {
  margin: 4px 0 0;
  color: var(--text-secondary);
  font-size: 12px;
  line-height: 16px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bonus_bonusItemLabel__kOoJQ {
  display: inline-block;
  align-items: center;
  background: var(--tint-orange-smooth);
  border: 1px solid var(--tint-orange-base);
  color: var(--tint-orange-base);
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.bonus_bonusItemLabel__kOoJQ svg {
  display: inline-block;
  margin-right: 4px;
  margin-top: -2px;
}

.bonus-activity_item-bonus-login-button__Mbmwj {
  visibility: visible;
  border: 1px solid transparent;
  border-radius: 4px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-color: var(--bonus-login-button);
  min-width: 120px;
  height: auto;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 10px;
  margin-top: 20px;
}

.bonus-activity_item-bonus-login-button__Mbmwj:hover {
  background-image: linear-gradient(90deg, #5fbce6, #5fbce6), linear-gradient(90deg, #5fbce6, #5fbce6, #5fbce6, #7fa7d3, #62b9e0);
}
































label {
  font-size: 13px;
  color: #424242;
  font-weight: 500;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 45px;
  height: 20px;
  background: #E0E0E0;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  top: 7px;
  right: 20px;

}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 20px;
  height: 16px;
  background: #fff;
  border-radius: 26px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
}

.btn-color-mode-switch>.alert {
  display: none;
  background: #FF9800;
  border: none;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
  background: #2855dc;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 22px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}


.btn-color-mode-switch input[type="checkbox"]:checked~.alert {
  display: block;
}




/*------------------------------------------------ 
    >>>>>>>>>> 04. Notices Section CSS <<<<<<<<<< 
--------------------------------------------------*/
.home-slider {
  padding: 15px 0;
}

.home-slider .notices-section {
  padding: 30px 0;
  /* display: flex;
 justify-content: space-between;
 align-items: center; */
}

.home-slider img {
  width: 100%;
  height: 100%;
}

.notice-icon {
  width: 30px;
}

.notice-silder>.swiper {
  height: 30px;
}

.notice-silder>.swiper a {
  font-size: 16px;
  color: var(--text-secondary);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}

.notice-more-btn {
  width: 110px;
}

.notice-more-btn a {
  color: var(--primary-base);
  text-align: end;
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  white-space: nowrap;
  font-weight: 600;

}

.notice-more-btn svg {
  margin-left: 10px;
}






/*------------------------------------------------ 
    >>>>>>>>>> 05. Slider Section CSS <<<<<<<<<< 
--------------------------------------------------*/
/* .swiper-wrapper{
  position: relative;
} */
.home-slider img {
  width: 100%;
  object-fit: cover;
}

.home-slider>.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  height: 4px;
  padding: 0px 6px;
  border-radius: 2px;
  margin: 0 2px !important;
}

.home-slider>.swiper-pagination-bullet-active {
  height: 4px;
  padding: 0px 10px !important;
  border-radius: 0;
  margin: 0 2px;
  background-color: blue;
}

.home-slider>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  padding-top: 10px !important;
  position: relative !important;
}

/*------------------------------------------------ 
    >>>>>>>>>> 06. Popular Cryptocurrencies Section CSS <<<<<<<<<< 
--------------------------------------------------*/
.popular-cryptocurrencies-section {
  padding-top: 50px;
}

.pc-title h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
}

.pc-tabs-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 24px;
}

/* .pc-tabs-nav-list {
  display: flex;
  align-items: center;
}
.pc-tab-nav-item{
  padding: 24px 15px;
} */

.pc-tab-nav-item span {
  margin: 0 0 0 40px;
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--text-tertiary);
}

.pc-tab-nav-item-hot {
  border: 1px dashed red;
  display: flex;
  align-items: center;
  padding: 7px 0;

}

.pc-tab-nav-item {
  display: flex;
  align-items: center;
}

.pc-tab-nav-item-hot a {
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--text-tertiary);
}

.hot-list_makerTitle {
  display: inline-block;
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  /* padding: 2px 4px; */
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-radius: 2px;
  text-shadow: none;
  color: var(--primary-base);
  border: 1px solid var(--primary-base);
}

.hot-list_change {
  display: inline-block;
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  padding: 2px 4px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  text-shadow: none;
  border: 1px solid;
}

.pc-tab-nav-item-hot-change {
  display: flex;
  align-items: center;
}

.pc-tab-nav-item-hot-change a {
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--text-tertiary);
  margin: 0 0 0 40px;
}

.hot-list_changes {
  display: inline-block;
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  padding: 2px 4px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  text-shadow: none;
  border: 1px solid;
  color: var(--up);
  border-color: var(--up);
}

/* .pc-tabs-nav > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  position: absolute  !important;
} */

.pc-swiper-custom-pagination>.swiper-pagination-bullet {
  width: 200px;
}

.pc-swiper-custom-pagination>.swiper-pagination-bullet {
  width: auto;
  height: auto;
  display: inline-block;
  border-radius: 0;
  background: transparent;
}

.pc-swiper-custom-pagination>.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  height: 0;
  /* padding: 24px 15px; */
  border-radius: 0;
  margin: 0 24px 0 0 !important;
}

.pc-swiper-custom-pagination>.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  height: 0;
  /* padding: 24px 15px; */
  border-radius: 0;
  margin: 0 24px 0 0 !important;
}

.pc-tab-nav-item {
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--pc-h1-text-color);
  /* color: var(--text-tertiary); */
}

.pc-swiper-custom-pagination>.pagination-bullet-active {
  color: var(--pc-h1-text-color) !important;
  /* color: var(--text-tertiary); */
}

.pc-top-futures-section {
  padding: 0 24px;
}






/* coin  */


.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}


.ant-tabs-content {
  position: relative;
  width: 100%;
}

.ant-table-content table {
  width: 100%;
  text-align: left;
  border-radius: var(--table-border-radius-base) var(--table-border-radius-base) 0 0;
  border-collapse: separate;
  border-spacing: 0;
  --bs-table-color: var(--text-primary);
  --bs-table-bg: var(--background);
}

.ant-table-content table tr {
  border-style: hidden;
  padding: 10px 0;
}


.ant-table-content table>button {
  --bs-btn-color: var(--text-primary);
  --bs-btn-border-color: #dde0e2;

}

.ant-table-content a {
  color: var();
}

/*------------------------------------------------ 
    >>>>>>>>>> 06. back-top-btn-area And Support Section CSS <<<<<<<<<< 
--------------------------------------------------*/

/* .back-top-btn-area {

  
  position: fixed;
  display: none;
  bottom: 25%;
  right: 1%;
  width: 80px;
  height: 75px;
  line-height: 75px;
  border-radius: 50%;
  font-size: 17px;
  color: #fff;
  background-color: var(--primary-base);
  text-align: center;
  vertical-align: middle;
  box-shadow: 0px 0px 3px #000;
  align-items: center;
  cursor: pointer;
}

.back-top-btn-area svg {
  font-size: 25px;
  font-weight: 600;
} */

.back-top-btn-area {
  position: fixed;
  display: none;
  bottom: 25%;
  right: 1%;
  cursor: pointer;
  z-index: 9;
}

.back-top-btn-area svg {
  padding: 15px 20px;
  border-radius: 50%;
  font-size: 40px;
  color: #fff;
  background-color: #0075BB;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0px 0px 3px #000;
  align-items: center;
  cursor: pointer;
}

.support-btn-area {
  position: fixed;
  bottom: 10%;
  right: 1%;
  cursor: pointer;
  z-index: 9;
}

.support-btn-show {
  padding: 15px 20px;
  border-radius: 50%;
  font-size: 40px;
  color: #fff;
  background-color: #0075BB;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0px 0px 3px #000;
  align-items: center;
  cursor: pointer;
}


.support-btn svg {
  padding: 15px 17px;
  border-radius: 50%;
  font-size: 40px;
  color: var(--primary-base);
  background-color: var(--background);
  cursor: pointer;
  margin-right: 10px;
}

::placeholder {
  color: var(--text-primary) !important;
}

.support-btn svg:hover {
  color: #fff;
  background-color: var(--primary-base);

}

.support-btn {
  display: none;
}

/* .support-btn-area:hover {
  border-radius: 10px;
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px #0000005c;
  padding: 10px 10px;
} */

/* .support-btn-area:hover>.support-btn {
  display: block;
}

.support-btn-area:hover>.support-btn-show {
  padding: 15px 17px;
  font-size: 40px;
  color: var(--primary-base);
  background-color: var(--background);
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0;
  border: none;
} */

.swiper {
  z-index: 0 !important;
}

.swiper-wrapper {
  z-index: 0 !important;
}


.switch {
  margin-top: 15px;
  font-size: 14px;
}

.switch a {
  color: #007BFF;
  text-decoration: none;
}



/*------------------------------------------------ 
    >>>>>>>>>> 06. Footer Section CSS <<<<<<<<<< 
--------------------------------------------------*/
.footer-area {
  border-bottom: 1px solid var(--divider-primary);
}

.footer-area div {
  padding-bottom: 40px;
}

.footer__widget {
  font-size: 14px;
}

.footer__widget .logo {
  display: block;
  max-width: 250px;
  height: 60px;
  margin-bottom: 30px;
}

.footer__widget p {
  margin: 0;
  margin-bottom: 25px;
  text-align: justify;
}

.post__tag,
.post__share {
  margin: -5px;
  display: flex;
}

.post__tag li,
.post__share li {
  padding: 5px;
}

.post__tag li,
.post__share li a {
  padding: 5px 8px;
  font-size: 20px;
  color: #fff;
  background-color: var(--primary-base);
  border-radius: 10px;
}

.footer__widget .title {
  color: var(--text-tertiary);
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
  position: relative;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer__widget .widget__links li:not(:last-child) {
  margin-bottom: 5px;
}

.footer__widget .widget__links li a {
  color: var(--text-primary);
  font-size: 14px;
  line-height: 30px;
  padding-right: 5px;

}

.footer__widget .widget__links li a:hover span {
  transition: all 1s !important;
  transform: translateX(10px) !important;
  margin-left: 10px;
}

.footer__widget .widget__links li a:hover {
  color: #f2a900;
}

.footer__widget .widget__links li a svg {
  margin-right: 5px;
}

/* .footer__widget .widget__links li a:hover > svg{
  margin-right: 10px;
} */

ul li {
  list-style: none;
}

.subscribe--form--control {
  height: 50px;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  font-size: 14px;
  color: #fff;
  /* position: relative; */
}

.subscribe-form {
  display: flex;
}

.subscribe-form button {
  border: none;
}

.subscribe-form svg {
  /* position: absolute; */
  height: 30px;
  width: 30px;
  padding: 10px;
  line-height: 50px;
  justify-content: center;
  right: 2px;
  top: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  background-color: #f2a900;
  margin-left: -5px;

}

.company-info-area {
  padding-bottom: 30px;
}

.company-info {
  width: 90%;
  margin: -70px auto 0px auto;
  padding: 30px 20px;
  background-color: var(--background);
}

.company-info svg {
  width: 50px;
  line-height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgba(38, 38, 38, 0.801);
  font-size: 22px;
  margin-right: 20px;
  padding: 18px 5px;
}

.company-info-content a {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 18px;
}

.company-info-content span {
  font-weight: 500;
  color: var(--text-primary);
  font-size: 15px;
}

.company-info-content a:hover {
  color: #f2a900;
}

/*------------------------------------------------ 
    >>>>>>>>>> 06. discover-products-area Section CSS <<<<<<<<<< 
--------------------------------------------------*/

.discover-products-area {
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: content-box;
  padding: 80px 0 20px;
}

.discover-products-area h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  padding-bottom: 20px;
}

.discover-products-items-list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
}


.discover-products-items {
  position: relative;
  -webkit-margin-end: 30px;
  margin-inline-end: 30px;
  flex: 1 0;
  cursor: pointer;
  border-radius: 12px;
  text-align: left;
  margin-left: 20px;
}

.discover-products-item-list {
  padding: 20px 16px;
  display: flex;
}
.discover-products-item-list .lazy-load-image-loaded{
  width: 250px;
}
.discover-products-item-list img {
  width: 160px;
  height: 160px;
  margin-right: 20px;
}

.discover-products-item h3 {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: var(--text-primary);
}

.discover-products-item p {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-secondary);
}

.discover-products-item .products_des__CFIiD {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-tertiary);
}

/*------------------------------------------------ 
    >>>>>>>>>> 06. discover-products-area Section CSS <<<<<<<<<< 
--------------------------------------------------*/
.join-community-area {
  position: relative;
}

.join-community-area-bg {
  --home-community-gradient-color: rgba(247, 248, 249, .85);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-secondary);
  overflow: hidden;
}

.join-community-area-bg-content {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1700px;
}

.join-community-area-bg .join-community-area-mask {
  --home-community-gradient-color: rgba(247, 248, 249, .85);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.join-community-area-bg .join-community-area-mask :before {
  --home-community-gradient-color: rgba(247, 248, 249, .85);
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  line-height: 0;
  background: linear-gradient(90.37deg, var(--bg-secondary) .36%, var(--home-community-gradient-color) 86.6%);
}

.join-community-area-bg .join-community-area-mask>img {
  width: auto;
  height: 100%;
}

.join-community-area-body__Jgr43 {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  z-index: 0;
  background: var(--gradient-bg-1);
}

.join-community-area-body__Jgr43 h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
}

.join-community-area-body__Jgr43>p {
  margin: 12px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-secondary);
}

.community_communityBigBtn__MD3tr {
  padding: 36px -12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .community_communityBigBtn__MD3tR>* {
  margin: 0 12px;
} */

/* .community_communityBigBtn__MD3t > div {
  padding: 0 45px 0 32px;
  height: auto;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
} */

.community_communityBigBtn__MD3t svg {
  fill: transparent;
  font-size: 28px;
}


.popular-btn a {
  padding: 12px 23px;
  height: auto;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #70707038;
  color: #000;
}

.popular-btn a:first-child {
  margin-right: 15px;
}

.popular-btn svg {
  fill: transparent;
  font-size: 35px;
  color: #007BFF;
}

.popular-btn p {
  padding-left: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
  color: var(--text-primary);
}

.join-community-social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-community-social a {
  padding: 10px 10px;
  border-radius: 50%;
  color: var(--text-primary);
}

.join-community-social a img {
  display: inline-flex;
  /* width: 40px; */
  height: 40px;
  line-height: 40px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--text-tertiary);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: 50%; */
  transition: all .2s ease-in-out;
}

/*------------------------------------------------ 
    >>>>>>>>>> 06. App Section CSS <<<<<<<<<< 
--------------------------------------------------*/
.download_home-App {
  margin: 80px auto !important;
  padding: 0 80px;
}

.download_home-App h2 {
  margin: 0 0 12px;
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
}

.download_home-App h2+p {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-secondary);
  text-align: center;
}

.download_home-App-content {
  display: flex;
  margin-top: 36px;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.download_home-App-content-logo {
  width: 40.5%;
  min-width: 480px;
  text-align: center;
}

.download_deviceLogo__mGXRx {
  width: 100%;
}

.download_home-App-content-info {
  -webkit-margin-start: 24px;
  margin-inline-start: 24px;
}

.download_downloadInfo__WX6Ki {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.download_downloadInfo__WX6Ki img {
  -webkit-margin-end: 36px;
  margin-inline-end: 36px;
  width: 44px;
  height: 44px;
}

.download_contRight___VlGG h5 {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: var(--text-primary);
}

.download_contRight___VlGG p {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-tertiary);
}

/* .download_home-App-content-logo {
  width: 40.5%;
  min-width: 400px;
  text-align: center;
} */

.download_home-App-content-info {
  -webkit-margin-start: 24px;
  margin-inline-start: 24px;
}

.app-download-btn {
  margin-top: 4px;
}

.app-download-btn a:not(:last-child) {
  -webkit-margin-end: 24px;
  margin-inline-end: 24px;
}

.app-download-btn a {
  margin: 24px 0 0;
  /* width: 136px; */
  height: 44px;
  background: #0c0e18;
  border-radius: 8px;
  padding: 5px 15px;
  color: var(--text-button);
  font-size: 16px;
  line-height: 44px;
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--border-button);
  justify-content: center;
}

.app-download-btn a svg {
  font-size: 22px;
  margin-right: 10px;
}

.app-download-btn a:hover {
  background: #0c0e18d9;

}


.danger-alart-custom {
  padding: 0 !important;
  text-align: center;
}

.danger-alart-custom p {
  margin: 0 !important;
}

.danger-alart-custom button {
  padding: 0 !important;
  background-color: transparent;
  margin-top: 5px;
}

/* user Dashboard  */

.user-dashboard-slider img {
  width: 100%;
  height: 120px;
}

.dashboard-menu-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-template-rows: auto;
}

.dashboard-menu {
  padding: 10px 20px;
  text-align: center;
  box-shadow: 2px 1px 5px 1px rgba(0, 0, 0, 0.273);
  border-radius: 5px;
}

.dashboard-menu span {
  display: inline-block;
}

.dashboard-menu p {
  font-weight: bold;
}

.start-trad {
  background-color: var(--bg111);
}

/* new Listin  */


/*  */
.New-Listing-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  height: 200px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: rd;
}

.start-trading-btn {
  background-color: var(--bg111);
}

.new-listing-area {
  display: inline-block;
  margin: 0 12px;
  padding: 16px 20px;
  border-radius: 12px;
  border: 1px solid var(--divider-primary);
  width: 100%;
}

.new-listing-area:hover {
  background-color: var(--bg-secondary);
}

.new-listing-content-top img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
  background-color: var(--skeleton-to);
  margin-right: 20PX;
}


.new-listing-content-top h6 {
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--title);
}

.listing-content h5 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--text-secondary);
}

.listing-content p {
  margin: 4px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--up);
}

.new-listing-content img {
  width: 70px;
  height: auto;
}

.New-Listing-slider .swiper-slide {
  margin-bottom: 20px;
}

/* ChooseGffex-section  */
.ChooseGffex-section {
  background-color: var(--bg111);
}



.ChooseGffex-video {
  position: relative;
  max-height: 800px;
}

.ChooseGffex-video video {
  height: 100%;
  vertical-align: middle;
  width: 100%;
}

.ChooseGffex-video .ChooseGffex-video-btn-area {
  position: relative;
  z-index: 999;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 8%;
  left: 0;
  width: 100%;
}


.ChooseGffex-video-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.ChooseGffex-video-btn a {
  color: var(--text-tertiary);
}

.ChooseGffex-video-btn a svg {
  color: var(--text-tertiary);
  font-size: 48px;
  display: inline-block;
  margin-bottom: 25px;
}

.ChooseGffex-video-btn a p {
  color: var(--text-tertiary);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
}

.ChooseGffex-video-btn a:hover svg {
  color: #fff;
}

.ChooseGffex-video-btn a:hover p {
  color: #fff;
}

.ChooseGffex-video-btn a:active svg {
  color: #fff;
}

.ChooseGffex-video-btn a:active p {
  color: #fff;
}


/* user dashboard area  */

.user-dashboard{
  padding-top: 50px;
}

.user-dashboard>.row {
  --bs-gutter-x: 0 !important;
}

.user-dashboard>.row>* {
  padding: 0 !important;
}

.user-menu-left {
  border-right: 1px solid var(--bs-border-color-2);
}

.user-nav-items {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  padding: 18px 16px;
  cursor: pointer;
}

.user-menu-area .active {
  background-color: var(--bg-secondary);
  color: #4281ff;
}

.user-nav-items svg {
  width: 20px;
  height: 20px;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}

.user-nav-items p {
  margin-bottom: 0;
}

.user-nav-items:hover {
  background-color: var(--bg-secondary);
}

.user-profile-name-area {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bs-border-color-2);
}

.user-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

.user-image img {
  border-radius: 50%;
  width: 100px;
}

.user-image-update svg {
  font-size: 16px;

}

.update-choose-image {
  padding: 30px 10px;
}

.update-choose-image span {
  display: block;
  margin-bottom: 10px;
}

.user-image-crop-btn {
  display: block !important;
  margin: 10px auto;

}

.user-name-area h4 {
  line-height: 28px;
  font-weight: 600;
  font-size: 20px;
  color: var(--title);
  margin-right: 10px;
}

.user-name-edit {
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  font-size: 16px;
  transition: all .3s;
  color: var(--secondary-text);
  cursor: pointer;
  margin-right: 10px;
}

.user-name-edit:hover {
  color: #007BFF;
}

.user-profile-vip {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 100px;
  line-height: 24px;
  font-size: 12px;
  color: #a76c34;
  background: linear-gradient(270.39deg, #fff4e9 .24%, #fbead8 99.6%);
  cursor: pointer;
}

.user-account-details {
  padding-bottom: 10px;
}

.user-account-details p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  margin: 0;
  padding-bottom: 3px;
}

.user-account-details span {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
  display: flex;
  align-items: center;
}


.user-account-login-details {
  padding-bottom: 10px;
}

.user-account-login-details p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  margin-bottom: 0;
  padding: 3px;
}

.user-account-login-details span {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
  display: flex;
  align-items: center;
}

.user-profile-identification {
  padding: 24px 20px;
  border: 1px solid #e2e5e7;
  border-radius: 8px;
  margin-bottom: 24px;
}

.user-profile-identification h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--text-primary);
  margin-bottom: 8px;
}

.user-profile-identification p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
  margin-bottom: 1em;
}

.user-primary-kyc-btn {
  font-size: 14px;
  color: #faad14;
}

.user-primary-kyc-btn svg {
  padding-left: 5px;
}

.user-primary-kyc-btn:hover {
  color: var(--primary-base);
}

.user-institutional-verification-btn {
  color: var(--primary-base);
  font-size: 14px;
}

.user-institutional-verification-btn:hover {
  color: #4f84ef;
}

.user-box-btn {
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
  background-color: var(--bg-secondary);
  padding: 14px 20px;
  border-radius: 8px;
  margin-bottom: 24px;
  cursor: pointer;
}

.user-box-btn img {
  width: 26px;
  height: 26px;
  margin-inline-end: 8px;
}

.user-box-btn span {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 600;
}

.user-box-btn p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
  padding-top: 10px;

}

.user-box-btn svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: var(--text-secondary);
}

.user-referral-link {
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  align-self: self-start;
  background-color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

.user-referral-link span {
  font-size: 14px;
  font-weight: 400;
}

.user-referral-link img {
  width: 14px;
  height: 14px;
  margin-inline-start: 4px;
}

.user-usdt-value {
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px dashed var(--text-primary);
  cursor: pointer;
  padding-top: 10px;
}

.breadcrumb-area {
  --bs-breadcrumb-divider: '>';
}

.user-identification-title h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding: 20px 0;
}


/* KYC */
.user-KYC {
  padding: 20px;
  border: 1px solid #e2e5e7;
  border-radius: 8px;
}

.user-KYC-title {
  border-bottom: 1px solid #e2e5e7;
  padding-bottom: 20px;
}

.user-KYC-title h3 {
  margin-bottom: 8px;
  line-height: 28px;
  font-weight: 500;
  font-size: 20px;
  color: var(--title);
}

.user-KYC-title p {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0;
  line-height: 22px;
  font-size: 14px;
  color: var(--secondary-text);
}

.user-KYC-dis {
  padding-top: 10px;
  font-size: 14px;
  color: var(--secondary-text);
}

/* referral  */
.user-referral-top-banner {
  background-position: calc((100vw - 1200px)/2 + 533px);
  background-repeat: no-repeat;
  background-color: #f8faff;
  background-size: 787px 345.5px;
  padding: 70px 0;
}

.user-referral-top-banner h1 {
  max-width: 638px;
  margin-bottom: 8px;
  line-height: 60px;
  font-weight: bolder;
  font-size: 48px;
  color: var(--primary-base);
}

.user-referral-top-banner p {
  max-width: 638px;
  margin-bottom: 0;
  line-height: 24px;
  font-weight: 700;
  font-size: 18px;
  color: var(--primary-base);
  padding-top: 10px;
}

.user-referral-dashboard {
  padding: 20px;
  border: 1px solid #e2e5e7;
  border-radius: 8px;
}

.user-referral-dashboard h2 {
  margin-bottom: 0;
  line-height: 28px;
  font-size: 20px;
  color: var(--text-primary);
}

.user-referral-dashboard span {
  font-size: 16px;
  color: var(--text-secondary);
}

.user-referral-dashboard h5 {
  line-height: 34px;
  font-size: 28px;
  font-weight: 400;
  color: var(--text-primary);
}

.user-referral-dashboard-com {
  width: 33%;
}

.user-referral-area {
  cursor: auto;
}

.user-referral-dashboard .user-box-btn {
  cursor: auto;
}

/* .user-referral-history-area {
  margin-top: 30px;
  border: 1px solid #e2e5e7;
  border-radius: 8px;
} */

/* .user-referral-history-titile {
  padding: 20px;
  border-bottom: 1px solid #e2e5e7;
} */

.user-referral-history-titile h3 {
  line-height: 28px;
  font-size: 20px;
  color: rgb(255, 211, 2) !important;
  padding: 18px 0;
}
/* .user-referral-history-titile h3 {
  line-height: 28px;
  font-size: 20px;
  color: var(--text-primary);
} */

.referral-commission_myRefererCode__eKzrS {
  padding: 9px 20px;
  line-height: 22px;
  font-size: 16px;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
}

/* .user-referral-history-items {
  padding: 20px;
} */

.user-referral-history-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  overflow: hidden;

  td,
  th {
    border-top: 1px solid #ECF0F1;
    padding: 10px;
  }

  th {
    font-size: 14px;
    font-weight: 500;
  }

  td {
    border-left: 1px solid #ECF0F1;
    border-right: 1px solid #ECF0F1;
  }

  /* th{
    background-color: #c8f0ee;
  } */

  tr:nth-of-type(even) td {
    background-color: lighten(#4ECDC4, 35%);
  }

  .total {
    th {
      /* background-color: white; */
    }

    td {
      text-align: right;
      font-weight: 700;
    }
  }
}

.user-referral-history-table thead tr {
  border-bottom: 1px solid #e2e5e7;
}

.table-headers {
  color: #87909f;
}

.user-referral-event-rewards-rules-area {
  padding: 20px;
  border: 1px solid #e2e5e7;
  border-radius: 10px;
}

.user-referral-event-rewards-rules-area h2 {
  margin-bottom: 0;
  line-height: 28px;
  font-size: 20px;
  color: var(--text-primary);
}

/* user deposit  */
.user-deposit {
  background-color: var(--bg111);
}

.user-deposit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.user-deposit-title h1 {
  margin-bottom: 0;
  color: var(--heading-color);
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.user-deposit-title p {
  color: var(--primary-base);
  cursor: pointer;
}

.user-deposit-title p:hover {
  color: #689aff;
}


.hh-grayBox {
  background-color: var(--bg222);
  /* background-color: #111214; */
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

/* .pt45{padding-top:45px;} */
.Deposit-tracking {
  /* text-align: center; */
  width: 33.33%;
  position: relative;
  display: block;
}

.Deposit-tracking .is-complete {
  text-align: center;
  font-weight: 600;
  display: block;
  /* position: relative; */
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #AFAFAF;
  background-color: #4281ff;
  /* margin: 0 auto; */
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

/* .order-tracking .is-complete:after {
	display: block;
	position: absolute;
	content: '';
	height: 14px;
	width: 7px;
	top: -2px;
	bottom: 0;
	left: 0px;
	margin: auto 0;
	border: 0px solid #AFAFAF;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	opacity: 0;
} */
.Deposit-tracking.completed .is-complete {
  border-color: #4281ff;
  border-width: 0px;
  background-color: #4281ff;
}

.Deposit-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}

.Deposit-tracking p {
  color: #A4A4A4;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
}

.Deposit-tracking::before {
  content: '';
  display: block;
  height: 3px;
  width: calc(100% - 60px);
  background-color: #4281ff;
  top: 13px;
  position: absolute;
  left: calc(-90% + 20px);
  z-index: 0;
}

.Deposit-tracking:first-child:before {
  display: none;
}

.Deposit-tracking.completed:before {
  background-color: #4281ff;
}

.Deposit-tracking-dis {
  padding-top: 20px;
}

.Deposit-tracking-dis h3 {
  color: var(--text-primary);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  width: 95%;
}

.Deposit-tracking-dis p {
  color: #87909f;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  width: 85%;
}

.Deposit-tracking-show-hide {
  font-size: 18px;
  color: var(--text-primary);
  cursor: pointer;
}

.user-deposit-input-area {
  background-color: var(--bg222);
  /* background-color: #111214; */
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
}


.user-deposit-tips-title:before {
  content: "";
  position: absolute;
  top: 5px;
  left: -7px;
  width: 3px;
  height: 15px;
  background: var(--primary-base);
}

.user-deposit-tips-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.user-deposit-tips-title h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
}

.user-deposit-tips-dis p {
  color: var(--text-secondary);
  margin: 16px 0;
}

.user-deposit-faq-area {
  padding: 10px 0;
}

.user-deposit-faq-lists a {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  font-size: 14px;
  padding: 10px 0;
  width: 90%;
  line-height: 20px;
}

.user-deposit-faq-lists a svg {
  color: var(--icon-tertiary);
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
  font-size: 20px;
  margin-top: 1px;
}

.user-deposit-faq-lists a:hover {
  color: blue;
}

.user-deposit-faq-lists-btn a {
  width: 90%;
  background: var(--fill-menu-selected);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  color: var(--text-secondary);
}

.user-deposit-history {
  background-color: var(--bg222);
  /* background-color: #111214; */
  padding: 0 20px;
  border-radius: 10px;
}

/*************************************
**** Product item style
**************************************/

/* Shine */
.item-box {
  position: relative;
  overflow: hidden;
  display: block;
  background: var(--background);
  /* max-width: 300px; */
  padding: 10px;
  border: 1px solid #eff2f1;
  box-shadow: var(--trade-card-bg) 0 0 14px 2px;
  border-radius: 10px;
}

.product-item {
  text-align: center;
  text-decoration: none;
  display: block;
  /* position: relative; */
  padding-bottom: 10px;
  cursor: pointer;
  z-index: 2 !important;
}

.product-item::before {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  content: "";
  background: #0059307d;
  height: 0%;
  z-index: 0;
  border-radius: 10px;
  -moz-transition: .3s all ease;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}


.product-item figure>img {
  padding: 0.8rem;
  transition: .3s all ease;
  -moz-transition: .3s all ease;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
}

.product-item h3 {
  color: var(--text-primary);
  text-decoration: none;
  z-index: 2999 !important
}


.product-item:hover::before {
  height: 70%;

}

.product-item:hover figure::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}



.product-item figure {
  position: relative;
}

.product-item figure img {
  margin: auto;
  width: 80%;
  height: 80%;
  border-radius: 50%;
}

.product-item figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

figure:hover::before .product-item {
  border: 10px solid red;
}

.product-item figure:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 100%;
  }
}

@keyframes shine {
  100% {
    left: 100%;
  }
}

.user-transaction-history-titile {
  padding: 20px 30px 0 30px;
  display: flex;
  align-items: center;
  border: 1px solid #e2e5e7;
}

.user-transaction-history-titile h3 {
  margin-right: 30px;
  font-size: 20px;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0 10px 5px 10px;
  margin-bottom: 0px;
}

.user-transaction-history-titile .active {
  color: var(--text-primary);
  border-bottom: 3px solid blue;
}



.thradeSettingDataRadio input[type="radio"] {
  display: none;
  margin: 0;
  padding: 0;
}

.thradeSettingDataRadio label {
  background-color: var(--label-bg);
  border: 2px solid var(--base-color);
  border-radius: 6px;
  color: var(--text-primary);
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  margin-bottom: 5px;
}

.thradeSettingDataRadio input[type="radio"]:checked+label {
  color: #fff;
  background-color: #35479d;
}

.flip-clock {
  --fcc-flip-duration: 0.5s;
  /* transition duration when flip card */
  --fcc-digit-block-width: 40px;
  /* width of digit card */
  --fcc-digit-block-height: 60px;
  /* height of digit card, highly recommend in even number */
  --fcc-digit-font-size: 30px;
  /* font size of digit */
  --fcc-digit-color: white;
  /* color of digit */
  --fcc-label-font-size: 10px;
  /* font size of label */
  --fcc-label-color: #ffffff;
  /* color of label */
  --fcc-background: rgb(167, 57, 57);
  /* background of digit card */
  --fcc-divider-color: white;
  /* color of divider */
  --fcc-divider-height: 0;
  /* height of divider */
  --fcc-separator-size: 6px;
  /* size of colon */
  --fcc-separator-color: white;
  /* color of colon */
  justify-content: center !important;
  padding: 5px 0;
}

/* 
.container {
  margin: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  height: 100vh;
}
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
}

.chart-container {
  width: 80%;
  height: 100%;
} */

.testimonial-area {
  text-align: center;
}

.testimonial-title h4 {
  color: var(--text-primary);
  padding: 10px 0;
  font-size: 36px;
  line-height: 50px;
}

.testimonial .row {
  --bs-gutter-x: 0;
}

.testimonial img {
  width: 90%;
}

.testimonial-rating svg {
  color: rgb(255, 221, 57);
  font-size: 16px;
  margin-right: 5px;
  padding: 10px 0;
}

.testimonial-dis p {
  font-size: 22px;
  line-height: 1.4;
  color: var(--text-primary);
  font-weight: 500;
  padding: 0 10px;
}

.testimonial-author a {
  color: var(--text-primary);
  font-size: 15px;
  font-weight: 600;
  display: block;
}

.testimonial-author a:hover {
  text-decoration: underline !important;
}

.testimonial-author small {
  color: var(--text-secondary);
  font-size: 13px;
  font-weight: 500;
  display: block;
  padding: 5px 0;
}

.react-loading-skeleton {
  --base-color: var(--skeleton-base-color);
  background-color: var(--skeleton-bg) !important;
}

.react-loading-skeleton::after {
  background-image: linear-gradient(90deg, var(--skeleton-base-color), var(--skeleton-highlight-color), var(--skeleton-base-color)) !important;
}


.all-market-name p {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;
}

.all-market-name p {
  font-size: 18px;
  padding-bottom: 2px;
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;

}

.all-market-name p:hover {

  color: var(--text-secondary);

}

.all-market-name .active {
  border-bottom: 1px solid var(--text-primary);
}

.total-market-name a {
  padding: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  border: 0px;
  color: inherit;
  cursor: pointer;
}

.all-market-details-top {
  padding: 20px 40px;
  background-color: var(--bg-secondary);
}

.total-market-name a svg {
  font-size: 18px;

  margin-left: 5px;
  color: rgb(255, 87, 34);
  transition: color 0.25s ease 0s, transform 0.3s ease 0s;

}

/* .total-market-name a:hover svg {
  padding-left: 15px;
  transition: color 0.25s ease 0s, transform 0.3s ease 0s;

} */

.market-trade-amount p {
  color: var(--text-primary);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 1px;
}

.market-trade-amount small {
  color: var(--text-secondary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.css-mkny2 {
  display: flex;
  position: relative;
  padding: 17px 24px 14px;
  border-bottom: 1px solid var(--border-button);
}

.css-4c2x90 {
  width: 38%;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-secondary);
  padding-right: 24px;
  box-sizing: border-box;
}

.css-g7jqpb {
  font-size: 12px;
  line-height: 20px;
  color: var(--text-secondary);
  width: 15%;
  box-sizing: border-box;
}

.css-1q6uvqr {
  width: 32%;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-secondary);
}

.market-img {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
}


.all-market-name small {
  line-height: 14px;
  color: var(--text-secondary);
  margin-top: 2px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  font-size: 12px;
  width: 100%;
  padding-right: 16px;
  box-sizing: border-box;
}

.loader-section {
  height: 100vh;
  margin: auto;
}

.all-market-name>td>button {
  padding: 5px 40px;
  font-weight: 400 !important;
}


/* Live chat css  //////////////////////////////////////////////////////////*/
.card-bordered {
  border: 1px solid #ebebeb;
}



.padding {
  padding: 3rem !important
}


.btn-secondary {
  color: #4d5259 !important;
  background-color: #e4e7ea;
  border-color: #e4e7ea;
  color: #fff;
}

.btn-xs {
  font-size: 11px;
  padding: 2px 8px;
  line-height: 18px;
}

.btn-xs:hover {
  color: #fff !important;
}






.ps-container {
  position: relative;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media .avatar {
  flex-shrink: 0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.media-chat .media-body {
  -webkit-box-flex: initial;
  flex: initial;
  display: table;
  width: 100% !important;
}

.media-body {
  min-width: 0;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
  font-weight: 100;
  color: #9b9b9b;
}

.media>* {
  margin: 0 8px;
}

.media-chat .media-body p.meta {
  background-color: transparent !important;
  padding: 0;
  opacity: .8;
}

.media-meta-day {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0;
  color: #8b95a5;
  opacity: .8;
  font-weight: 400;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media-meta-day::before {
  margin-right: 16px;
}

.media-meta-day::before,
.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  margin-left: 16px;
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media-chat.media-chat-reverse .media-body p {
  float: right;
  clear: right;
  background-color: #48b0f7;
  color: #fff;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
}

.chat-file-upload .filelabel {
  border: 2px solid grey;
  border-radius: 5px;
  display: block;
  padding: 7px 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
}

.chat-file-upload .filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}

.chat-file-upload .filelabel i,
.chat-file-upload .filelabel {
  color: grey;
  transition: 200ms color;
}

.chat-file-upload .filelabel:hover {
  border: 2px solid #1665c4;
}

.chat-file-upload .filelabel:hover i,
.filelabel:hover .title {
  color: #1665c4;
}

#FileInput {
  display: none;
}

.chat-file-btn {
  font-size: 12px;
  padding: 6px 7px;
}

.border-light {
  border-color: #f1f2f3 !important;
}

.chat-attach-file {
  padding: 1px 2px;
  color: red;
  display: inline-block;
}

.bt-1 {
  border-top: 1px solid #ebebeb !important;
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #f9fafb;
}

.publisher>*:first-child {
  margin-left: 0;
}

.publisher>* {
  margin: 0 8px;
}

.publisher-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  outline: none !important;
  background-color: #686363;
  height: 50px;
  padding: 20px 10px;
  color: #fff;
  border-radius: 10px;
}

button,
input,
optgroup,
select,
textarea {
  background-color: var(--background);
  color: var(--text-primary);
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #8b95a5;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.file-group {
  position: relative;
  overflow: hidden;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #cac7c7;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.file-group input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px;
}

.text-info {
  color: #48b0f7 !important;
}

/* Live chat css  End//////////////////////////////////////////////////////////*/


.expired_time-css ._qWTcH {
  --fcc-digit-block-width: 20px;
  --fcc-digit-block-height: 20px;
  --fcc-digit-font-size: 20px;
  --fcc-label-font-size: 16px;
}

.expired_time-css ._2aOn7 {

  font-size: 6px;

}

.expired_time-css ._1tbqx ._2hRXr {
  margin-bottom: 0;
}

.expired_time-css ._1tbqx ._3cpN7 {
  margin-bottom: 0;
}

.chakra-ui-light {
  --chakra-colors-chakra-body-bg: transparent !important;
}




/* Chat css New ////////////////////// */

.chat-section-area .row {
  --bs-gutter-x: 0 !important;

}

.chat-section-area {
  position: fixed;
  bottom: 50px;
  right: 30px;
  background-color: var(--background);
  border-radius: 5px;
  /* width: 25%; */
}

.box {
  position: relative;
  border-radius: 3px;
  background: var(--background);
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}


.box.box-success {
  border-top-color: #00a65a;
}

.box.box-default {
  border-top-color: #d2d6de;
}

.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}

.box .nav-stacked>li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}

.box .nav-stacked>li:last-of-type {
  border-bottom: none;
}

.box.height-control .box-body {
  max-height: 300px;
  overflow: auto;
}

.box .border-right {
  border-right: 1px solid #f4f4f4;
}

.box .border-left {
  border-left: 1px solid #f4f4f4;
}

.box.box-solid {
  border-top: 0;
}

.box.box-solid>.box-header .btn.btn-default {
  background: transparent;
}

.box.box-solid>.box-header .btn:hover,
.box.box-solid>.box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}

.box.box-solid.box-success {
  border: 1px solid #00a65a;
}

.box.box-solid.box-success>.box-header {
  color: #fff;
  background: #00a65a;
  background-color: #00a65a;
}

.box.box-solid.box-success>.box-header a,
.box.box-solid.box-success>.box-header .btn {
  color: #fff;
}

.box.box-solid>.box-header>.box-tools .btn {
  border: 0;
  box-shadow: none;
}

.box.box-solid[class*='bg']>.box-header {
  color: #fff;
}

.box .box-group>.box {
  margin-bottom: 5px;
}

.box .knob-label {
  text-align: center;
  color: #333;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}

.box>.overlay,
.overlay-wrapper>.overlay,
.box>.loading-img,
.overlay-wrapper>.loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

.box .overlay>.fa,
.overlay-wrapper .overlay>.fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}

.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}

.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}

.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}

.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

.collapsed-box .box-header.with-border {
  border-bottom: none;
}

.box-header>.fa,
.box-header>.glyphicon,
.box-header>.ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}

.box-header>.fa,
.box-header>.glyphicon,
.box-header>.ion {
  margin-right: 5px;
}

.box-header>.box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.box-header>.box-tools [data-toggle="tooltip"] {
  position: relative;
}

.box-header>.box-tools.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}

.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: #97a0b3;
}

.open .btn-box-tool,
.btn-box-tool:hover {
  color: #606c84;
}

.btn-box-tool.btn:active {
  box-shadow: none;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}

.no-header .box-body {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.box-body>.table {
  margin-bottom: 0;
}

.box-body .fc {
  margin-top: 5px;
}

.box-body .full-width-chart {
  margin: -19px;
}

.box-body.no-padding .full-width-chart {
  margin: -9px;
}

.box-body .box-pane {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}

.box-body .box-pane-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}

.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: var(--background);
}

.direct-chat .box-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  overflow-x: hidden;
  padding: 0;
}

.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 250px;
  overflow: auto;
  scrollbar-color: var(--background) var(--black);
}

.direct-chat-msg,
.direct-chat-text {
  display: block;
}

.direct-chat-msg {
  margin-bottom: 10px;
}

.direct-chat-msg:before,
.direct-chat-msg:after {
  content: " ";
  display: table;
}

.direct-chat-msg:after {
  clear: both;
}

.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
}

.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}

.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}

.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}

.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}

.right .direct-chat-text:after,
.right .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #d2d6de;
}

.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}

.right .direct-chat-img {
  float: right;
}

.direct-chat-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  font-size: 12px;
}

.direct-chat-name {
  font-weight: 600;
}

.direct-chat-timestamp {
  color: #999;
}

.contacts-list>li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 0;
}

.contacts-list>li:before,
.contacts-list>li:after {
  content: " ";
  display: table;
}

.contacts-list>li:after {
  clear: both;
}

.contacts-list>li:last-of-type {
  border-bottom: none;
}

.contacts-list-img {
  border-radius: 50%;
  width: 40px;
  float: left;
}

.contacts-list-info {
  margin-left: 45px;
  color: #fff;
}

.contacts-list-name,
.contacts-list-status {
  display: block;
}

.contacts-list-name {
  font-weight: 600;
}

.contacts-list-status {
  font-size: 12px;
}

.contacts-list-date {
  color: #aaa;
  font-weight: normal;
}

.contacts-list-msg {
  color: #999;
}

.direct-chat-success .right>.direct-chat-text {
  background: #00a65a;
  border-color: #00a65a;
  color: #fff;
}

.direct-chat-success .right>.direct-chat-text:after,
.direct-chat-success .right>.direct-chat-text:before {
  border-left-color: #00a65a;
}



.browse {
  display: inline-block;
  position: relative;
}

.browse-file {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  text-align: right;
  opacity: 0;
  z-index: 2;
}

.browse-file-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* .browse-file-img svg{
  font-size: 30px;
} */


/* FAQ  */
.faq-title h5 {
  text-align: center;
  padding: 40px;
  font-size: 30px !important;
  color: var(--text-primary);
}

.faq-accordion {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.faq-item {
  box-shadow: 0 0 30px var(--bs-border-color-2);
  padding: 10px 24px;
  padding-right: 48px;
  cursor: pointer;
  /* border-top: 4px solid #fff;
  border-bottom: 4px solid #fff; */
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 24px;
  row-gap: 10px;
  align-items: center;
}

.faq-number {
  font-size: 24px;
  font-weight: 500;
  color: #ced4da;
}

.faq-ques {
  color: var(--text-primary);
}

.faq-ques,
.faq-icon {
  font-size: 24px;
  font-weight: 500;
}

.faq-content-box {
  grid-column: 2 / -1;
  padding-bottom: 16px;
  line-height: 1.6;
  color: var(--text-tertiary);
}

.faq-content-box ul {
  color: #868e96;
  margin-left: 16px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* OPEN STATE */
.faq-open {
  border-top: 4px solid #087f5b;
}

.faq-open .faq-number,
.faq-open .faq-ques {
  color: #087f5b;
}


/* blog  */

.blogs-title h5 {
  text-align: center;
  font-size: 30px !important;
  color: var(--text-primary);
  padding: 30px;
  margin: 0;
}

.blogs-articles article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.blogs-articles article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/* basic article elements styling */
.blogs-articles article h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: var(--text-primary);
  transition: color 0.3s ease-out;
}

.blogs-article-wrapper {
  background-color: var(--black);
  height: 100%;
}

.blogs-article-wrapper figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.blogs-articles article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.blogs-article-body {
  padding: 10px 24px 24px 24px;
}

.blogs-article-body p {
  font-size: 15px;
  text-align: justify;
}

.blogs-articles article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-primary);
}

.blogs-articles article a:focus {
  outline: 1px dotted #28666e;
}

.blogs-articles article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

/* using the has() relational pseudo selector to update our custom properties */
.blogs-articles article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}



.blogs-articles {
  display: grid;
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

@media screen and (max-width: 960px) {
  .blogs-articles article {
    container: card/inline-size;
  }

  .blogs-article-body p {
    display: none;
  }
}

@container card (min-width: 380px) {
  .blogs-article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }

  .blogs-article-body {
    padding-left: 0;
  }

  .blogs-article-wrapper figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .blogs-article-wrapper figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.blogs-sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}



/* Contact Us  */




/* ///// inputs /////*/

.contact-us-input-area>.row {
  margin: auto;

}

.contact-us-input-area input:focus~label,
textarea:focus~label,
input:valid~label,
textarea:valid~label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.contact-styled-input {
  float: left;
  width: 100%;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 768px) {
  .contact-styled-input {
    width: 100%;
  }
}

.contact-styled-input label {
  color: var(--text-tertiary);
  position: absolute;
  top: 40%;
  left: 20px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.contact-styled-input.contact {
  width: 100%;
  max-width: 100%;
}

.contact-us-input-area input,
textarea {
  padding: 20px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: var(--background);
  color: var(--text-primary);
  border-radius: 4px;
}

.contact-us-input-area input:focus,
textarea:focus {
  outline: 0;
}

.contact-us-input-area input:focus~span,
textarea:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

.contact-us-input-area textarea {
  width: 100%;
  min-height: 15em;
}

.contact-us-input-container {
  width: 70vw;
  max-width: 100%;
  margin: 20px auto 25px auto;
  padding: 20px;
  background-color: var(--trade-card-bg);
  border-radius: 10px;
}

.contact-us-submit-btn {
  float: right;
  padding: 7px 35px;
  border-radius: 60px;
  display: inline-block;
  background-color: #4b8cfb;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contact-us-submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.10),
    0 1px 1px 0 rgba(0, 0, 0, 0.09);
}

@media (max-width: 768px) {
  .contact-us-submit-btn {
    width: 100%;
    float: none;
    text-align: center;
  }
}



/* Blog Details //////////////////////////////// */

.blog-details-title h5 {
  padding-bottom: 20px;
  font-size: 30px;
  color: var(--text-primary);
}

.cs-blog {
  margin-bottom: 30px;
}

.cs-blog h2 {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 29px;
  margin: 0 0 11px;
  position: relative;
  text-transform: uppercase;
}

.cs-blog::after {
  clear: both;
  content: "";
  display: block;
}

ul.blog-list {
  list-style: outside none none;
  margin: -30px 0 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.blog-list.blog-slide {
  margin: 0;
}

.blog-list.blog-slider {
  margin: 0;
}

ul.blog-list li {
  float: left;
  list-style: outside none none;
  margin: 30px 0 0;
}

.blog-slide .slick-list {
  margin: 0 -15px;
}

ul.blog-list.blog-slide li {
  margin-bottom: 10px;
  margin-top: 0;
}

ul.blog-list li:first-child {
  border: 0 none;
}

ul.blog-list li figure {
  overflow: hidden;
  position: relative;
}

ul.blog-list li figure img {
  width: 100%;
}

ul.blog-list li .cs-text {
  border: 1px solid #f0f0f0;
  overflow: hidden;
  padding: 15px 20px;
}


.cs-blog-detail .cs-text .post-option {
  border-top: 1px solid #f0f0f0;
  float: left;
  padding-top: 10px;
  width: 100%;
}

.cs-blog-detail .cs-text .post-option span a {
  color: #777;
}

.widget ul.blog-list li .cs-text {
  height: auto;
  margin: 0;
  min-height: inherit;
  padding: 9px 0 13px;
}

ul.blog-list li .cs-text span {
  color: #8b919e;
  display: inline-block;
  font-size: 12px;
  line-height: 19px;
}

ul.blog-list li .cs-text p {
  margin-bottom: 12px;
}

ul.blog-list li .cs-text h5 {
  border-bottom: 1px solid #fff;
  font-size: 15px;
  margin: 0;
  min-height: 56px;
  padding: 0 0 5px;
}

ul.blog-list li .cs-text h5 a {
  color: #292c33;
}

ul.blog-list li .cs-text .readmore {
  float: right;
  font-size: 11px;
  line-height: 20px;
  padding-top: 6px;
  position: relative;
  text-transform: uppercase;
}

ul.blog-list .slick-list.draggable {
  overflow: hidden;
}

.cs-auther-name a {
  color: #999;
}

.blog-list .slick-arrow {
  background-color: #f9f9f9;
  float: left;
  height: 29px;
  margin: 5px 0 0 5px;
  text-align: center;
  width: 29px;
}

.blog-list .slick-arrow a {
  color: #999;
  font-size: 18px;
  line-height: 32px;
}

.cs-blog.classic {
  margin: 0 0 30px;
}

.cs-blog.classic ul {
  margin: 0;
}

.cs-blog.classic li {
  border-top: 2px solid #eceef0;
  float: left;
  list-style: outside none none;
  padding: 16px 0;
  width: 100%;
}

.cs-blog.classic p {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 -4px 0 0;
  vertical-align: middle;
  width: 100%;
}

.cs-blog.classic p i {
  color: #c4c6c8;
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.cs-blog.classic span {
  display: inline-block;
  float: right;
  font-size: 12px;
  text-align: right;
  vertical-align: middle;
}

.cs-blog.classic span i {
  color: #e2e5e8;
  float: right;
  font-size: 24px;
  margin: 2px 0 0 10px;
}

.cs-pagination-blog {
  margin-bottom: 30px;
}

.cs-blog.blog-medium {
  border-bottom: 0 none;
  margin: 0;
  padding-bottom: 30px;
}

.cs-blog.blog-medium::after {
  clear: both;
  content: "";
  display: block;
}

.cs-blog.blog-medium .blog-text .cs-post-title {
  clear: both;
}

.cs-blog .cs-media figure {
  position: relative;
}

.cs-blog .cs-media figure figcaption {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
}

.cs-blog .cs-media:hover figure figcaption {
  opacity: 1;
  visibility: visible;
}

.cs-blog.blog-medium .post-title h3 {
  margin-bottom: 0;
}

.cs-blog .post-title {
  margin-bottom: 10px;
}

.cs-blog.blog-medium .cs-media figure figcaption .cs-readmore a {
  color: #fff;
  font-size: 24px;
  left: 50%;
  margin: -10px 0 0 -65px;
  position: absolute;
  top: 50%;
  transform: scale(0.7);
}

.cs-blog.blog-medium .cs-media:hover figure figcaption .cs-readmore a {
  transform: scale(1);
}

.cs-blog.blog-medium:last-child {
  border-bottom: medium none;
  padding-bottom: 40px;
}

.blog-medium .cs-media {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  width: 37%;
}

.blog-modern .cs-media {
  display: inline-block;
  margin-right: -4px;
  vertical-align: middle;
  width: 48.6%;
}

.blog-medium .cs-media figure img,
.blog-modern .cs-media img {
  width: 100%;
}

.blog-medium .cs-media~.blog-text {
  display: inline-block;
  float: none;
  margin-right: 0;
  vertical-align: middle;
  width: 58%;
}

.blog-modern .blog-text {
  display: inline-block;
  margin-right: -4px;
  padding-left: 30px;
  vertical-align: middle;
  width: 51.4%;
}

.blog-modern .blog-text .cs-post-title {
  margin-bottom: 5px;
  padding-bottom: 1px;
  position: relative;
}

.blog-modern .blog-text .cs-post-title::after {
  bottom: 1px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 27px;
}

.blog-modern .blog-text .blog-separator {
  margin: 0 0 10px;
}

.blog-modern .blog-text .blog-separator::before {
  display: none;
}

.blog-medium .blog-text {
  width: 99.1%;
}

.blog-medium .blog-text p {
  display: inline;
  margin: 0 0 15px;
}

.blog-medium .blog-separator {
  margin: 0 0 10px;
}

.cs-blog .cs-categories,
.cs-blog-detail .cs-categories {
  display: block;
  margin: 0 0 12px;
}

.cs-blog .cs-categories a,
.cs-blog-detail .cs-categories a {
  border-bottom: 2px solid #ededed;
  color: #55a747;
  display: inline-block;
  font-size: 10px;
  margin-right: 5px;
  padding-bottom: 2px;
  text-transform: uppercase;
}

.cs-blog-detail .post-option {
  float: right;
}

.cs-blog .post-option span a,
.cs-blog-detail .post-option span a {
  color: #999 !important;
  display: inline-block;
  font-size: 12px;
  margin-right: 18px;
  vertical-align: middle;
}

.cs-blog .post-option span i,
.cs-blog-detail .post-option span i {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  vertical-align: middle;
}

.cs-blog-detail .post-option span.post-category i {
  margin: 0;
}

.cs-blog-detail .post-option .post-category a {
  margin-left: 10px;
  margin-right: 0;
}

.cs-blog-detail .post-option .post-date {
  margin-left: 18px;
}

.cs-blog-detail .cs-text .post-option span i {
  float: left;
  margin: 3px 8px 0 0;
}

.cs-blog.blog-grid figure img {
  width: 100%;
}

.cs-blog.blog-grid .cs-media~.blog-text {
  margin: -30px 0 0;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.cs-blog.blog-grid .cs-inner-bolg {
  background-color: #fff;
  display: inline-block;
  padding: 20px 25px;
  width: 100%;
}

.cs-blog.blog-grid .blog-text p {
  margin: 0 0 5px;
}

.cs-blog.blog-grid .post-option {
  line-height: normal;
  margin: 0 0 10px;
}

.cs-blog.blog-grid .post-option span {
  color: #8b919e;
  font-size: 10px;
  margin: 0 15px 0 0;
  position: relative;
  text-transform: uppercase;
}

.cs-blog.blog-grid .post-option span::before {
  background-color: #8b919e;
  border-radius: 100%;
  content: "";
  height: 3px;
  left: -10px;
  position: absolute;
  top: 5px;
  width: 3px;
}

.cs-blog.blog-grid .post-option span:last-child {
  margin: 0;
}

.cs-blog.blog-grid .post-option span:first-child::before {
  display: none;
}

.cs-blog.blog-grid .read-more {
  display: inline-block;
  font-size: 12px;
  position: relative;
}

.cs-blog.blog-grid .read-more::before {
  content: "";
  font-family: "icomoon";
  font-size: 14px;
  position: absolute;
  right: -15px;
  top: 0;
}

.blog-large .cs-media img {
  width: 100%;
}

.blog-large .cs-text {
  margin: 0 0 20px;
  position: relative;
  z-index: 1;
}

.blog-large .cs-media~.cs-text {
  background-color: #fff;
  margin: 0 auto;
  padding: 30px 0 0;
  width: 100%;
}

.cs-blog .cs-author,
.cs-blog-detail .cs-author {
  float: left;
  margin: 0 0 10px;
}

.cs-blog .cs-author figure,
.cs-blog-detail .cs-author figure {
  display: inline-block;
  height: 32px;
  margin: 0 10px 0 0;
  vertical-align: middle;
  width: 32px;
}

.cs-blog .cs-author figure img,
.cs-blog-detail .cs-author figure img {
  border-radius: 100%;
}

.cs-blog .cs-author .cs-text,
.cs-blog-detail .cs-author .cs-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.cs-blog .cs-author .cs-text a,
.cs-blog-detail .cs-author .cs-text a {
  color: #555;
  font-size: 13px;
}

.blog-large .post-option,
.cs-blog.blog-medium .post-option {
  float: right;
}

.cs-blog.blog-large .post-option span i,
.cs-blog.blog-medium .post-option span i {
  color: #cfcfcf;
}

.post-option span i {
  margin-right: 5px;
  transition: all 0.3s ease-in-out 0s;
}

.blog-separator {
  border-bottom: 1px solid #f1f1f1;
  display: inline-block;
  margin: 20px 0 25px;
  position: relative;
  width: 100%;
}

.blog-large .cs-text p {
  margin: 0 0 25px;
}

.blog-large .read-more {
  border: 1px solid;
  border-radius: 20px;
  display: inline-block;
  font-size: 12px;
  padding: 4px 20px;
  text-transform: uppercase;
}

.blog-large .cs-post-title {
  margin: 0 0 15px;
}

.blog-large .cs-post-title h3 {
  margin: 0;
}

.cs-blog-detail .cs-post-title h1 {
  margin: 0 0 10px;
}

.cs-blog-detail .cs-post-title::after {
  clear: both;
  content: "";
  display: block;
}

.cs-blog-detail .cs-main-post img {
  width: 100%;
}

.cs-blog-detail .cs-main-post {
  margin-bottom: 25px;
}

.cs-blog-detail .cs-admin-post .cs-media figure,
.cs-blog-detail .cs-admin-post .cs-media figure img {
  border-radius: 100%;
}

.cs-blog-detail .cs-admin-post .cs-text {
  overflow: hidden;
}

.cs-blog-detail .cs-admin-post {
  float: left;
  width: 40%;
}

.cs-blog-detail .cs-admin-post .cs-media {
  float: left;
  height: 46px;
  margin-right: 14px;
  width: 46px;
}

.cs-blog-detail .cs-author-name {
  color: #ccc;
  display: inline-block;
  font-size: 14px;
  margin-right: 20px;
  padding-top: 6px;
  vertical-align: middle;
}

.cs-blog-detail .cs-author-name strong {
  color: #55a747;
  display: block;
  line-height: 26px;
}

.cs-blog-detail .cs-more-post {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #ccc;
  font-size: 11px;
  padding: 6px 8px;
}

.cs-blog-detail .cs-social-share {
  float: right;
}

.cs-blog-detail .cs-social-media {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.cs-blog-detail .cs-social-media li {
  display: inline-block;
  list-style: outside none none;
  margin: 0;
  vertical-align: top;
}

.cs-blog-detail .cs-social-media li a {
  background-color: #fc6d4c;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: 13px;
  height: 28px;
  line-height: 30px;
  margin: 0 5px 5px 0;
  text-align: center;
  width: 28px;
}

.cs-blog-detail .cs-social-media li a.cs-more {
  line-height: 33px;
  padding: 0;
}

.cs-blog-detail .cs-social-media li a::before {
  display: none;
}

.cs-blog-detail .cs-social-media li a[data-original-title="facebook"] {
  background-color: #2b4a8b;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Facebook"] {
  background-color: #2b4a8b;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Tumblr"] {
  background-color: #32506d;
}

.cs-blog-detail .cs-social-media li a[data-original-title="tumblr"] {
  background-color: #32506d;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Dribbble"] {
  background-color: #ea4c89;
}

.cs-blog-detail .cs-social-media li a[data-original-title="dribbble"] {
  background-color: #ea4c89;
}

.cs-blog-detail .cs-social-media li a[data-original-title="stumbleupon"] {
  background-color: #eb4823;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Stumbleupon"] {
  background-color: #eb4823;
}

.cs-blog-detail .cs-social-media li a[data-original-title="rss"] {
  background-color: #f06c19;
}

.cs-blog-detail .cs-social-media li a[data-original-title="twitter"] {
  background-color: #1f94d9;
}

.cs-blog-detail .cs-social-media li a[data-original-title="linkedin"] {
  background-color: #10598c;
}

.cs-blog-detail .cs-social-media li a[data-original-title="google"] {
  background-color: #d83936;
}

.cs-blog-detail .cs-social-media li a[data-original-title="youtube"] {
  background-color: #b00;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Youtube"] {
  background-color: #b00;
}

.cs-blog-detail .cs-social-media li a.cs-more .at4-icon {
  border-radius: 10px;
  margin: 5px 0 0 -2px;
}

.cs-blog-detail .cs-share {
  float: none;
  left: 0;
  margin: 0 15px 0 0;
  position: absolute;
  top: 0;
}

.cs-blog-detail .cs-share a {
  color: #333;
  font-size: 18px;
  font-weight: 700;
}

.cs-blog-detail .cs-share-detail::after {
  clear: both;
  content: "";
  display: block;
}

.cs-blog-detail .cs-share-detail {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  vertical-align: middle;
  width: 49%;
}

.cs-blog-detail .cs-post-option-panel {
  float: left;
  padding-top: 20px;
  width: 100%;
}

.cs-blog-detail .rich-editor-text p {
  margin-bottom: 30px;
}

blockquote,
.rich-text-editor blockquote {
  border-left: 4px solid;
  margin: 0 0 40px;
  padding: 20px 0 0;
  position: relative;
  width: 95%;
}

blockquote {
  background-color: #fcfcfc;
  font-style: italic;
  padding: 15px 40px 20px 50px !important;
}

blockquote,
blockquote span,
blockquote p {
  color: #777;
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}

blockquote .author-name a {
  color: #999;
  font-size: 11px;
}

blockquote.text-left-align {
  text-align: left;
}

blockquote.text-right-align {
  text-align: right;
}

blockquote.text-center-align {
  text-align: center;
}

blockquote::before,
.rich-text-editor blockquote::before {
  color: #eaeaea;
  content: "";
  font-family: "icomoon";
  font-size: 22px;
  font-style: normal;
  left: 24px;
  position: absolute;
  top: 15px;
  transform: scale(-1);
}

.rich-text-editor blockquote {
  background-color: #fcfcfc;
  font-style: italic;
  padding: 15px 40px 20px 50px;
}

.rich-text-editor blockquote p {
  margin: 0;
}

blockquote>span {
  margin: 0;
  position: relative;
}

blockquote>span.author-name::after {
  display: none;
}

blockquote>span::after {
  color: #eaeaea;
  content: "";
  display: inline-block;
  font-family: "icomoon";
  font-size: 22px;
  font-style: normal;
  margin: 0 0 0 8px;
  position: relative;
  top: 3px;
}

.cs-blog-detail .tags {
  display: inline-block;
  margin: 0 -4px 0 0;
  vertical-align: middle;
  width: 50%;
}

.cs-blog-detail .cs-tags {
  display: block;
  margin: 0 0 40px;
}

.cs-blog-detail .cs-tags .tags span {
  color: #333;
  display: inline-block;
  font-size: 18px;
  margin: 0 10px 5px 0;
}

.cs-blog-detail .cs-tags .tags ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.cs-tags ul li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 0 6px;
}

.cs-tags ul li a {
  display: block;
  font-size: 12px;
  margin: 0 8px 0 0;
}

.cs-tags .tags ul li a {
  background-color: #f5f5f5;
  border-radius: 20px;
  color: #777;
  padding: 2px 18px 3px;
}

.comment-respond {
  margin-bottom: 30px;
}

.comment-form ul {
  list-style: outside none none;
}

.comment-form ul li {
  margin-bottom: 30px;
}

.comment-form .cs-element-title h3 {
  margin: 0;
}

.comment-form form .input-holder {
  position: relative;
}

.comment-form form .input-holder i {
  color: #cecece;
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 15px;
}

.comment-form form .input-holder input[type="text"],
.comment-form form .input-holder textarea {
  border: 1px solid #e4e4e4;
  color: #999;
  font-size: 14px;
  height: 50px;
  margin-bottom: -1px;
  padding: 10px 20px;
  width: 100%;
}

.comment-form form .input-holder textarea {
  height: 214px;
  margin: 0 0 20px;
}

.comment-form form input[type="submit"] {
  background-color: #55a747;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 10px 30px;
  text-transform: uppercase;
}

.blog-detail {
  box-shadow: none;
}

.blog-detail .blog-list {
  float: left;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}

.blog-slider-next {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 10px;
}

.blog-slider-prev {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
}

.blog-detail::after,
.author-detail::after,
#comment ul li::after,
.blog-detail .blog-list::after,
.cs-packeges::after {
  clear: both;
  content: "";
  display: block;
}

.blog-title {
  margin-bottom: 25px;
}

.blog-title h3 {
  color: #282828;
  letter-spacing: -1px;
  line-height: 34px;
  margin: 0 0 10px;
}

.blog-detail .main-post {
  margin: 0 0 25px;
}

.blog-detail .main-post img {
  width: 100%;
}

.author-detail {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 22px;
}

.cs-admin figure {
  float: left;
  margin-right: 15px;
}

.cs-admin .cs-text {
  display: inline-block;
  overflow: hidden;
  padding-top: 8px;
}

.cs-admin .cs-text span {
  color: #ccc;
  display: block;
  font-size: 13px;
  line-height: 16px;
}

.cs-admin .cs-text strong {
  color: #282828;
  font-size: 14px;
  line-height: 18px;
}

.blog-detail h2 {
  line-height: 29px;
  margin: 0 0 11px;
  position: relative;
  width: 91%;
}

.rich-editor-text p {
  clear: both;
  line-height: 24px;
  margin-bottom: 20px;
}

/* Blog Details End ////////////////////////////////////////////////////////////////////////////////// */

.form-control {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.form-select {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.trade-complate-bg {
  background-color: var(--background);
}

.trade-complate svg {
  color: #2e2e7b;
}

.trade-complate h4 {
  color: var(--text-primary);
}

.trade-complate p {
  color: var(--text-secondary);
}

.trade-complate p {
  color: var(--text-secondary);
}

.trade-complate-bg button {
  color: #fff !important;
}


.pagination .page-item .page-link,
.pagination .page-item span {
  border-radius: 0 !important;
}

.page-item:first-child .page-link {
  border-radius: 10px 0 0 10px !important;
  font-weight: 500;
  /* min-width: 80px; */
}

.page-item:last-child .page-link {
  border-radius: 0 10px 10px 0 !important;
  font-weight: 500;
  /* min-width: 50px; */
}

.custom-pagination .pagination li {
  margin: 2px 1px;
}

.custom-pagination .page-link {
  color: var(--text-primary);
  background-color: var(--pagination-bg);
  border: 0 !important;
}

.custom-pagination .page-link:hover {
  background-color: var(--background);
}


.custom-pagination .pagination {
  --bs-pagination-disabled-color: var(--text-tertiary);
  --bs-pagination-disabled-bg: var(--bg-secondary);

}

.custom-pagination .active>.page-link,
.page-link.active {
  z-index: 0;

}

.testimonial-rating svg {
  color: #ffdd39;
  font-size: 16px;
  margin-right: 5px;
  padding: 10px 0;
}

.testimonial-rating .unrating {
  color: var(--bg-tertiary) !important;
}

.modal-content {
  background-color: var(--bg-secondary) !important;
  box-shadow: 0 0 10px var(--bs-border-color-2) !important;
}

.notices-modal-show .modal-content {
  background-color: #181A20 !important;
  border: none !important;
  box-shadow: none !important;
}

.tickets-card-area .card {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}



/*======================
    404 page
=======================*/


#error-404{
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

#error-404 .fof{
  display: table-cell;
  vertical-align: middle;
}

#error-404 .fof h1{
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #888;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}

.Mobile-version-slider .lazy-load-image-background.blur.lazy-load-image-loaded{
  width: 100%;
} 
.home-slider .lazy-load-image-background.blur.lazy-load-image-loaded{
  width: 100%;
} 


.history-filter .ant-picker{
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary);
  margin-right: 10px;
}
.history-filter .ant-picker input{
  padding: 10px 20px;
  background-color: var(--bg-secondary) !important;
  border-radius: 5px;
  margin: 0px;
}

.history-filter .ant-picker svg{
  color: var(--text-primary);

}



/* Plan  */


/******=======  ABOUT PART END  =======******/
.plan_bg{
  padding: 50px 0px;
  position: relative;
  background: #040a1f;
}
.plan_bg .container{
  position: relative;
}
.plan_bg .plan_vedio{
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  object-fit: cover;
  opacity: 0.2;
}
.plan_bg .head{
  text-align: center;
} 
.plan{
  margin: 90px auto 0px;
  background: linear-gradient(45deg, #0b1634, #011642);
  padding: 55px 20px 20px;
  border-radius: 20px;
  border: 2px solid #276df46b;
  position: relative;
  max-width: 265px;
}
.plan1{
  background: linear-gradient(45deg, #0c1734, #022b3a);
  border: 2px solid #05ae4a;
}
.plan2{
  background: linear-gradient(45deg, #0e1128, #341201);
  border: 2px solid #d05001;
}
.plan h2{
  background: url("./plan_img1.png");
  background-size: 100% 100%;
  padding: 23px;
  position: absolute;
  top: -70px;
  right: 0px;
  width: 170px;
  height: 130px;
  text-align: center;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 35px;
  font-weight: 700;
  color: #fff;
}
.plan1 h2{
  background: url("./plan_img1.png");
  background-size: 100% 100%;
}
.plan2 h2{
  background: url("./plan_img1.png");
  background-size: 100% 100%;
}
.plan h3{
  font-size: 15px;
  font-weight: 700;
  color: #1aa7ef;
}
.plan1 h3{
  color: #07af48;
}
.plan2 h3{
  color: #fea310;
}
.plan h3 span{
  color: #fff;
  font-weight: 400;
}
.plan h4{
  float: right;
  margin: 24px -21px 0px 0px;
  font-size: 15px;
  background: linear-gradient(45deg, #015fea, #00bffa);
  padding: 10px 23px;
  max-width: 170px;
  width: 100%;
  border-radius: 7px 0px 0px 7px;
  color: #fff;
  font-weight: 500;
}
.plan1 h4{
  background: linear-gradient(45deg, #42e87f, #38f8d3);
  color: #0e1228;
  font-weight: 600;
}
.plan2 h4{
  background: linear-gradient(45deg, #f9ca22, #f83800);
  color: #0e1228;
  font-weight: 600;
}
.plan .plan_text{
  margin: 75px 0px 0px;
}
.plan p{
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 10px 0px;
}
.plan p .img1{
  max-width: 18px;
  margin: 0px 4px 0px 0px !important;
  display: inline-block !important;
}
.plan p span{
  float: right;
  color: #1aa7ef;
  font-weight: 600;
  font-size: 15px;
}
.plan .plan_but{
  padding: 15px 0px 0px;
}
.plan .plan_but .but1{
  float: left;
  margin: 0px 0px 0px -21px;
  padding: 8px 70px 8px 30px;
}

.plan_bg {
  padding: 35px 0px;
}
.plan {
  margin: 60px auto 0px;
  padding: 55px 10px 20px;
}
.plan .plan_but .but1 {
  margin: 0px 0px 0px -12px;
}
.plan h2 {
  padding: 22px;
  top: -50px;
  width: 130px;
  height: 100px;
  font-size: 28px;
}
.plan h4 {
  margin: 24px -11px 0px 0px;
}

.but1 svg{
  position: absolute;
  color: #241154;
  width: 35px;
  height: 35px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 3px;
  border-radius: 50%;
  bottom: 0px;
  margin: auto;
}

.but1 svg {
  width: 30px;
  height: 30px;
}

.plan .plan_but .but1 {
  margin: 0px 0px 0px -12px;
}


/* Shutter In Horizontal */
.hvr-shutter-in-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: linear-gradient(45deg, #0ecbff, #4663ff) !important;
  color: #fff;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding: 8px 60px 11px 20px;
  border-radius: 5px 20px 20px 5px;
  position: relative;
  border: 1px solid #2492f6;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}
.hvr-shutter-in-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #241154;
  background: linear-gradient(45deg, #6718ce, #2970f9) !important;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 5px 20px 20px 5px;
}
.hvr-shutter-in-horizontal:hover, .hvr-shutter-in-horizontal:focus, .hvr-shutter-in-horizontal:active {
color: white;
}
.hvr-shutter-in-horizontal:hover:before, .hvr-shutter-in-horizontal:focus:before, .hvr-shutter-in-horizontal:active:before {
-webkit-transform: scaleX(0);
transform: scaleX(0);
}

.hvr-shutter-in-horizontal {
  font-size: 13px;
  font-weight: 500;
  padding: 7px 45px 7px 10px;
}
/******=======  plan PART END  =======******/


/******=======  CALCI PART START  =======******/
.calci_bg{
  padding: 50px 0px 0px;
  margin: 0px;
}
.calci_bg .container{
  position: relative;
}
.calci_bg .calci{


}
.calci_bg .calci1{
  background: #050618;
  padding: 15px 20px;
  border: 1px solid #03307b;
  border-radius: 15px;
}
.calci_bg li{
  display: inline-block;
  width: 49%;
}
.calci_bg .cal_text{
  margin: 0px 5px;
}
.calci_bg .cal_text h3{
  font-size: 14px;
  font-weight: 500;
  color: #b2b3ba;
  padding: 0px;
  margin: 0px;
}
.calci_bg .cal_text h3 span{
  display: block;
  margin: 10px 0px 0px 0px;
}
.calci_bg .cal_text select{
  width: 100%;
  padding: 8px 10px;
  border-radius: 5px;
  background: #0c1633;
  border: 1px solid #1b459c;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  color: #1aa7ef;
  margin: 10px 0px 0px 0px;
}
.calci_bg .cal_text input{
  width: 100%;
  padding: 11px 10px;
  border-radius: 5px;
  background: #0c1633;
  border: 1px solid #1b459c;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  color: #1aa7ef;
  margin: 10px 0px 0px 0px;
}
.calci_bg .cal_text span{
  width: 100%;
  padding: 11px 10px;
  border-radius: 2px;
  background: #0e1128;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  color: #1aa7ef;
}
.calci_bg .cal_text .cl1{
  color: #f98305;

}

.calci_bg .calci1 {
  margin: 20px 0px 0px;
}

/******=======  CALCI PART END  =======******/




.chart-container {
  flex: 1;
}

.announcement{
  height: 80vh;
  overflow-y: scroll !important;
}
.announcement p{
  width: 95%;
  margin: auto;
  padding: 20px 3px;
  font-size: 16px;
  cursor: pointer;
  line-height: 25px;
  border-bottom: 1px solid #0075BB;
}
.user-bal-area{
  padding: 10px 22px;
}
.user-bal-info {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding-bottom: 2px;
}
.user-bal-info p{
 font-size: 16px;
 font-weight: 500;
 margin: 0 !important;
 color: #fff;
}
.user-bal-info span{
 font-size: 14px;
 font-weight: 500;
 padding-left: 20px;
 color: #e1e1e1;

}

.Toastify__toast-container--top-right {
  z-index: 99999 !important;
}

.user-deposit{
    background-color: #F4F4F5;
    padding-top: 45px;
    /* background-color: #e0e0e159; */
    /* background-color: #e0e0e1db; */
}
.user-dashboard .col-9{
    background-color: #F4F4F5;
    /* background-color: #e0e0e159; */
    /* background-color: #e0e0e1db; */
}

.kyc-details h5{
  font-size: 20px;
  color: #000;
  padding-bottom: 15px;
}

.kyc-details h5 span{
  font-size: 16px;
  color: #333333;
}


.mobile-header-menu-area{
background-color: #0075bb;
}

.mobile-logo-link{
  width: 70%;
}

 

/* Table color */

.user-deposit-history{
  background-color: #014670;
  border-radius: 5px;
}
.user-referral-history-table {
  background-color:  #0075BB !important;
  border-radius: 10px;
}
.user-referral-history-table th{
  color: #bcd0f7 !important;
}
.user-referral-history-table td{
  color: #fff !important;
}

.mobile-home-sft{
 padding-bottom: 15px;
  
}


.mobile-news-section{
  padding: 5px 0  90px 0  !important;
}
.mobile-news-details-section{
  padding: 20px 0  130px 0  !important;
}
.support-btn-area {
  z-index: 9999;
}

.chat-section-area {
 
  z-index: 9999 !important;
}

.mobile-py-20-120{
  padding-top: 20px !important;
  /* padding-bottom:120px !important; */
}

.mobile-py-20-280{
  padding-top: 20px !important;
  /* padding-bottom:280px !important; */
}
.mobile-py-20-500{
  padding-top: 20px !important;
  /* padding-bottom:500px !important; */
}
.user-image-update img {
  margin: auto !important;
}

.fa-industry:before {
  content: "\f275";
}

.fa-code:before {
  content: "\f121";
}
.fa-location-arrow:before {
  content: "\f124";
}

.fa-percent:before {
  content: "\f295";
}

.dropdwon-menu-show{
  position: relative;
}

.dropdwon-menu-hide{
  position: absolute;
  top: 5px;
  right: 0px;
}

.dropdwon-menu-show .dropdown-menu-dark{
  padding: 10px 0 30px 0 !important;
  background-color: #014670;
}
.dropdwon-menu-show .dropdown-menu-dark a{
  padding: 20px 10px !important;
  font-size:18px !important;
  display: block !important;
  font-weight: 600 !important;
}
.dropdwon-menu-show .dropdown-menu-dark button{
  padding: 20px 10px;
  font-size:18px;
  display: block !important;
  font-weight: 600;
  width: 100%;
  text-align: left;
}

.dropdwon-menu-show .dropdown-menu-dark a:hover{
  background-color: #0075BB;
}

.dropdwon-menu-show .dropdown-menu-dark button:hover{
  background-color: #0075BB;
}

.UltimatePlatform .css-1ktfw2v span{
  font-size: 18px !important;
  font-weight: 400;
}

.UltimatePlatform .css-1ktfw2v {
 padding-top: 10px;
}
.platform-apps-page-section .css-1ktfw2v {
  display: table-cell;
}


/* trading view  */

.tradingview-widget-container{
  height: 100vh !important;
}

.mobile-tradingview-widget{
  height: 60vh !important;
}


.trade-area-posce{
  padding:  25px 20px;
}
 .charts__head {
  background: #0075BB !important;
  border-color: #23262F;
}

.charts__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  /* border-radius: 4px; */
  background: #FCFCFD;
  /* border-bottom: 1px solid #E6E8EC; */
}

.charts .nav {
  display: flex;
}
 .charts .select {
  background: #353945;
}


 .select {
  background: #141416;
  box-shadow: inset 0 0 0 2px #353945;
  -webkit-appearance: none;
  
}

.charts .select {
  display: none;
  height: 28px;
  line-height: 28px;
  background: #E6E8EC;
}

.select {
  float: none;
  width: 100%;
  height: 48px;
  padding: 0 48px 0 16px;
  box-shadow: inset 0 0 0 2px #E6E8EC;
  background: #FCFCFD;
  border-radius: 12px;
  border: none;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
}

.nav__link.active {
  background: #353945;
  color: #FCFCFD;
}


.nav__link:not(:last-child) {
  margin-right: 8px;
}

.nav__link {
  display: flex;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #d5d5d5;
  transition: all .2s;
}

.trade-charts-area-posce{
  /* padding: 10px 0; */
}





/* trade modal  */
.trade-results-modal-area .modal-content{
  width: 80%;
  margin: auto;
}

.posce-trade-modal .modal-content {
  background: #0075BB !important;
  color: white;
  border-radius: 10px;

  width: 80%;
  margin: auto;
}

.posce-trade-modal .modal-header {
  border-bottom: none;
  justify-content: center;
  position: relative;
}

.posce-trade-modal .modal-title {
  margin: 0;
  font-weight: bold;
  line-height: 1.5;
}


.posce-trade-modal .modal-body {
  background-color: transparent;
  color: white;
}

.posce-trade-modal .bargaining__field {
  box-shadow: inset 0 0 0 2px #23262F;
}


.posce-trade-modal.field__input, 
.posce-trade-modal.field__textarea {
  border-color: #353945;
  color: #FCFCFD;
}


.posce-trade-modal .bargaining__field {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 16px;
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px #E6E8EC;
  cursor: pointer;
}

.posce-trade-modal .field__input {
  height: 48px;
  padding: 0 14px;
}

.posce-trade-modal .field__input, .field__textarea {
  width: 100%;
  border-radius: 12px;
  border: 2px solid #E6E8EC;
  box-shadow: none;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 500;
  color: #23262F;
  transition: border-color .2s;
}

.posce-trade-modal .bargaining__btns .bargaining__button {
  flex: 0 0 calc(50% - 12px);
  width: calc(50% - 12px);
  margin: 0 6px;
}

.posce-trade-modal .field__input  {
 cursor: text;
}


.posce-trade-modal .thradeSettingDataRadio  {
  text-align: center;
}
.posce-trade-modal .thradeSettingDataRadio label {
  padding:  8px 30px;
  font-size: 0.95em;
  color: #fff;
  background-color: #014670;
}

.posce-trade-modal .thradeSettingDataRadio  {
  padding-bottom: 10px;
}
.posce-trade-modal .thradeSettingDataRadio  span{
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  color: #87909f;
}
.posce-trade-modal .field__input::placeholder{
  color: #c1c2c3 !important;
}

.posce-trade-modal .btn-close {
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
  color: #fff !important;
  opacity: 1 !important;
}
















body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #0075BB;
  outline: 1px solid #0075BB;
}

.footer-menu-link a{
font-weight: 600;
line-height: 30px;
}

.footer-menu-contact-area p{
  font-weight: 500;
  padding-bottom: 10px;
  line-height: 30px;
}

.footer-menu-contact-area p svg{
  padding-right: 5px;
}



.mobile-trade-section .charts__head{
  background-color: #FCD535 !important;
}
.mobile-trade-section .charts  .d-flex  .nav {
  padding: 5px;
  border-radius: 50%;
  background-color: #000
}


.mobile-trade-modal-section   .modal-content{
  background-color: #FCD535 !important;
}
.mobile-trade-modal-section    .modal-title{
  color: #000;
}
.mobile-trade-modal-section   .modal-body {
  color: #000 !important;
}
.mobile-trade-modal-section   .modal-body span{
  color: #000 !important;
}
.mobile-trade-modal-section   .modal-body input::placeholder{
  color: #000 !important;
}
.mobile-trade-modal-section   .trade-results p{
  color: #000 !important;
}

.mobile-trade-modal-section  .modal-header {
  background-color: #e7c126 !important;
  color: #000;
}

.mobile-trade-offcanvas-area .text-gray-400 {
  color: #000 !important;
}

.mobile-kyc-page h5 span{
  color: #FCD535 !important;
}
.mobile-kyc-page h5 small{
 font-size: 12px !important;
 font-weight: 400;
}

.deposit-warning{
  background-color: #FCD535;
  padding: 20px ;
  border-radius: 10px;
}
.deposit-warning small{
  font-size: 20px; 
  color: red;
  line-height: 25px;
}
.deposit-warning small b{
  font-size: 30px; 
  
}


