.new-coin-dis{
    padding: 15px 0;
}

.new-coin-dis p{
    color: #fff;
}
.new-coin-details{
    display: flex;
    align-items: center;
    justify-content:space-evenly;
}
.new-coin-details .new-coin-logo {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border-radius: 50%;
}

.supply-coin{
    display: flex;
    align-items: center;
}


.supply-coin p{
    font-size: 4vw;
    color: #fff;
    font-weight: 600;
    padding-right: 10px;
}
.supply-coin span{
    font-size: 3vw;
    color: #848E9C;
}
.expired-time p{
    font-size: 5vw;
    color: #fff;
    font-weight: 600;
    padding-right: 10px;
    padding-top: 20px;
}
.expired-time span{
    font-size: 4vw;
    color: #848E9C;
}

.new-coin-details-area .expired_time-css{
    background-color: #FCD535 ;
}
.new-coin-details-area .expired_time-css ._2aOn7{
    color: #000 ;
}

