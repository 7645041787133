.orders-area {
  padding: 20px 0 90px 0;
}

.mobile-home-main  .orders-menu-area h5 {
  font-size: 3vw;
  font-weight: 500;
  color: #fff !important;
  padding: 1.5vw 3vw;
  background-color: #1d113d !important;
  border-radius: 3.5vw;
}
.mobile-home-main .orders-menu-area .active {
  background-color: #0166fc !important;
  border-radius: 3.5vw;
}

.orders-profit-loss {
  padding: 10px 0;
  text-align: center;
}

.mobile-home-main .orders-profit-loss h5 {
  font-size: 4vw;
  font-weight: 500;
  line-height: 12vw;
  color: #fff !important;
}

.mobile-home-main .orders-profit-loss h3 {
  font-size: 8.53333vw;
  font-weight: 700;
  line-height: 8.53333vw;
}

.user-orders-balance-area{
    padding-top: 20px;
    padding-bottom: 10px;
}

.mobile-home-main .user-orders-balance-area p{
    font-size: 3.2vw ;
   line-height:  3.2vw;
   font-weight: 400;
   color: #cbcbcb !important;
}
.user-orders-balance-area span{
    font-size: 4.26667vw;
   line-height: 4.26667vw;
   font-weight: 600;
   color: #fff !important;
}
.orders-history{
    padding: 10px 0;
}
.orders-history-item{
    padding-bottom: 15px;
}

.mobile-home-main .orders-history-title h5{
    font-size: 4vw;
    font-weight: 400;
    color: #fff !important;
    padding-bottom: 10px;
}

.mobile-home-main .orders-item-details p{
    font-size: 3.5vw;
    font-weight: 400;
    color: #fff !important;
}
.mobile-home-main .orders-item-details span{
    font-size: 3vw;
    font-weight: 400;
    color: #cbcbcb !important;
    display: block;
}

.mobile-home-main .orders-item-up-value h6{
    border: 1px solid #0166fc !important;
    border-radius: 10px;
    font-size: 3.5vw;
    display: flex;
    color: #fff !important;
    font-weight: 300;

}

.mobile-home-main .orders-item-up-value h6 p{
    padding: 0.5vw 2.13333vw ;
    background-color: #0166fc !important;
    border-radius:10px 0 0 10px;
}

.mobile-home-main .orders-item-up-value h6 span{
    padding: 0.5vw 2.13333vw;
}
.mobile-home-main .orders-history-item h5{
    font-size: 6.4vw ;
    padding-top: 10px
}

.mobile-home-main .orders-item-dwon-value h6{
    border: 1px solid #f23c48 !important;
    border-radius: 10px;
    font-size: 3.5vw;
    display: flex;
    color: #fff !important;
    font-weight: 300;

}

.mobile-home-main  .orders-item-dwon-value h6 p{
    padding: 0.5vw 2.13333vw ;
    background-color: #970711 !important;
    border-radius:10px 0 0 10px;
}

.mobile-home-main .orders-item-dwon-value  h6 span{
    padding: 0.5vw 2.13333vw;
}


.mobile-home-main  .text-danger-1{
    color: #970711 !important;
}

.mobile-home-main  .text-success-1{
    color: #0166fc !important;
}

.user-trade-position-modal{
    height: 180vw !important;
    padding-top: 30px;
}
.user-trade-position-modal .user-history-details-area{
    height: 175vw !important;
}

.user-history-modal-area{
    background-color: #0d6efd;
}

.user-history-modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 20px;
  }
  .user-history-modal-header h5{
    font-size: 4vw;
    color: #fff !important;
  }
  .user-history-modal-header svg{
    font-size: 5vw;
    color: #f23c48;
  }

  .order-history-update-btn{
    padding: 2vw 6vw;
    background-color: rgba(0, 69, 112, 0.8) !important;
    color: #fff;
    font-size: 3vw;
    /* line-height: 1vw; */
    font-weight: 400;
    border-radius: 10vw;
  }
  
  .user-trade-close-btn p{
    cursor: pointer;
    background-color: rgba(0, 69, 112, 0.8) !important;
    line-height: 0;
    padding: 20px 0 !important;
  }

  .user-history-modal-area  input{
    background-color:  rgba(0, 69, 112, 0.8) !important;
    border: 1px solid rgba(0, 69, 112, 0.8) !important;
    color: #fff !important;
  }