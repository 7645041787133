.mobile-home-sft-area a {
  color: var(--text-primary);
}

.mobile-home-sft-area svg {
  font-size: 25px;
  color: var(--blue-6-seed);
}

.mobile-home-sft-area p {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
}

.mobile-home-sft-area > .row {
  --bs-gutter-x: 0;
}

.mobile-home-sft-area > .row div {
  padding: 0 !important;
}

.mobile-home-sft a svg {
  border-radius: 10px;
  padding: 12px;
  font-size: 20px;
  font-weight: 400;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  border: 1px solid #2b3139;
}

.mobile-home-sft a p {
  font-size: 12px;
  font-weight: 600;
  color: #848e9c;
  padding-top: 5px;
}

/* @media (min-width: 300px) and (max-width: 350px) {
    .mobile-home-sft-area{
        width: 270px;
        margin: auto;
    }
  }
  @media (min-width: 350.1px) and (max-width: 450px) {
    .mobile-home-sft-area{
        width: 320px;
        margin: auto;
    }

    .mobile-home-sft a svg{
        font-size: 25px;

    }
  }
  @media (min-width: 450.1px) {
    .mobile-home-sft-area{
        width: 350px;
        margin: auto; 
    }

    .mobile-home-sft a svg{
        font-size: 25px;

    }
  } */

@media (min-width: 370px) {
  .mobile-home-sft a svg {
    font-size: 25px;
    padding: 15px;
  }
}
