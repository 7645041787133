.menu-indicator-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: #1d113d;
  /* background: #181A20; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20x 0 0;
  font-family: "Poppins", sans-serif;
  z-index: 999;
  border-top: 1px solid #2B3139;
}

.menu-indicator-navigation ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;
  align-items: center;
}

.menu-indicator-navigation li {
  position: relative;
  list-style: none;
  z-index: 1;
}

.menu-indicator-navigation li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.menu-indicator-navigation a .icon svg {
  position: relative;
  display: block;
  line-height: 75px;
  height: 25px;
  width: 25px;
  text-align: center;
  transition: 0.5s;
  color: #FCD535;
}


.menu-indicator-navigation a .text {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  transition: 0.5s;
  padding-top: 5px;
}




.menu-indicator-navigation .list-link{
  color: #848E9C;
}
.menu-indicator-navigation .list-link .active{
  color: #fff;
}