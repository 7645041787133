.login-section {
    /* background: #1475BC; */
    min-height: 100vh;
  }
  .login-area{
      padding: 30px 0 80px 0;
  }
  .login-img {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #353945;
  
  }
  
  .login-are {
    padding: 200px 0;
  }
  .login-area .login-card {
    width: 380px;
    margin: auto;
    background-color: #fff;
    background-size: 400% 400%;
    border: 2px solid #596280;
    border-radius: 25px;
    background-color: white;
    padding: 0.75rem 1rem;
  }
  
  
  .login-form-area .input-group label{
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      color: #B1B5C3;
      display: block;
  }
  
  .login-form-area .input-group {
      padding-bottom: 20px;
      
  }
  
  .login-form-area .input-group input {
      width: 100%;
      border-radius: 12px !important;
      border: 2px solid #353945;
      box-shadow: none;
      background: none;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      line-height: 1.71429;
      font-weight: 500;
      transition: border-color .2s;
      height: 48px;
      padding: 0 14px;
      color: #000;
  }
  
  .login-form-area .input-group input[type="text"], .login-form-area .input-group input[type="email"], .login-form-area .input-group input[type="tel"], .login-form-area .input-group input[type="password"], .login-form-area .input-group textarea {
      -webkit-appearance: none;
      box-shadow: none;
  }
  
  .login-form-area .input-group input:focus {
      border-color: #777E90 !important;
  }
  .login-form-area .input-group input::placeholder {
      color: #777E90 !important;
      font-size: 16px;
  }  
  .remember {
      display: flex;
      align-items: center;
  }
  .remember label{
      color: gray !important;
      font-size: 14px !important;
      padding-left: 5px !important;
      cursor: pointer !important;
      font-weight: 500 !important;
  }
  .remember input{
      cursor: pointer !important;
  }
  .forget{
      text-align: end;
  }
  .forget a{
      color: #3772FF;
      transition: color .2s;
      font-size: 14px;
      font-weight: 500;
     
  }
  
  .submit-btn {
      width: 100%;
      margin-top: 32px;
  
      box-shadow: 0px 0px 14px -7px #19abf0;
      background-image: linear-gradient(45deg, #30b0e3 0%, #001ef7 51%, #30b0e3 100%);
      color: #FFF;
      background-size: 200% auto;
      transition: 0.5s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
  }
  
  .submit-btn:hover {
      background-position: right center;
      /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
    }
    
    .submit-btn:active {
      transform: scale(0.95);
    }
  
  
  
  .button-1 {
      padding: .325rem 1rem;
      border: none;
      border-radius:30px;
      font-weight: 600;
      min-width: 140px;
  
  
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 48px;
  
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      transition: all .2s;
  }
  
  .home-btn {
      position: fixed;
      left: 10px;
      bottom: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 10px;
      z-index: 1000;
      font-weight: 800;
      box-shadow: 0px 0px 14px -7px #19abf0;
      background-image: linear-gradient(45deg, #30d1e3 0%, #0040f7 51%, #30d1e3 100%);
      color: #FFF;
      background-size: 200% auto;
      transition: 0.5s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
  }
  
  
  .submit-btn:disabled,
  .submit-btn[disabled]{
    cursor: not-allowed;
  }
  
  .home-btn:hover {
      background-position: right center;
      /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
    }
    
    .home-btn:active {
      transform: scale(0.95);
    }
  
  
  
  
  
    .toggle-eye svg{
      background: none;
      border: none;
      color: #337ab7;
      /*display: none;*/
      /*font-size: .9em;*/
      font-weight: 600;
      /*padding: .5em;*/
      position: absolute;
      right: .75em;
      top: 45%;
      z-index: 9;
      cursor: pointer;
    }
    
    .toggle-eye .active svg{
      color: #ff0000;
    }