
.mobile-user-trade-section  .bg-all {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    padding-bottom: 2vh;
}

.mobile-user-trade-section  .mobile-view {
    min-height: calc(100vh - 88px);
    padding-bottom: 3.5rem;
    padding-top: 40px;
}
.mobile-user-trade-section  .tab-content {
    padding-bottom: 40px;
}

.mobile-user-trade-section  .bg-dashboard {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 15px;
}

.mobile-user-trade-section  .section_two {
    position: relative;
    /* height: 22vh; */
}


.mobile-user-trade-section  .section_div {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    /* position: absolute; */
    top: -30px;
    padding-bottom: 5PX;
}

/* @media only screen and (max-width: 767px) {
    .mobile-user-trade-section  [class^="section"] {
        margin-bottom: 64px;
    }
} */

.mobile-user-trade-section  .section_div_div {
    position: relative;
    height: 100px;
    padding-bottom: 5px;
    text-align: center;
}

.mobile-user-trade-section  .section_div_btn {
    height: 55px;
    width: 55px;
    padding: 15px;
    background: #2B3139;
    border-radius: 10px;
    /* box-shadow: 10px 0px 10px 0px #2B3139; */
}

.mobile-user-trade-section  .section_div_btn_svg {
    height: 25px;
    width: 25px;
}

.mobile-user-trade-section  .section_div_p {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 65px;
    color: #848E9C !important;
    font-size: 14px;
    opacity: 0.8;
    font-weight: 600;
}

.mobile-user-trade-section  .section_div_two {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    /* position: absolute; */
    top: 85px;
}

.mobile-user-trade-section  .coin_style_div {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.mobile-user-trade-section  .coin_style_h6 {
    width: 100%;
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    margin-top: 0px;
    font-size: 16px;
}

.mobile-user-trade-section  .shadow-md {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.mobile-user-trade-section  .coin_style_div_div-img {
    height: 50px;
    width: 50px;
}

.mobile-user-trade-section  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.mobile-user-trade-section  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.mobile-user-trade-section  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.mobile-user-trade-section  .crypto .active{
    color: #fff !important;
}

.mobile-user-trade-section  .metal .active{
    color: #fff !important;
}

.mobile-user-trade-section  .energises .active{
    color: #fff !important;
}

.mobile-user-trade-section  .stock .active{
    color: #fff !important;
}

.mobile-user-trade-section  .indices .active{
    color: #fff !important;
}

.mobile-user-trade-section  .forex .active{
    color: #fff !important;
}

.mobile-user-trade-section h5{
    color: #fff !important;
}
.mobile-user-trade-section p{
    color: #848E9C !important;
}

.coin_menu a{
    border-bottom: 1px solid #2B3139 !important;
}


.text-muted {
    color: rgb(198 205 212 / 75%) !important;
    display: block;
}

