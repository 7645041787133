.user-mobile-header-section{
    padding: 10px 0;
}
.user-mobile-header-logo svg{
	font-size: 20px;
	line-height: 30px;
	color: #FCD535;
	cursor: pointer;
}

.user-mobile-header-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-mobile-header-icon svg{
   font-size: 20px;
   line-height: 30px;
   color: #EAECEF;
   cursor: pointer;
}
.user-mobile-header-icon a:nth-child(1){
   padding-right: 15px;
}

.user-mobile-header-icon svg:hover{
   color: #FCD535;
}




.noUserSelect {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}



/* ========== Page header (Top bar) ========== */

.page-header {
	width: 100%;
	width: 100%;
	position: fixed;
	background: #6472cd;
	top: 0;
	z-index: 500;
	height: 64px;
	max-height: 64px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}



/* Search bar (header) */

.searchBarMain {
    background-color: #1d113dd6;
	border-radius: 5px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: relative;
	border:  1px solid #1d113dd6;
    padding:  2px;
    height: 30px;
    display: flex;
    align-items: center;
	width: 100%;
	margin: 0 3vw;
}
.searchBarMain:hover{
	border:  1px solid #FCD535;
}

.searchBarMain i.searchBarSearchIcon {
	height: 100%;
	line-height: 42px;
	float: left;
	text-align: center;
	color: rgba(68, 68, 68, 0.5);
}

.searchBarMain input#searchBarInput {
	box-sizing: border-box;
	background: transparent;
	outline: none;
	font-size: 16px;
	color: #fff;
	font-weight: 400;
    width: 100%;
}
.searchBarMain #searchBarInput::placeholder {
	font-size: 16px;
	color: #fff !important;
}


.searchBarMain i.clearSearchBarField:hover {
	cursor: pointer;
}

.searchBarMain svg{
    padding: 0 5px;
    color: #fff;
}



/* //////////////////////// */

  .support-dropdown-menu-item {
	background: #0075bb;
	border-radius: 8px;
	position: absolute;
    top: 35px;
    right: 0;
    width: 70vw;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	opacity: 0;
	display: none;
	transform: translateY(-20px);
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  }
  
  .support-dropdown-menu-item.active {
	opacity: 1;
	display: block;
	transform: translateY(0);
	z-index: 1;
  }
  
  .support-dropdown-menu-item ul {
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .support-dropdown-menu-item li {
	border-bottom: 1px solid rgba(0, 69, 112, 0.8);
  }
  
  .support-dropdown-menu-item li a {
	text-decoration: none;
	color: #333333;
	padding: 15px 20px;
	display: block;
  }
  
  .support-dropdown-menu{
	position: relative;
	padding-right: 10px;
  }