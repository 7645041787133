.popular-market-section{
    padding-top: 10px;
    padding-bottom: 40px;
}

.popular-market-title{
    padding: 10px 0;
}
.popular-market-title p{
    font-size: 14px;
    font-weight: 500;
    color: #848E9C;
}

.popular-market-list{
    padding-bottom: 30px;
}

.popular-market-list h6{
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.popular-market-list h6 span{
    font-size: 12px;
    font-weight: 500;
    color: #848E9C;
}
.popular-market-list  p{
    font-size: 12px;
    font-weight: 500;
    color: #848E9C;
    padding-top: 5px;
}

.popular-market-list-right{
    text-align: center;
}

.btn-2{
    padding: .5vw 2vw;
    color: #fff;
    background-color: #0EC67E;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
}

.btn-danger{
    background-color: #F6465D;
}

.mobile-home-main  .react-loading-skeleton::after {
    background-image: linear-gradient(90deg, #26272d, #26272d, #26272d) !important;
}


.mobile-home-main  .react-loading-skeleton {
    --base-color: #2c2b2b;
    --highlight-color: #181A20;
    background-color: #23252a !important;
}

