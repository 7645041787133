body {
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none !important;
}

.ol,
ul {
  padding: 0;
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

/* body {
      font-family: 'niveau_grotesk';
      font-weight: normal;
      background-image: url(./images/sail-flipped.jpg);
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: top center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  } */

/* top Header  */

@media (min-width: 980px) {
  #top-header.et-fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (min-width: 981px) {
  .et-fixed-header#top-header,
  .et-fixed-header#top-header #et-secondary-nav li ul {
    background-color: #014670;
  }
}

#top-header,
#top-header a,
#et-secondary-nav li li a,
#top-header .et-social-icon a:before {
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
}

#et-secondary-nav,
#top-header {
  -webkit-transition: background-color 0.4s, transform 0.4s,
    opacity 0.4s ease-in-out;
  -moz-transition: background-color 0.4s, transform 0.4s,
    opacity 0.4s ease-in-out;
  transition: background-color 0.4s, transform 0.4s, opacity 0.4s ease-in-out;
}

#top-header {
  z-index: 100000;
  color: #fff;
  font-size: 12px;
  line-height: 13px;
}

.clearfix:after,
.et_pb_wc_images .et_pb_module_inner:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
  content: " ";
}

#et-secondary-menu {
  float: right;
}

#et-secondary-nav,
#et-secondary-nav li {
  display: inline-block;
}
#et-secondary-nav,
#top-header {
  -webkit-transition: background-color 0.4s, transform 0.4s,
    opacity 0.4s ease-in-out;
  -moz-transition: background-color 0.4s, transform 0.4s,
    opacity 0.4s ease-in-out;
  transition: background-color 0.4s, transform 0.4s, opacity 0.4s ease-in-out;
}

ol,
ul {
  list-style: none;
}

#et-secondary-nav li {
  margin-right: 0px;
  padding: 0.77em 15px;
}
#et-secondary-nav li {
  position: relative;
  text-align: right;
}

#et-secondary-nav,
#et-secondary-nav li {
  display: inline-block;
}

#et-secondary-menu li,
#top-menu li {
  word-wrap: break-word;
}

li.pcap-link {
  background: #fff;
}
#top-header .pcap-link a {
  color: #2ea3f2;
}
#top-header .pcap-link a:hover {
  color: #1232e4;
}
#et-secondary-nav > li:last-child {
  margin-right: 0;
}

#et-secondary-nav li {
  margin-right: 0px;
  padding: 0.77em 15px;
}

#et-secondary-nav li {
  position: relative;
  text-align: right;
}

#top-header,
#top-header a,
#et-secondary-nav li li a,
#top-header .et-social-icon a:before {
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
}

#et-secondary-nav a {
  -webkit-transition: background-color 0.4s, color 0.4s ease-in-out;
  -moz-transition: background-color 0.4s, color 0.4s ease-in-out;
  transition: background-color 0.4s, color 0.4s ease-in-out;
  color: #fff;
}
#et-secondary-nav a:hover {
  color: #62a0c7;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0 !important;
}

/* main-header */

@media (min-width: 980px) {
  #main-header.et-fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
}

#main-header.et-fixed-header {
  box-shadow: none !important;
}

#main-header,
#main-header .nav li ul,
.et-search-form,
#main-header .et_mobile_menu {
  background-color: #0075bb;
}

@media only screen and (min-width: 1101px) {
  #main-header .et_menu_container {
    height: 100%;
  }
}

.container.clearfix.et_menu_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo_container {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
span.logo_helper {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
/* .logo_container a {
  font-size: 35px;
  color: #fff;
  text-transform: uppercase;
} */
.logo_container a img {
  height: 50px !important;
  width: auto !important;
}

@media (min-width: 1301px) and (max-width: 1500px) {
  #logo {
    width: 440px;
  }
}

#logo {
  max-height: 100% !important;
}

#logo {
  display: inline-block;
  float: none;
  max-height: 54%;
  margin-bottom: 0;
  vertical-align: middle;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translate3d(0, 0, 0);
}

img {
  max-width: 100%;
  height: auto;
}

#et-top-navigation {
  padding-left: 0 !important;
}

#et-top-navigation {
  font-weight: 600;
}

#top-menu,
.fullwidth-menu,
nav#top-menu-nav,
nav.fullwidth-menu-nav {
  float: left;
}

#top-menu,
#top-menu-nav {
  line-height: 0;
}

#top-menu,
.fullwidth-menu,
nav#top-menu-nav,
nav.fullwidth-menu-nav {
  float: left;
}

#top-menu,
#top-menu-nav {
  line-height: 0;
}

#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px;
}

#et-secondary-menu li,
#top-menu li {
  word-wrap: break-word;
}

.nav li {
  position: relative;
  line-height: 1em;
}

@media only screen and (min-width: 1101px) {
  #top-menu .menu-item-has-children > a:first-child,
  #et-secondary-nav .menu-item-has-children > a:first-child {
    padding-bottom: 29px !important;
  }
}

#et-secondary-nav .menu-item-has-children > a:first-child,
#top-menu .menu-item-has-children > a:first-child {
  padding-right: 20px;
}

@media only screen and (min-width: 981px) {
  .et-fixed-header #top-menu a,
  .et-fixed-header #et_search_icon:before,
  .et-fixed-header #et_top_search .et-search-form input,
  .et-fixed-header .et_search_form_container input,
  .et-fixed-header .et_close_search_field:after,
  .et-fixed-header #et-top-navigation .et-cart-info {
    color: #ffffff !important;
  }
  .et-fixed-header #top-menu a:hover {
    color: rgb(212, 208, 208) !important;
  }
}

@media only screen and (min-width: 1101px) {
  #top-menu li a {
    padding: 0 0;
  }
}

@media (min-width: 1301px) and (max-width: 1500px) {
  #top-menu li a {
    font-size: 14px !important;
  }
}

#top-menu li a {
  font-weight: 700;
}

#top-menu li a,
.et_search_form_container input {
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
}

#main-header .nav li ul {
  max-height: calc(100vh - 108px);
  overflow-x: hidden;
  overflow-y: auto;
}

#main-header,
#main-header .nav li ul,
.et-search-form,
#main-header .et_mobile_menu {
  background-color: #0075bb;
}

#top-menu li li {
  margin: 0;
  padding: 0 20px;
}

#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px;
}

#et-secondary-menu li,
#top-menu li {
  word-wrap: break-word;
}

.nav li li {
  position: relative;
  line-height: 2em;
}

.body-section {
  /* font-family: 'niveau_grotesk';
      font-weight: normal; */
  background-image: url("./sailflippedsmall.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* diligen area  */

@media only screen and (min-width: 981px) {
  .et_pb_section {
    /* padding-top: 50px !important; */
    padding: 65px 0 !important;
  }
}

@media only screen and (min-width: 981px) {
  .et_pb_section {
    padding: 150px 0;
  }
}

.et_pb_section {
  background-color: rgba(255, 255, 255, 0);
}

.et_pb_section {
  position: relative;
}

.et_pb_section,
.et_pb_slider .et_pb_slide {
  background-position: center;
  -webkit-background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: 100%;
  -moz-background-size: cover;
  background-size: 100%;
  background-size: cover;
  height: 100%;
  /* display: flex; */
}

@media only screen and (min-width: 981px) {
  .et_pb_row {
    padding: 0 !important;
  }
}

.container,
.et_pb_row,
.et_pb_slider .et_pb_container,
.et_pb_fullwidth_section .et_pb_title_container,
.et_pb_fullwidth_section .et_pb_title_featured_container,
.et_pb_fullwidth_header:not(.et_pb_fullscreen)
  .et_pb_fullwidth_header_container {
  max-width: 1280px;
}

.et_pb_row {
  position: relative;
  width: 80%;
  max-width: 1080px;
  margin: auto;
}

@media (min-width: 981px) {
  .et_pb_gutters1 .et_pb_column_1_2,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_2 {
    width: 50%;
  }
}

@media (min-width: 981px) {
  .et_pb_gutters1 .et_pb_column,
  .et_pb_gutters1.et_pb_row .et_pb_column {
    margin-right: 0;
  }
}

.et_pb_column.et_pb_column_1_2.et_pb_column_1.et_pb_css_mix_blend_mode_passthrough {
  float: right;
}

@media (min-width: 981px) {
  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child,
  .et_pb_row_inner .et_pb_column.et-last-child,
  .et_pb_row_inner .et_pb_column:last-child {
    margin-right: 0 !important;
  }
}

@media (min-width: 981px) {
  .et_pb_gutters1 .et_pb_column_1_2,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_2 {
    width: 55%;
  }
}

.et_pb_text > :last-child {
  padding-bottom: 0;
}

.et_pb_text_inner {
  position: relative;
}

.intro-block div.intro-statement {
  padding: 38px 38px 15px 38px;
}

.intro-block div.intro-statement h1 {
  font-size: 35px;
  line-height: 1.1em;
  text-transform: uppercase;
  padding-bottom: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.intro-block .intro-qualifier {
  font-family: "niveau_grotesk";
  font-weight: 400;
  line-height: 38px;
}

.intro-block div.intro-statement h1 {
  font-size: 35px;
  line-height: 1.1em;
  text-transform: uppercase;
  padding-bottom: 18px;
}

.intro-block div.intro-statement p {
  font-size: 16px;
  line-height: 25px;
  color: #000;
}

/* .intro-block div.intro-statement p {
  font-size: 21px;
  line-height: 28px;
  color: #000;
} */

p:not(.has-background):last-of-type {
  padding-bottom: 0;
}

.intro-block .third {
  background-color: #014670;
  color: #fff;
  text-align: center;
  margin: 0px 0.7% 0 0;
  width: 32.866666%;
  padding: 29px 0px 15px 0px;
  position: relative;
  z-index: 10;
  float: left;
  display: inline;
}

.intro-block .third::after {
  background-color: #0075bc;
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 5px;
  z-index: -1;
}

.intro-block .intro-number {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 10px;
  display: inline-block;
}

.intro-block .intro-label {
  font-size: 3vw;
  line-height: 30px;
}

.intro-block .intro-label {
  font-size: 16px !important;
}

.intro-block span.intro-qualifier::before,
.intro-block span.intro-label::before {
  content: " ";
  display: block;
}

.intro-block .third {
  background-color: #014670;
  color: #fff;
  text-align: center;
  margin: 0px 0.7% 0 0;
  width: 32.866666%;
  padding: 29px 0px 15px 0px;
  position: relative;
  z-index: 10;
  float: left;
  display: inline;
}

.et_column_last {
  clear: right;
  margin-right: 0 !important;
}

.intro-section .intro-block {
  width: 90%;
  float: right;
  background-color: rgba(255, 255, 255, 0.68);
}

/* <!-- ABOUT PROSPECT CAPITAL --> */

.et_pb_section.et_pb_section_1.et_section_regular.et_section_transparent {
  background: rgba(255, 255, 255, 0.6);
}

@media (min-width: 981px) {
  .et_pb_gutters2 .et_pb_column,
  .et_pb_gutters2.et_pb_row .et_pb_column {
    margin-right: 3%;
  }
}

.et_pb_column_3 {
  z-index: 9;
  position: relative;
}

.about-block h4 {
  background-color: #0075bc;
  padding: 1em 1em;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
}

.about-block .textwidget {
  padding: 2em 1em 2.7em !important;
}

.about-block .textwidget {
  padding: 2em 2em 2.7em 2em !important;
  margin: 0;
  background-color: #014670;
  line-height: 30px;
  min-height: 350px;
}

.about-block p {
  font-weight: normal;
  font-size: 20px;
  color: #fff;
}

.spacer {
  display: none;
}

/* .et_pb_column {
      float: left;
      position: relative;
      z-index: 9;
      background-position: center;
      background-size: cover;
      width: 22.75%;
  } */

@media only screen and (min-width: 981px) {
  .about-title {
    padding-bottom: 45px;
  }
}

@media (min-width: 981px) {
  .et_pb_gutters2 .et_pb_column,
  .et_pb_gutters2.et_pb_row .et_pb_column {
    margin-right: 3%;
  }
}

.about-card-area {
  text-align: center;
}

/* WHY INVEST IN PROSPECT? */

.why-invest-section {
  background: rgba(0, 69, 112, 0.8) !important;
}

.why-invest h4 {
  padding: 0;
  margin: 0 0 38px 0 !important;
  text-transform: uppercase;
  color: #fff !important;
  font-size: 22px;
}

@media only screen and (max-width: 1405px) {
  .why-invest .why-invest-blurb p {
    font-size: 18px;
  }
}

.why-invest .why-invest-blurb p {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6em;
  color: #fff;
  /* text-align: justify; */
  width: 90%;
}

.et_pb_image .et_pb_image_wrap {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.et_pb_image img {
  position: relative;
}

.why-invest-section .et_pb_image_wrap img:hover {
  border-radius: 50%;
  box-shadow: 0 0 20px #f5e858, inset 0 0 10px #f5e858;
}

.wi-label p {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding-top: 10px;
}

.btn-cntr1-area {
  text-align: center;
}

/* <!-- -----------------------------------QUOTE  ----------------------------------- --> */

div.et_pb_section.et_pb_section_3 {
  background-image: url("quote-image-crop-opacity-small.jpg") !important;
  background-size: cover;
}
.et_pb_section_3.et_pb_section {
  background-color: #ffffff !important;
}

.et_pb_row_6.et_pb_row {
  margin-top: 60px !important;
}

.et_pb_row {
  position: relative;
  width: 80%;
  margin: auto;
}

.et_pb_column.et_pb_column_4_4.et_pb_column_14.et_pb_css_mix_blend_mode_passthrough.et-last-child {
  width: 100% !important;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

.et_pb_row:after,
.et_pb_row_inner:after,
.et_pb_slides:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  line-height: 0;
  content: ".";
}

@media only screen and (max-width: 1400px) {
  .left-quote {
    text-align: left;
  }
}

@media only screen and (max-width: 1400px) {
  .curl-quote {
    margin: 0 5% !important;
  }
}

.et_had_animation {
  position: relative;
}

.et_pb_image {
  display: block;
}

@media only screen and (max-width: 1400px) {
  .curl-quote img {
    width: 15%;
  }
}

@media (min-width: 981px) {
  .et_pb_gutters2 .et_pb_column_4_4 .et_pb_module,
  .et_pb_gutters2.et_pb_row .et_pb_column_4_4 .et_pb_module {
    margin-bottom: 1.5%;
  }
}

@media only screen and (max-width: 1400px) {
  .testimonial-quote {
    line-height: 1.4em;
    font-size: 22px;
  }
}

.testimonial-quote {
  margin: auto 20%;
  margin-top: 28px;
  line-height: 1.5em;
  font-size: 25px;
}

.et_pb_bg_layout_light {
  color: #004670 !important;
}

.et_pb_module {
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.et_pb_image_7 {
  text-align: right;
  margin-right: 0;
}

.testimonial-sig {
  margin-top: 33px;
  margin-left: 60%;
  font-weight: bold;
  font-size: 25px;
  line-height: 1.3em;
}

.et_pb_text_18 {
  letter-spacing: 0;
  line-height: 1.7em;
}

.et_pb_module {
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

/* <!-- ----------------------------------- CHARTS  ----------------------------------- --> */

.et_pb_section_4.et_pb_section {
  background-color: #ffffff !important;
}

.chart-section {
  text-align: center;
  border-top: solid #0075bc;
  border-bottom: solid #0075bc;
  border-top-width: 8px;
  border-bottom-width: 8px;
}

.chart-block .et_pb_column:first-child {
  margin-right: 0 !important;
  border-right: solid #004670;
  padding-right: 5%;
}

.chart-section .chart-label {
  text-transform: uppercase;
  margin-bottom: 0px;
}

.et_pb_text_19 {
  letter-spacing: 0;
  line-height: 1.7em;
}

@media (max-width: 1423px) {
  .chart-block .chart-label h1 {
    font-size: 1.9vw;
  }
}

.et_pb_text_19 h1 {
  font-size: 30px;
  letter-spacing: 0;
  line-height: 1em;
}

.chart-section .chart {
  margin-top: 60px;
}

/* <!-- -----------------------------------LATEST FROM PROSPECT  ----------------------------------- --> */

.et_pb_section_5.et_pb_section {
  background-color: #014670 !important;
}

.info-section .title {
  text-transform: uppercase;
  padding-bottom: 20px;
}

.et_pb_bg_layout_dark {
  color: #ffffff !important;
}

.et_pb_bg_layout_dark,
.et_pb_bg_layout_dark h1,
.et_pb_bg_layout_dark h2,
.et_pb_bg_layout_dark h3,
.et_pb_bg_layout_dark h4,
.et_pb_bg_layout_dark h5,
.et_pb_bg_layout_dark h6 {
  color: #fff !important;
}

.et_pb_divider_0 {
  height: 40px;
}

.et_pb_divider {
  position: relative;
  margin: 0 0 30px 0;
}
.et_pb_space {
  box-sizing: content-box;
  height: 23px;
}

.et_pb_space {
  border: none;
}

.info-section {
  color: #fff !important;
}

.et_pb_divider_0:before {
  border-top-color: #ffffff;
  border-top-width: 4px;
}

.et_pb_divider:before {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-top-width: 4px;
  border-top-style: solid;
  border-top-color: #eee;
  /* border-top-color: rgba(0, 0, 0, .1); */
  content: "";
}

.et_pb_divider_internal {
  display: inline-block;
  width: 100%;
}

.et_pb_divider:after,
.et_pb_space:after {
  display: table;
  content: "";
}

.et_pb_code_inner {
  position: relative;
}

.left-column-ticker {
  float: left;
  /* width: 64%; */
  font-weight: 500;
}

@media only screen and (max-width: 1400px) {
  .company-name {
    font-size: 1.6vw;
  }
}

.company-name {
  color: #fff;
  font-size: 24px;
  float: left;
  padding-bottom: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 1400px) {
  .stock-exchange-symbol {
    font-size: 1.15vw;
  }
}

.stock-exchange-symbol {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  padding-top: 10px;
}

@media only screen and (max-width: 1400px) {
  .stock-date {
    font-size: 1.55vw;
  }
}

.stock-date {
  font-size: 22px;
  clear: both;
  color: #fff;
  font-weight: normal;
}

@media only screen and (max-width: 1400px) {
  .stock-price {
    font-size: 4.5vw;
  }
}

.stock-price {
  color: #fff;
  font-size: 64px;
  font-weight: 400;
  padding-right: 10px;
  float: left;
  line-height: 50px;
}

@media only screen and (max-width: 1400px) {
  .delta {
    font-size: 1vw;
    padding-bottom: 1px;
  }
}

@media only screen and (max-width: 1920px) {
  .delta {
    font-size: 1vw;
    padding-bottom: 1px;
    padding-top: 5px;
  }
}

.delta {
  display: inline-block;
}
.positive {
  color: #55ff46 !important;
}

@media only screen and (max-width: 1400px) {
  .volume {
    font-size: 1.1vw;
  }
}

.stock-delay {
  float: right;
  font-size: 14px;
  padding-top: 14px;
}

.et_pb_blog_0 .et_pb_post .entry-title {
  font-size: 18px !important;
  letter-spacing: 0px !important;
  line-height: 28px !important;
}

.latest h2 {
  line-height: 1.4em;
  font-weight: normal;
  color: #fff !important;
}
.latest h2 a {
  color: #fff !important;
}

h2.entry-title {
  padding-bottom: 14px;
}

.et_pb_portfolio_item .et_pb_module_header a,
.et_pb_portfolio_item h3 a,
.et_pb_post .entry-title a,
.et_pb_post h2 a {
  text-decoration: none;
}

.et_pb_blog_0 .et_pb_post .entry-title {
  font-size: 20px !important;
  letter-spacing: 0px !important;
  line-height: 24px !important;
}

.latest .et_pb_post .post-meta {
  font-weight: 500;
  color: #fff !important;
  margin-bottom: 12px;
}

.et_pb_blog_0 .et_pb_post .post-meta,
.et_pb_blog_0 .et_pb_post .post-meta a {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.info-section .latest .post-meta a {
  color: #fff !important;
}

.et_pb_blog_0 .et_pb_post .post-content,
.et_pb_blog_0.et_pb_bg_layout_light .et_pb_post .post-content p,
.et_pb_blog_0.et_pb_bg_layout_dark .et_pb_post .post-content p {
  letter-spacing: 0;
  line-height: 1.7em;
}

.latest .et_pb_post .post-content p {
  font-weight: normal;
  color: #fff;
}

.et_pb_blog_0 .et_pb_post p {
  line-height: 1.7em;
}

.et_pb_blog_0 .et_pb_post .more-link {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: underline;
}

.post-content a {
  color: #fff;
}

@media only screen and (max-width: 1400px) {
  .info-section .ir .label {
    font-size: 1.4vw;
  }
}

.info-section .ir .label {
  /* line-height: 78px; */
  font-size: 20px;
  color: #fff;
  padding-bottom: 17px;
}

.info-section .ir .label p {
  color: #fff;
}

.label a {
  color: inherit;
}
.label a:hover {
  color: #f7dd05;
}
.ir-link {
  fill: #fff;
  color: #fff;
}

@media only screen and (max-width: 1400px) {
  .info-section .ir .icon {
    width: 25% !important;
  }
}

.info-section .ir .icon {
  float: left;
  margin-right: 4.5%;
  margin-bottom: 17px;
  width: 78px !important;
  fill: #fff;
}

@media only screen and (max-width: 1400px) {
  .email-icon {
    bottom: 5%;
  }
}

.email-icon {
  width: 12%;
  position: absolute;
  left: 3%;
  bottom: 4%;
}

@media only screen and (max-width: 1400px) {
  .email-top {
    font-size: 1.7vw;
  }
}

.email-top {
  font-size: 25px;
  color: #000;
  height: 55px;
  padding: 10px 15px 15px 19%;
  text-align: left;
  background: #fff;
}

.email-bottom {
  height: 80px;
  background: #0075bc;
  padding: 20px 15px 20px 19%;
}

div.email-button {
  height: 40px;
  width: 100%;
  color: #fff;
  background: #014670;
  /* background: #ff7800; */
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-color: #7aceff #0057da #7f3c00 #006cf1;
  /* border-color: #ffb97a #da6700 #7f3c00 #f17100; */
  font-family: "niveau_grotesk";
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* <!-- -----------------------------------ultimate platform   ----------------------------------- --> */

@media screen and (min-width: 840px) {
  .css-1c4craf {
    margin-top: 80px;
  }
}

.css-1c4craf {
  width: 100%;
  margin-top: 40px;
  box-sizing: border-box;
  overflow: hidden;
  order: -4;
}

@media screen and (min-width: 1280px) {
  .css-egs5au {
    max-width: 1120px;
    padding: 0px 65px;
  }
}

.css-egs5au {
  width: 100%;
  max-width: 1140px;
  padding: 0px 16px;
  margin: 0px auto;
  box-sizing: border-box;
  position: relative;
}

@media screen and (min-width: 840px) {
  .css-1wh3shw {
    width: 430px;
    text-align: left;
    margin-bottom: 24px;
  }
}

.css-1wh3shw {
  font-size: 40px;
  margin: 0px 0px 16px;
}

@media screen and (min-width: 840px) {
  .css-12kwjz {
    font-size: 40px;
    line-height: 48px;
  }
}

.css-12kwjz {
  display: inline-block;
  line-height: 48px;
  width: 80%;
  font-weight: 400;
  color: #000;
  /* color: rgb(108, 7, 196);
  background: linear-gradient(
      -45deg,
      rgb(108, 7, 196) 0%,
      rgb(22, 69, 182) 100%
    )
    text;
  -webkit-text-fill-color: transparent; */
}

@media screen and (min-width: 1280px) {
  .css-1ktfw2v {
    width: 400px;
  }
}

@media screen and (min-width: 480px) {
  .css-1ktfw2v {
    font-size: 16px;
    line-height: 26px;
  }
}

.css-1ktfw2v {
  font-weight: 500;
}

@media screen and (min-width: 1280px) {
  .css-1jk6h72 {
    display: flex;
    margin-top: 32px;
  }
}

@media screen and (min-width: 840px) {
  .css-1jk6h72 {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
}

.css-1jk6h72 {
  flex-wrap: wrap;
  margin: 8px -8px -8px;
}

.css-1ul5z79 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  max-width: 100%;
  padding: 11px 12px;
  color: rgb(74, 74, 74);
  line-height: 1;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s ease 0s, color 0.2s ease 0s;
  min-width: 200px;
  margin: 8px;
}

.Button_orangeOutline {
  border-color: #ff5722;
  color: #ff5722;
  font-size: 14px;
}

.Button {
  background: none;
  border: 1px solid transparent;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  -webkit-transition: border 0.2s ease, background-color 0.2s ease,
    color 0.15s ease;
  -o-transition: border 0.2s ease, background-color 0.2s ease, color 0.15s ease;
  transition: border 0.2s ease, background-color 0.2s ease, color 0.15s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.css-1td69o {
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.Button_orangeOutline {
  border-color: #ff5722;
  color: #ff5722;
  font-size: 14px;
}

.css-1td69o img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease 0s;
}

.css-whh5e5 {
  opacity: 1;
}

.css-q7lffx {
  opacity: 0;
}

.css-1ktx9jg {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
}

.css-1bj41xz {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 11px;
  line-height: 13px;
  color: rgb(155, 155, 155);
  font-weight: 500;
  margin-top: 4px;
  transition: color 0.2s ease 0s;
}

.Button_orangeOutline:focus,
.Button_orangeOutline:hover {
  background-color: #ff5722;
  border-color: #ff5722;
  color: #fff;
}

.Button,
.Button:active,
.Button:focus,
.Button:hover {
  text-decoration: none;
}

.css-2cp6gk img {
  max-width: 100%;
  vertical-align: top;
}

/*------------------------ Footer ----------------------- */
.footer-area {
  padding: 50px 0;
  background-color: #014670;
}

.footer-menu-area .col-4 {
  padding: 0;
}
.footer-menu li {
  display: block;
  padding-bottom: 10px;
}
.footer-menu a {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.footer-menu a:hover {
  color: #a3cde6;
}

.footer-bottom {
  padding-top: 30px;
}

.company-address h5 {
  color: #00fff5;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 10px;
}

.company-address p {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  padding-bottom: 4px;
  margin: 0;
}

.footer-menu-area .col-4 {
  padding: 0;
}

.footer-bottom .col-6 {
  padding: 0;
}
.footer-bottom p {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  padding-bottom: 4px;
  margin: 0;
}

.why-invest-section .row {
  -bs-gutter-x: 0px !important;
}

.invest-icon-row {
  -bs-gutter-x: 0px !important;
}
.invest-icon-row p {
  cursor: pointer;
}
/* trading-platform-area */
.market-area {
  padding: 140px 0 80px 0;
}

.trading-platform-area {
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
}

.trading-platform-title {
  text-align: center;
  padding: 10px 0;
}

.trading-platform-title h4 {
  display: inline-block;
  font-size: 36px;
  line-height: 48px;
  font-weight: 400;
  color: rgb(108, 7, 196);
  background: linear-gradient(
      -45deg,
      rgb(108, 7, 196) 0%,
      rgb(22, 69, 182) 100%
    )
    text;
  -webkit-text-fill-color: transparent;
}

.trading-platform-title p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  color: #535354;
}

.trading-platform-dis {
  width: 80%;
  margin: auto;
  text-align: justify;
}

.trading-platform-dis p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #0d0e0f;
}

.trading-platform-market {
  padding-top: 20px;
  width: 80%;
  margin: auto;
}
/* .trading-platform-market-image-one .lazy-load-image-loaded {
  width: 65px;
}
.trading-platform-market-image-three .lazy-load-image-loaded {
  width: 90px;
}
.trading-platform-market-image-four .lazy-load-image-loaded {
  width: 75px;
}
.trading-platform-market-image-two .lazy-load-image-loaded {
  width: 65px;
}
.trading-platform-market-image-six .lazy-load-image-loaded {
  width: 90px;
} */

.trading-platform-market img {
  width: 100%;
  cursor: pointer;
}
.trading-platform-marke-dis {
  padding-left: 20px;
  width: 100%;
}

/* .trading-platform-market .lazy-load-image-loaded {
  width: 100%;
} */

.trading-platform-market h6 {
  color: #0d0e0f;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.trading-platform-market p {
  color: #535354;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 8px;
}
.trading-platform-market-card {
  cursor: pointer;
}

.trading-platform-market .trading-platform-market-card:hover p {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.trading-platform-market .trading-platform-market-card:hover img {
  border-radius: 50%;
  box-shadow: 0 0 20px #f5e858, inset 0 0 10px #f5e858;
}

/* blog  */
.blog-details-area {
  padding-top: 120px;
  padding-bottom: 50px;
}
.blog-area {
  margin-top: 90px;
}

.blogs-title h5 {
  text-align: center;
  font-size: 30px !important;
  color: #0d0e0f;
  padding: 30px;
  margin: 0;
}

.blogs-articles article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.blogs-articles article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/* basic article elements styling */
.blogs-articles article h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: #0d0e0f;
  transition: color 0.3s ease-out;
}

.blogs-article-wrapper {
  background-color: #ffffff;
  height: 100%;
}

.blogs-article-wrapper figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.blogs-articles article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.blogs-article-body {
  padding: 10px 24px 24px 24px;
}

.blogs-article-body p {
  font-size: 15px;
  text-align: justify;
}

.blogs-articles article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #0d0e0f;
}

.blogs-articles article a:focus {
  outline: 1px dotted #28666e;
}

.blogs-articles article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

/* using the has() relational pseudo selector to update our custom properties */
.blogs-articles article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.blogs-articles {
  display: grid;
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

@media screen and (max-width: 960px) {
  .blogs-articles article {
    container: card/inline-size;
  }

  .blogs-article-body p {
    display: none;
  }
}

@container card (min-width: 380px) {
  .blogs-article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }

  .blogs-article-body {
    padding-left: 0;
  }

  .blogs-article-wrapper figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .blogs-article-wrapper figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.blogs-sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Blog Details //////////////////////////////// */

.blog-details-title h5 {
  padding-bottom: 20px;
  font-size: 30px;
  color: #0d0e0f;
}

.cs-blog {
  margin-bottom: 30px;
}

.cs-blog h2 {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 29px;
  margin: 0 0 11px;
  position: relative;
  text-transform: uppercase;
}

.cs-blog::after {
  clear: both;
  content: "";
  display: block;
}

ul.blog-list {
  list-style: outside none none;
  margin: -30px 0 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.blog-list.blog-slide {
  margin: 0;
}

.blog-list.blog-slider {
  margin: 0;
}

ul.blog-list li {
  float: left;
  list-style: outside none none;
  margin: 30px 0 0;
}

.blog-slide .slick-list {
  margin: 0 -15px;
}

ul.blog-list.blog-slide li {
  margin-bottom: 10px;
  margin-top: 0;
}

ul.blog-list li:first-child {
  border: 0 none;
}

ul.blog-list li figure {
  overflow: hidden;
  position: relative;
}

ul.blog-list li figure img {
  width: 100%;
}

ul.blog-list li .cs-text {
  border: 1px solid #f0f0f0;
  overflow: hidden;
  padding: 15px 20px;
}

.cs-blog-detail .cs-text .post-option {
  border-top: 1px solid #f0f0f0;
  float: left;
  padding-top: 10px;
  width: 100%;
}

.cs-blog-detail .cs-text .post-option span a {
  color: #777;
}

.widget ul.blog-list li .cs-text {
  height: auto;
  margin: 0;
  min-height: inherit;
  padding: 9px 0 13px;
}

ul.blog-list li .cs-text span {
  color: #8b919e;
  display: inline-block;
  font-size: 12px;
  line-height: 19px;
}

ul.blog-list li .cs-text p {
  margin-bottom: 12px;
}

ul.blog-list li .cs-text h5 {
  border-bottom: 1px solid #fff;
  font-size: 15px;
  margin: 0;
  min-height: 56px;
  padding: 0 0 5px;
}

ul.blog-list li .cs-text h5 a {
  color: #292c33;
}

ul.blog-list li .cs-text .readmore {
  float: right;
  font-size: 11px;
  line-height: 20px;
  padding-top: 6px;
  position: relative;
  text-transform: uppercase;
}

ul.blog-list .slick-list.draggable {
  overflow: hidden;
}

.cs-auther-name a {
  color: #999;
}

.blog-list .slick-arrow {
  background-color: #f9f9f9;
  float: left;
  height: 29px;
  margin: 5px 0 0 5px;
  text-align: center;
  width: 29px;
}

.blog-list .slick-arrow a {
  color: #999;
  font-size: 18px;
  line-height: 32px;
}

.cs-blog.classic {
  margin: 0 0 30px;
}

.cs-blog.classic ul {
  margin: 0;
}

.cs-blog.classic li {
  border-top: 2px solid #eceef0;
  float: left;
  list-style: outside none none;
  padding: 16px 0;
  width: 100%;
}

.cs-blog.classic p {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 -4px 0 0;
  vertical-align: middle;
  width: 100%;
}

.cs-blog.classic p i {
  color: #c4c6c8;
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.cs-blog.classic span {
  display: inline-block;
  float: right;
  font-size: 12px;
  text-align: right;
  vertical-align: middle;
}

.cs-blog.classic span i {
  color: #e2e5e8;
  float: right;
  font-size: 24px;
  margin: 2px 0 0 10px;
}

.cs-pagination-blog {
  margin-bottom: 30px;
}

.cs-blog.blog-medium {
  border-bottom: 0 none;
  margin: 0;
  padding-bottom: 30px;
}

.cs-blog.blog-medium::after {
  clear: both;
  content: "";
  display: block;
}

.cs-blog.blog-medium .blog-text .cs-post-title {
  clear: both;
}

.cs-blog .cs-media figure {
  position: relative;
}

.cs-blog .cs-media figure figcaption {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
}

.cs-blog .cs-media:hover figure figcaption {
  opacity: 1;
  visibility: visible;
}

.cs-blog.blog-medium .post-title h3 {
  margin-bottom: 0;
}

.cs-blog .post-title {
  margin-bottom: 10px;
}

.cs-blog.blog-medium .cs-media figure figcaption .cs-readmore a {
  color: #fff;
  font-size: 24px;
  left: 50%;
  margin: -10px 0 0 -65px;
  position: absolute;
  top: 50%;
  transform: scale(0.7);
}

.cs-blog.blog-medium .cs-media:hover figure figcaption .cs-readmore a {
  transform: scale(1);
}

.cs-blog.blog-medium:last-child {
  border-bottom: medium none;
  padding-bottom: 40px;
}

.blog-medium .cs-media {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  width: 37%;
}

.blog-modern .cs-media {
  display: inline-block;
  margin-right: -4px;
  vertical-align: middle;
  width: 48.6%;
}

.blog-medium .cs-media figure img,
.blog-modern .cs-media img {
  width: 100%;
}

.blog-medium .cs-media ~ .blog-text {
  display: inline-block;
  float: none;
  margin-right: 0;
  vertical-align: middle;
  width: 58%;
}

.blog-modern .blog-text {
  display: inline-block;
  margin-right: -4px;
  padding-left: 30px;
  vertical-align: middle;
  width: 51.4%;
}

.blog-modern .blog-text .cs-post-title {
  margin-bottom: 5px;
  padding-bottom: 1px;
  position: relative;
}

.blog-modern .blog-text .cs-post-title::after {
  bottom: 1px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 27px;
}

.blog-modern .blog-text .blog-separator {
  margin: 0 0 10px;
}

.blog-modern .blog-text .blog-separator::before {
  display: none;
}

.blog-medium .blog-text {
  width: 99.1%;
}

.blog-medium .blog-text p {
  display: inline;
  margin: 0 0 15px;
}

.blog-medium .blog-separator {
  margin: 0 0 10px;
}

.cs-blog .cs-categories,
.cs-blog-detail .cs-categories {
  display: block;
  margin: 0 0 12px;
}

.cs-blog .cs-categories a,
.cs-blog-detail .cs-categories a {
  border-bottom: 2px solid #ededed;
  color: #55a747;
  display: inline-block;
  font-size: 10px;
  margin-right: 5px;
  padding-bottom: 2px;
  text-transform: uppercase;
}

.cs-blog-detail .post-option {
  float: right;
}

.cs-blog .post-option span a,
.cs-blog-detail .post-option span a {
  color: #999 !important;
  display: inline-block;
  font-size: 12px;
  margin-right: 18px;
  vertical-align: middle;
}

.cs-blog .post-option span i,
.cs-blog-detail .post-option span i {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  vertical-align: middle;
}

.cs-blog-detail .post-option span.post-category i {
  margin: 0;
}

.cs-blog-detail .post-option .post-category a {
  margin-left: 10px;
  margin-right: 0;
}

.cs-blog-detail .post-option .post-date {
  margin-left: 18px;
}

.cs-blog-detail .cs-text .post-option span i {
  float: left;
  margin: 3px 8px 0 0;
}

.cs-blog.blog-grid figure img {
  width: 100%;
}

.cs-blog.blog-grid .cs-media ~ .blog-text {
  margin: -30px 0 0;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.cs-blog.blog-grid .cs-inner-bolg {
  background-color: #fff;
  display: inline-block;
  padding: 20px 25px;
  width: 100%;
}

.cs-blog.blog-grid .blog-text p {
  margin: 0 0 5px;
}

.cs-blog.blog-grid .post-option {
  line-height: normal;
  margin: 0 0 10px;
}

.cs-blog.blog-grid .post-option span {
  color: #8b919e;
  font-size: 10px;
  margin: 0 15px 0 0;
  position: relative;
  text-transform: uppercase;
}

.cs-blog.blog-grid .post-option span::before {
  background-color: #8b919e;
  border-radius: 100%;
  content: "";
  height: 3px;
  left: -10px;
  position: absolute;
  top: 5px;
  width: 3px;
}

.cs-blog.blog-grid .post-option span:last-child {
  margin: 0;
}

.cs-blog.blog-grid .post-option span:first-child::before {
  display: none;
}

.cs-blog.blog-grid .read-more {
  display: inline-block;
  font-size: 12px;
  position: relative;
}

.cs-blog.blog-grid .read-more::before {
  content: "";
  font-family: "icomoon";
  font-size: 14px;
  position: absolute;
  right: -15px;
  top: 0;
}

.blog-large .cs-media img {
  width: 100%;
}

.blog-large .cs-text {
  margin: 0 0 20px;
  position: relative;
  z-index: 1;
}

.blog-large .cs-media ~ .cs-text {
  background-color: #fff;
  margin: 0 auto;
  padding: 30px 0 0;
  width: 100%;
}

.cs-blog .cs-author,
.cs-blog-detail .cs-author {
  float: left;
  margin: 0 0 10px;
}

.cs-blog .cs-author figure,
.cs-blog-detail .cs-author figure {
  display: inline-block;
  height: 32px;
  margin: 0 10px 0 0;
  vertical-align: middle;
  width: 32px;
}

.cs-blog .cs-author figure img,
.cs-blog-detail .cs-author figure img {
  border-radius: 100%;
}

.cs-blog .cs-author .cs-text,
.cs-blog-detail .cs-author .cs-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.cs-blog .cs-author .cs-text a,
.cs-blog-detail .cs-author .cs-text a {
  color: #555;
  font-size: 13px;
}

.blog-large .post-option,
.cs-blog.blog-medium .post-option {
  float: right;
}

.cs-blog.blog-large .post-option span i,
.cs-blog.blog-medium .post-option span i {
  color: #cfcfcf;
}

.post-option span i {
  margin-right: 5px;
  transition: all 0.3s ease-in-out 0s;
}

.blog-separator {
  border-bottom: 1px solid #f1f1f1;
  display: inline-block;
  margin: 20px 0 25px;
  position: relative;
  width: 100%;
}

.blog-large .cs-text p {
  margin: 0 0 25px;
}

.blog-large .read-more {
  border: 1px solid;
  border-radius: 20px;
  display: inline-block;
  font-size: 12px;
  padding: 4px 20px;
  text-transform: uppercase;
}

.blog-large .cs-post-title {
  margin: 0 0 15px;
}

.blog-large .cs-post-title h3 {
  margin: 0;
}

.cs-blog-detail .cs-post-title h1 {
  margin: 0 0 10px;
}

.cs-blog-detail .cs-post-title::after {
  clear: both;
  content: "";
  display: block;
}

.cs-blog-detail .cs-main-post img {
  width: 100%;
}

.cs-blog-detail .cs-main-post {
  margin-bottom: 25px;
}

.cs-blog-detail .cs-admin-post .cs-media figure,
.cs-blog-detail .cs-admin-post .cs-media figure img {
  border-radius: 100%;
}

.cs-blog-detail .cs-admin-post .cs-text {
  overflow: hidden;
}

.cs-blog-detail .cs-admin-post {
  float: left;
  width: 40%;
}

.cs-blog-detail .cs-admin-post .cs-media {
  float: left;
  height: 46px;
  margin-right: 14px;
  width: 46px;
}

.cs-blog-detail .cs-author-name {
  color: #ccc;
  display: inline-block;
  font-size: 14px;
  margin-right: 20px;
  padding-top: 6px;
  vertical-align: middle;
}

.cs-blog-detail .cs-author-name strong {
  color: #55a747;
  display: block;
  line-height: 26px;
}

.cs-blog-detail .cs-more-post {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #ccc;
  font-size: 11px;
  padding: 6px 8px;
}

.cs-blog-detail .cs-social-share {
  float: right;
}

.cs-blog-detail .cs-social-media {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.cs-blog-detail .cs-social-media li {
  display: inline-block;
  list-style: outside none none;
  margin: 0;
  vertical-align: top;
}

.cs-blog-detail .cs-social-media li a {
  background-color: #fc6d4c;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: 13px;
  height: 28px;
  line-height: 30px;
  margin: 0 5px 5px 0;
  text-align: center;
  width: 28px;
}

.cs-blog-detail .cs-social-media li a.cs-more {
  line-height: 33px;
  padding: 0;
}

.cs-blog-detail .cs-social-media li a::before {
  display: none;
}

.cs-blog-detail .cs-social-media li a[data-original-title="facebook"] {
  background-color: #2b4a8b;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Facebook"] {
  background-color: #2b4a8b;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Tumblr"] {
  background-color: #32506d;
}

.cs-blog-detail .cs-social-media li a[data-original-title="tumblr"] {
  background-color: #32506d;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Dribbble"] {
  background-color: #ea4c89;
}

.cs-blog-detail .cs-social-media li a[data-original-title="dribbble"] {
  background-color: #ea4c89;
}

.cs-blog-detail .cs-social-media li a[data-original-title="stumbleupon"] {
  background-color: #eb4823;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Stumbleupon"] {
  background-color: #eb4823;
}

.cs-blog-detail .cs-social-media li a[data-original-title="rss"] {
  background-color: #f06c19;
}

.cs-blog-detail .cs-social-media li a[data-original-title="twitter"] {
  background-color: #1f94d9;
}

.cs-blog-detail .cs-social-media li a[data-original-title="linkedin"] {
  background-color: #10598c;
}

.cs-blog-detail .cs-social-media li a[data-original-title="google"] {
  background-color: #d83936;
}

.cs-blog-detail .cs-social-media li a[data-original-title="youtube"] {
  background-color: #b00;
}

.cs-blog-detail .cs-social-media li a[data-original-title="Youtube"] {
  background-color: #b00;
}

.cs-blog-detail .cs-social-media li a.cs-more .at4-icon {
  border-radius: 10px;
  margin: 5px 0 0 -2px;
}

.cs-blog-detail .cs-share {
  float: none;
  left: 0;
  margin: 0 15px 0 0;
  position: absolute;
  top: 0;
}

.cs-blog-detail .cs-share a {
  color: #333;
  font-size: 18px;
  font-weight: 700;
}

.cs-blog-detail .cs-share-detail::after {
  clear: both;
  content: "";
  display: block;
}

.cs-blog-detail .cs-share-detail {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  vertical-align: middle;
  width: 49%;
}

.cs-blog-detail .cs-post-option-panel {
  float: left;
  padding-top: 20px;
  width: 100%;
}

.cs-blog-detail .rich-editor-text p {
  margin-bottom: 30px;
}

blockquote,
.rich-text-editor blockquote {
  border-left: 4px solid;
  margin: 0 0 40px;
  padding: 20px 0 0;
  position: relative;
  width: 95%;
}

blockquote {
  background-color: #fcfcfc;
  font-style: italic;
  padding: 15px 40px 20px 50px !important;
}

blockquote,
blockquote span,
blockquote p {
  color: #777;
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}

blockquote .author-name a {
  color: #999;
  font-size: 11px;
}

blockquote.text-left-align {
  text-align: left;
}

blockquote.text-right-align {
  text-align: right;
}

blockquote.text-center-align {
  text-align: center;
}

blockquote::before,
.rich-text-editor blockquote::before {
  color: #eaeaea;
  content: "";
  font-family: "icomoon";
  font-size: 22px;
  font-style: normal;
  left: 24px;
  position: absolute;
  top: 15px;
  transform: scale(-1);
}

.rich-text-editor blockquote {
  background-color: #fcfcfc;
  font-style: italic;
  padding: 15px 40px 20px 50px;
}

.rich-text-editor blockquote p {
  margin: 0;
}

blockquote > span {
  margin: 0;
  position: relative;
}

blockquote > span.author-name::after {
  display: none;
}

blockquote > span::after {
  color: #eaeaea;
  content: "";
  display: inline-block;
  font-family: "icomoon";
  font-size: 22px;
  font-style: normal;
  margin: 0 0 0 8px;
  position: relative;
  top: 3px;
}

.cs-blog-detail .tags {
  display: inline-block;
  margin: 0 -4px 0 0;
  vertical-align: middle;
  width: 50%;
}

.cs-blog-detail .cs-tags {
  display: block;
  margin: 0 0 40px;
}

.cs-blog-detail .cs-tags .tags span {
  color: #333;
  display: inline-block;
  font-size: 18px;
  margin: 0 10px 5px 0;
}

.cs-blog-detail .cs-tags .tags ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.cs-tags ul li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 0 6px;
}

.cs-tags ul li a {
  display: block;
  font-size: 12px;
  margin: 0 8px 0 0;
}

.cs-tags .tags ul li a {
  background-color: #f5f5f5;
  border-radius: 20px;
  color: #777;
  padding: 2px 18px 3px;
}

.comment-respond {
  margin-bottom: 30px;
}

.comment-form ul {
  list-style: outside none none;
}

.comment-form ul li {
  margin-bottom: 30px;
}

.comment-form .cs-element-title h3 {
  margin: 0;
}

.comment-form form .input-holder {
  position: relative;
}

.comment-form form .input-holder i {
  color: #cecece;
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 15px;
}

.comment-form form .input-holder input[type="text"],
.comment-form form .input-holder textarea {
  border: 1px solid #e4e4e4;
  color: #999;
  font-size: 14px;
  height: 50px;
  margin-bottom: -1px;
  padding: 10px 20px;
  width: 100%;
}

.comment-form form .input-holder textarea {
  height: 214px;
  margin: 0 0 20px;
}

.comment-form form input[type="submit"] {
  background-color: #55a747;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 10px 30px;
  text-transform: uppercase;
}

.blog-detail {
  box-shadow: none;
}

.blog-detail .blog-list {
  float: left;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}

.blog-slider-next {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 10px;
}

.blog-slider-prev {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
}

.blog-detail::after,
.author-detail::after,
#comment ul li::after,
.blog-detail .blog-list::after,
.cs-packeges::after {
  clear: both;
  content: "";
  display: block;
}

.blog-title {
  margin-bottom: 25px;
}

.blog-title h3 {
  color: #282828;
  letter-spacing: -1px;
  line-height: 34px;
  margin: 0 0 10px;
}

.blog-detail .main-post {
  margin: 0 0 25px;
}

.blog-detail .main-post img {
  width: 100%;
}

.author-detail {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 22px;
}

.cs-admin figure {
  float: left;
  margin-right: 15px;
}

.cs-admin .cs-text {
  display: inline-block;
  overflow: hidden;
  padding-top: 8px;
}

.cs-admin .cs-text span {
  color: #ccc;
  display: block;
  font-size: 13px;
  line-height: 16px;
}

.cs-admin .cs-text strong {
  color: #282828;
  font-size: 14px;
  line-height: 18px;
}

.blog-detail h2 {
  line-height: 29px;
  margin: 0 0 11px;
  position: relative;
  width: 91%;
}

.rich-editor-text p {
  clear: both;
  line-height: 24px;
  margin-bottom: 20px;
}

/* Blog Details End ////////////////////////////////////////////////////////////////////////////////// */

.loader-section {
  height: 100vh;
  margin: auto;
}

.h1-25 {
  font-size: 25px !important;
}
.h1-30 {
  font-size: 30px !important;
}

.trading-platform-area {
  background: rgba(255, 255, 255, 0.6);
}

/*------------------------------------ Portfolio --------------------------------*/

.PagePortfolio #irwWrapper.container {
  padding-top: 15px;
}
.portfolio-area {
  /* padding-top: 80px; */
  padding-bottom: 50px;
}

.portfolio-area h2 {
  color: #035f8d;
  float: left;
  font-size: 26px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 5px;
}
@media (min-width: 881px) {
  .module_title.pageTitle {
    padding-top: 115px;
  }
}

@media (min-width: 981px) {
  #main-content .container:before {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background: none;
    content: "";
  }
}

#irwWrapper .form-control,
#irwWrapperPR .form-control,
.irwcommodal .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#irwWrapper * {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}

.PagePortfolio .form-search.form-control-feedback {
  position: absolute;
  right: 22px;
  top: 10px;
}

.fa-search:before {
  content: "\f002";
}

.footable-header {
  background-color: #f5f5f5;
}

.footable-header th {
  font-size: 14px;
  color: #796666;
  padding: 8px !important;
}
.footable td {
  font-size: 14px;
  color: #796666;
  padding: 12px 8px !important;
}
.footable tr {
  padding-bottom: 550px;
  border-bottom: 1px solid #d3cece80;
}

#irwWrapper th {
  text-align: left;
}

.PagePortfolio .entry-content thead > tr > th {
  background-color: #f5f5f5;
  color: #666;
}

.entry-content thead th,
.entry-content tr th,
body.et-pb-preview #main-content .container thead th,
body.et-pb-preview #main-content .container tr th {
  padding: 9px 24px;
  color: #555;
  font-weight: 700;
}

.entry-content tr td,
body.et-pb-preview #main-content .container tr td {
  padding: 6px 24px;
  border-top: 1px solid #eee;
}

/* alll market  */

.all-market-name p {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;
}

.all-market-name p {
  font-size: 18px;
  padding-bottom: 2px;
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;
}

.all-market-name p:hover {
  color: var(--text-secondary);
}

.all-market-name .active {
  border-bottom: 1px solid var(--text-primary);
}

.total-market-name a {
  padding: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  border: 0px;
  color: inherit;
  cursor: pointer;
}

.all-market-details-top {
  padding: 20px 40px;
  background-color: var(--bg-secondary);
}

.total-market-name a svg {
  font-size: 18px;

  margin-left: 5px;
  color: rgb(255, 87, 34);
  transition: color 0.25s ease 0s, transform 0.3s ease 0s;
}

/* .total-market-name a:hover svg {
  padding-left: 15px;
  transition: color 0.25s ease 0s, transform 0.3s ease 0s;

} */

.market-trade-amount p {
  color: var(--text-primary);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 1px;
}

.market-trade-amount small {
  color: var(--text-secondary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.css-mkny2 {
  display: flex;
  position: relative;
  padding: 17px 24px 14px;
  border-bottom: 1px solid var(--border-button);
}

.css-4c2x90 {
  width: 38%;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-secondary);
  padding-right: 24px;
  box-sizing: border-box;
}

.css-g7jqpb {
  font-size: 12px;
  line-height: 20px;
  color: var(--text-secondary);
  width: 15%;
  box-sizing: border-box;
}

.css-1q6uvqr {
  width: 32%;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-secondary);
}

.market-img {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
}

.all-market-name small {
  line-height: 14px;
  color: var(--text-secondary);
  margin-top: 2px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  font-size: 12px;
  width: 100%;
  padding-right: 16px;
  box-sizing: border-box;
}

.all-market-name > td > button {
  padding: 5px 40px;
  font-weight: 400 !important;
}

.platform-bottom {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
/* .UltimatePlatform{
  width: 95%;
   margin: auto;
} */
.ultimate-platform-section {
  background-color: rgb(0 69 112 / 13%);
}

.sub-btn {
  justify-content: flex-end;
}

/* mobile menu  */

@media (max-width: 980px) {
  .container.clearfix.et_menu_container {
    display: block !important;
  }
}

@media (max-width: 980px) {
  #logo,
  #main-header,
  .container,
  .logo_container {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
}

span.logo_helper {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}

@media (max-width: 980px) {
  #logo,
  #main-header,
  .container,
  .logo_container {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
}

@media only screen and (max-width: 980px) {
  #et_mobile_nav_menu {
    display: block;
    float: none;
    position: relative;
  }
}

@media only screen and (max-width: 980px) {
  #main-header .mobile_nav {
    background-color: rgba(0, 0, 0, 0.05);
    border-right: 5px none;
    display: block;
    padding: 5px 10px;
    text-align: left;
  }
}

@media only screen and (max-width: 980px) {
  .mobile_nav .select_page {
    display: inline-block;
    color: #fff;
  }
}

#main-header,
#main-header .nav li ul,
.et-search-form,
#main-header .et_mobile_menu {
  background-color: #0075bb;
}

.et_mobile_menu {
  /* display: none; */
  visibility: visible;
  position: absolute;
  z-index: 9999;
  left: 0;
  width: 100%;
  padding: 5%;
  border-top: 3px solid #2ea3f2;
  opacity: 1;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.et-search-form,
.et_mobile_menu,
.footer-widget li:before,
.nav li ul,
blockquote {
  border-color: #2ea3f2;
}

.et_mobile_menu li a {
  display: block;
  padding: 5px 5% !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  color: #fff;
}

.et_mobile_menu li a,
.nav li li a {
  font-size: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#mobile_menu .pcap-link a {
  color: #2ea3f2;
}

.mobile_nav-svg svg {
  color: #fff;
}

.mobile-menu {
  display: none;
}

.desktop-menu {
  display: block;
}

/* faq  */

.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.faqs .faq {
  background: #fff;
  border-radius: 10px;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 16px;
  /* padding-left: 30px; */
  transition: all 0.4s ease;
  padding: 10px 0 10px 30px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  color: #796666;
  font-weight: 500;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.faq-area {
  display: none;
}

.tablet-footable-area {
  display: none;
}

.sub-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.market-title h1 {
  font-size: 25px;
  padding-bottom: 35px;
}

.full-portfolio-btn {
  display: none;
}

/* CSS */
.button-87 {
  margin: 10px;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #19abf0;
  background-image: linear-gradient(
    45deg,
    #3088e3 0%,
    #1951f0 51%,
    #00a4f5 100%
  );
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}
.footer h6 {
  line-height: 25px;
}

.app-button a {
  min-width: 230px;
}
.app-button button {
  min-width: 230px;
}

.py-80 {
  padding: 80px 0px !important;
}

.about-propect img {
  width: 40%;
  height: auto;
  margin: auto;
}

/*  */

.diligent-section {
  padding: 90px 0 !important;
}

.desktop-footer-menu {
  display: block;
}

.mobile-footer-menu {
  display: none;
}

.nav ul li .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 230px;
  z-index: 99;
  border-radius: 5px;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(35px);
  -moz-transform: translateY(35px);
  -ms-transform: translateY(35px);
  -o-transform: translateY(35px);
  transform: translateY(35px);
  background: #fff;
  box-shadow: 0 0 1.25rem rgba(30, 34, 40, 0.06);
}

.nav ul li ul {
  padding: 10px 20px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nav ul li ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: var(--primary-color);
  transition: width 0.3s ease-in-out;
}

.nav ul li ul::before {
  width: 100%;
}

.nav ul li ul:hover a {
  color: var(--primary-color);
}

.nav ul li ul a {
  color: #000;
  font-size: 14px;
}

.nav li ul {
  z-index: 9999;
  width: 240px;
  border-top: 3px solid #2ea3f2;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav li ul {
  position: absolute;
  top: 25px;
}

.sub-menu .menu-item {
  display: block !important;
  padding: 5px 15px !important;
  border-bottom: 1px solid #0b5ed7;
}
.sub-menu {
  padding: 0 !important;
  background-origin: 5px;
}
.dropdwon-menu-show {
  position: relative;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
}

.dropdwon-menu-show:hover .sub-menu {
  visibility: visible !important;
  opacity: 1 !important;
}

.sub-menu button {
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff !important;
}
.sub-menu button:hover {
  color: rgb(212, 208, 208) !important;
}

.trade-results-modal-area .modal-header {
  background-color: #014670 !important;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-bottom: none !important;
}

.trade-results-modal-area .modal-content {
  background-color: #0075bb !important;
}

.trade-results-modal-area .timer .value {
  font-size: 30px;
  font-weight: 600;
}

.trade-timer {
  /* width: 25%;
  margin: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.trade-results {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.trade-results p {
  font-size: 16px;
  color: #fff;
  margin: 0 !important;
}

.diligent-signup {
  text-align: center;
  padding-bottom: 20px;
}
.diligent-signup a {
  font-size: 18px;
  font-weight: 500;
  padding: 8px 40px;
  border-radius: 20px;
  background-color: #0075bb;
  color: #fff;
}
.diligent-signup a:hover {
  background-color: #014670;
}

.diligent-signup-mobile {
  display: none;
}

.notice-close-btn button {
  background-color: #0075bb;
  color: #fff;
}

.notices-modal-show .modal-body {
  padding: 0 !important;
}
.notices-modal-show .modal-footer {
  border-top: 0 !important;
}

.mobile-investment-plan-section {
  display: none;
}
.mobile-user-investment-plan-section {
  display: none;
}

.desktop-invest-plan {
  display: block;
}

.mobile-invest-plan {
  display: none;
}

/*---------------------------------- All Markte css   start ------------------------------------------------------------*/

.market-area-sw a {
  text-decoration: none;
  font-weight: 400;
  color: #848e9c;
}

.market-area-sw .mobile-view {
  min-height: calc(100vh - 88px);
  /* background: #f1f2f4 !important; */
  padding-bottom: 3.5rem;
  padding-top: 60px !important;
}

.market-area-sw .bg-image {
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.market-area-sw .font-light {
  font-weight: 300;
}
.market-area-sw .text-dark {
  color: rgba(19, 19, 19, 1);
}
.market-area-sw .font-medium {
  font-weight: 500;
}
.market-area-sw .text-lg,
.text-xl {
  line-height: 1.75rem;
}
.market-area-sw .text-lg {
  font-size: 16px;
  color: #fff;
}
.market-area-sw .text-up {
  color: rgba(19, 206, 102, 1);
}
.market-area-sw .text-down {
  color: rgba(246, 0, 60, 1);
}
.market-area-sw .rounded-full {
  border-radius: 9999px;
}
.market-area-sw .hot-coin {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  background-color: #1d113dd6 !important;
}

.market-area-sw .hot-coin .hot-coin-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.market-area-sw .hot-coin .trend-img {
  height: 0.75rem;
}

.market-area-sw .hot-coin .trend-percent {
  border: 1px solid rgba(236, 236, 236, 1);
  padding: 0.25rem;
  width: 5rem;
}

.mobile-market-view .mobile-view {
  padding-top: 0px !important;
}

@media (min-width: 0px) and (max-width: 980px) {
  .market-area-sw .mobile-view {
    padding-top: 120px !important;
  }
  .user-mobile-market .mobile-view {
    padding-top: 25px !important;
  }
}

/* All Markte css   end////////////////////////////////////////////////////////////// */

/* #2B3139 */

/* form section ---------------------- */

.mobile-home-main .card {
  background-color: #1d113d !important;
}

.mobile-home-main .form-group label {
  color: #fff;
}
.mobile-home-main .form-group input {
  background-color: #100919!important;
  border: 1px solid #100919;
  color: #fff !important;
}
.mobile-home-main .form-group input::placeholder {
  color: #fff !important;
}
.mobile-home-main .form-group input:focus {
  border: 1px solid #fcd535 !important;
  box-shadow: none !important;
}
.mobile-home-main .form-group select {
  background-color: #100919 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}

.mobile-home-main .form-group select:focus {
  border: 1px solid #fcd535 !important;
  box-shadow: none !important;
}

.mobile-home-main .form-group pre {
  color: #fff !important;
}
.mobile-home-main .form-group p {
  color: #fff !important;
}
input::file-selector-button {
  background-image: linear-gradient(
    to right,
    #181a20,
    #181a20,
    #181a20 100%,
    #181a20 200%
  );
  color: #fff !important;
  cursor: pointer !important;
  border: none !important;
}

.mobile-home-main .form-group :disabled {
  background-color: #181a20 !important;
}

.mobile-history-table h3 {
  font-size: 3.5vw;
  padding: 10px 0;
}

/* form section ---------------------- */

/* custom pagination css  */

.mobile-page-number {
  display: none;
}
.mobile-custom-pagination .disabled > .page-link,
.page-link.disabled {
  color: #8f8d8d;
  pointer-events: none;
  background-color: rgb(11 6 25 / 38%);
  border-color: rgb(11 6 25 / 38%);
}
.mobile-custom-pagination .page-link:focus {
  box-shadow: 0 0 0 0.25rem #1d113d;
}
.mobile-custom-pagination .page-link {
  border: none;
}
.mobile-custom-pagination .page-link {
  color: #fff;
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
}

.mobile-custom-pagination a {
  font-size: 3vw;
}

.desktop-version-trade-item {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  background-color: #fff !important;
}

.desktop-version-trade-item .text-lg {
  color: #000 !important;
}

.desktop-version-trade-item img {
  width: 48px;
  height: auto;
  border-radius: 50%;
}

.order-filter-area {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.order-filter-area svg {
  color: #fcd535;
}

.order-filter-area p {
  padding-right: 2vw;
  color: #fcd535;
}
.order-filter-area .form-group select {
  border: 1px solid #fcd535 !important;
}
.trade-page-btn{
  padding-top: 5vw;
}
.trade-page-btn a{
  padding: 1vw 8vw;
}
.trade-page-btn .active {
  background-color: #0075bb;
  color: #fff;
}
