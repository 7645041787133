.mobile-sidebar-menu-area {
  display: block;
  padding: 0px;
  position: fixed;
  background-color: #1d113dd6;
  /* background-color: rgba(0, 0, 0, 0.4); */
  z-index: 100000000;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.mobile-sidebar-menu-close-btn {
  position: relative;
  height: 0px;
  width: 75%;
}
.mobile-sidebar-menu-close-btn svg {
  width: 20px;
  height: 20px;
  color: blue;
}
.mobile-sidebar-nav-area {
  /* display: flex !important; */
  overflow-y: auto;
}

.more_div_div {
  height: 96%;
  margin-top: 15px;
  overflow-y: auto;
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  /* background: #181a20; */
  border-radius: 10px;
  width: 70%;
  margin-left: 15px;
  position: relative;
  padding-bottom: 5px;
}

.nav_div_un {
  width: 90%;
  margin: 10px auto 0;
  height: auto;
  padding: 10px;
  margin-left: auto;
  margin-bottom: auto;
  background: #1d113d;
  /* background: #2b3139; */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  align-items: center;
}

.nav_div_un_div_un {
  width: 75%;
}

.nav_div_un_div_deux {
  width: 25%;
}

.rounded-full {
  border-radius: 9999px;
}

.mobile_canvas_menu {
  padding-bottom: 60px;
}

.mobile_canvas_menu .side_menu {
  list-style: none;
  margin: 1rem 0.375rem;
  padding: 0;
}
.user_peofile{
    display: flex;
    align-items: center;
}
.user_peofile img {
  width: 50px;
  height: auto;
  border-radius: 50%;
}

.mobile_canvas_menu .side_menu .menu-item {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  padding: 1rem 0.85rem;
  transition: 0.3s all ease-in-out;
  line-height: 14px;
  color: #FCD535;
  font-weight: 500;
  font-size: 0.875rem;
}

.more_div_div_span {
  position: absolute;
  padding: 4px;
  border-radius: 100%;
  background-color: white;
  top: 10px;
  right: -10px;
  cursor: pointer;
  z-index: 200000000;
}
