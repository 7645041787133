.mobile-news-grid .col {
  padding-bottom: 15px;
}
.mobile-news-grid .col .card {
  background-color: #2b3139;
}

.mobile-news-section .news-menu-area{
display: flex;
align-items: center;
justify-content: space-between;
}

.mobile-news-section .news-breadcrumb h5{
  font-size: 5vw;
  text-align: center;
}
.mobile-news-section ul .active {
  background-color: #2b3139;
  color: #fff !important;
  border-radius: 4px 4px 0 0;
}
.mobile-news-section ul .active a {
  color: #fff !important;
}
.mobile-news-section .news-menu-area ul li a {
  display: block;
  padding: 8px 14px;
  background-color: #fcd535;
  color: #000;
  margin-right: 5px;
}

.mobile-news-section .news-menu-area ul li a {
  display: block;
  padding: .5vw 2vw;
  background-color: transparent;
  color: #000;
  margin-right: 5px;
}
.mobile-news-section .news-view-area span{
  font-size: 3.5vw;
  font-weight: 500
}
.mobile-news-section .news-grid-area h5 a {
  color: #fff;
}

.mobile-news-grid img {
  border-radius: 5px;
}

.mobile-news-details-section .news-detail-all p {
  color: #fff;
}


.mobile-news-grid-area{
  padding: 0;
}



.mobile-news-lists-item  {
  display: flex;
}

.mobile-news-lists-item {
  box-shadow: rgb(68 69 70 / 40%) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  margin-bottom: 30px;
  border-radius: 10px;
}
.mobile-news-lists-item  img {
  width: auto;
  height: 100px;
  padding-right: 1vw;
  border-radius: 10px 0 0 10px;
}
.mobile-news-lists-item  .news-content {
  padding: 1.5vw 0;
}
.mobile-news-lists-item  h4 {
  font-size: 3vw;
  color: #fff;
  font-weight: 500;
  line-height: 3.5vw;
  width: 95%;
  padding-bottom: .5vw;
}
.mobile-news-lists-item  p {
  font-size: 1.8vw;
  color: #b4b3b3;
  font-weight: 400;
  line-height: 2vw;
  width: 95%;
  padding-bottom: .5vw;
}
.mobile-news-lists-item  span {
  font-size: 1.8vw;
  color: #b4b3b3;
  display: block;
}
.mobile-news-lists-item  a {
  font-size: 1.5vw;
  color: #000;
  background-color: #FCD535;
  padding: 1vw 2vw;
  border-radius: 1vw;
  font-weight: 600;
}
.mobile-news-lists-item  a:hover {
  color: #fff;
  background-color: #0166fc;
}

.news-view-area .active{
  color: #FCD535;
}

.news-detail-short_dis{
  color: #878686 !important;
  font-size: 4vw;
}

.mobile-news-details-section p{
  background-color: transparent !important;
}
.mobile-news-details-section .news-details-main img{
  border-radius: 20px;
  width: 100%;
}

@media (min-width: 650px) and (max-width: 900px) {
  .mobile-news-lists-item  img {
    width: auto;
    height: 130px;
  }

}
@media (min-width: 900.1px) and (max-width: 1500px) {
  .mobile-news-lists-item  img {
    width: auto;
    height: 230px;
  }

}