.mining-area{
    overflow: hidden;
}

.mining-area .card-custom {
    background: #031640;
    color: white;
    border-radius: 10px;
    border: 2px solid #5859f2;
    padding: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mining-area .badge-custom2 {
    background-color: #0181d9;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    transform: rotate(-35deg);
}

.mining-area .badge-custom {
    background-color: #5859f2;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    position: absolute;
    top: -15px;
    right: -15px;
    transform: rotate(-35deg);
}

.mining-area .text-box {
    background: #007bff;
    color: white;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    margin-top: 30px;
    width: 200px;
    padding: 5px 10px;
    border-radius: 10px;
    right: -45px;
    justify-content: end;
}


.mining-area .investment-info {
    font-size: 16px;
    margin: 15px 0;
}

.mining-area .countdown {
    background: #0b5ed8;
    border-radius: 6px;
    padding: 5px;
}

.mining-area .countdown-timer {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.mining-area .countdown-timer div {
    margin: 0 5px;
    padding: 2px 5px;
    background: #0e191a;
    border-radius: 6px;
}

.mining-area .form-control {
    border: 1px solid #596280;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #1A233A;
    color: #bcd0f7;
    padding: .75rem 1rem;
}

.mining-area .btn-custom {
    background: linear-gradient(to right, #8363F9, #41B8F9);
    border: none;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    margin-top: 20px;
    padding: 10px 20px;
    width: fit-content;
    align-self: flex-center;
}


/* history  */

.mining-area .card {
    background: #014670;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}

.mining-area .text-primary {
    color:  rgb(255, 211, 2) !important;
}
.mining-area .table-responsive{
    overflow-x: scroll;
}
.mining-area .table-responsive table{
    --bs-table-bg: #0075BB !important;
    background-color: #0075BB !important;
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
}
.mining-area .table>:not(caption)>*>*{
    color: #bcd0f7 !important;
}
.mining-area .table-responsive td{
    color: #fff !important;
}


/* ///// */
