.investment-plan-section .planwrap {
    /* background: #000539 url("https://bemarg.com/styles/images/plansbg.jpg") no-repeat left center;
      background-size: cover; */
    padding: 55px 0 30px;
  }
  
  .investment-plan-section .content {
    max-width: 1170px;
    margin: 0 auto;
  }
  .investment-plan-title {
    text-align: center;
    padding-bottom: 55px;
  }
  .investment-plan-title h4 {
    font-size: 30px;
    color: #000;
  } 
  
  .investment-plan-section .pricingTable {
    background: linear-gradient(to bottom, #0075bb 0%, #0038bb 100%);
    text-align: center;
    padding: 0 0 40px;
    border-radius: 50px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1;
  }
  .investment-plan-section .pricingTable:before {
    content: "";
    background: #014670;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: -1;
  }
  .investment-plan-section .pricingTable .pricingTable-header {
    color: #fff;
    background: linear-gradient(to bottom, #0075bb 0%, #0038bb 100%);
    padding: 20px 10px;
    border-radius: 50px 50px 0 0;
    text-transform: capitalize;
    position: relative;
    margin: 0 auto 40px;
  }
  .investment-plan-section .pricingTable .pricingTable-header:after {
    content: "";
    background: linear-gradient(to bottom, #0075bb 0%, #0038bb 100%);
    width: 35px;
    height: 25px;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: -25px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
  }
  .investment-plan-section .pricingTable .title {
    font-size: 35px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
  }
  .investment-plan-section .pricingTable .pricing-content {
    text-align: left;
    padding: 0;
    margin: 0 0 25px;
    list-style: none;
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  .investment-plan-section .pricingTable .pricing-content li {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.5px;
    padding: 0 0 0 28px;
    margin: 0 0 7px;
    position: relative;
  }
  .investment-plan-section .pricingTable .pricing-content li:last-child {
    margin: 0;
  }
  .investment-plan-section .pricingTable .pricing-content li:before {
    color: #5fe629;
    font-weight: 900;
    position: absolute;
    top: 1px;
    left: 0;
  }
  .investment-plan-section .pricingTable .pricing-content li.disable:before {
    content: "\f00d";
  }
  .investment-plan-section .pricingTable .price-value {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0 0 35px;
  }
  .investment-plan-section .pricingTable .price-value span {
    background: linear-gradient(to bottom, #0075bb 0%, #0038bb 100%);
    padding: 5px 20px;
    border-radius: 50px;
    display: inline-block;
  }
  .investment-plan-section .pricingTable .pricingTable-signup a {
    color: #fff;
    background: #0075bb;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 7px 30px 8px;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: inline-block;
    transition: all 0.3s ease-in-out;
  }
  .investment-plan-section .pricingTable .pricingTable-signup a:hover {
    color: #fff;
    background-color: var(--main-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .investment-plan-section .pricingTable .pricingTable-signup button {
    color: #fff;
    background: #0075bb;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 7px 30px 8px;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: inline-block;
    transition: all 0.3s ease-in-out;
  }
  .investment-plan-section .pricingTable .pricingTable-signup button:hover {
    color: #fff;
    background-color: var(--main-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .investment-plan-section .pricingTable.blue {
    --main-color: #014670;
    --main-color: #014670;
    width: 80%;
    margin: auto;
  }
  .investment-plan-section .pricingTable.orange {
    --main-color: #fe6612;
  }
  