
.trade-results-loss-icon svg{
    font-size: 80px;
    color: red;
    padding-bottom: 20px;
    display: flex;
    margin: auto;
}
.trade-results-win-icon svg{
    font-size: 80px;
    color: green;
    padding-bottom: 20px;
    display: flex;
    margin: auto;
}