.mobile-history-page-area{
    padding: 3vw 0;
}
.mobile-history-page-menu-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2vw;
}
.mobile-history-page-menu-item p{
 font-size: 4vw;
 padding: 1vw  !important;
 color: #FCD535;
 font-weight: 500;
}

.mobile-history-page-menu-item .active{
    color: #000;
    background-color: #FCD535;
    border-radius: 5px;
}


.mobile-history-item-area{
    padding: 4vw 0;
}

.mobile-history-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FCD535;
    margin-bottom: 20px;
    cursor: pointer;
}
.mobile-history-item p{
   font-size: 4vw;
   line-height: 3.5vw;
   color: #fff;
   font-weight: 600;
}
.mobile-history-item span{
   font-size: 3vw;
   color: #848E9C;
   font-weight: 400;
}

.text-write{
    color: #FCD535;
}

.history-details-modal-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

}

.history-details-modal-item p{
    font-size: 4vw !important;
    padding: 0 !important;
}

.mobile-history-section{
    background-color: #FCD535;
    padding: 2.5vw;
    border-radius: 10px;
}

.mobile-history-title h4{
    font-size: 5vw;
    color: #000 !important;
    padding-top: 2vw;
}

.mobile-latest-history{
    background-color: #2B3139 ;
    padding: 3vw 2vw;
    border-radius: 5px;
    border: none;
}

.mobile-history-item-left{
    display: flex;
    align-items:center;
}

.mobile-history-item-sn p{
    font-size: 2.5vw;
    color: #FCD535;
}
.mobile-history-item-sn span{
    color: #FCD535;
    padding-right: 1vw;
}

.mobile-history-item-right{
    text-align: end;
}