.user-home-slider {
    padding-top: 5px;
}
.user-home-slider img{
    border-radius: 20px;
    width: 100%;
    height: auto;
}

.mobile-slider-details-section img{
    padding: 0;
}
.mobile-slider-details-section .news-details-time span{
    font-size: 3vw;
}