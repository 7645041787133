/* .user-loan-area {
  margin: 0;
  padding: 0;
  background: #262626;
} */

.user-loan-area section {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 140px 0;
    background: #262626;
  }
  
  .user-loan-area .loan-card-tem-one .card,
  .user-loan-area .loan-card-tem-one .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, #f403d1, #64b5f6);
  }
  
  .user-loan-area .loan-card-tem-two .card,
  .user-loan-area .loan-card-tem-two .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, #ffec61, #f321d7);
  }
  
  .user-loan-area .loan-card-tem-three .card,
  .user-loan-area .loan-card-tem-three .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, #24ff72, #9a4eff);
  }
  
  .user-loan-area .title svg {
    color: #fff;
    font-size: 60px;
    line-height: 100px;
    font-weight: 900;
  }
  
  .loan-plane-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }
  
  .user-loan-area .title h2 {
    position: relative;
    margin: 20px 0 0;
    padding: 0;
    color: #fff;
    font-size: 20px;
    z-index: 2;
  }
  
  .user-loan-area .price {
    position: relative;
    z-index: 2;
  }
  
  .user-loan-area .price h4 {
    margin: 0;
    padding: 20px 0;
    color: #fff;
    font-size: 30px;
  }
  
  .user-loan-area .option {
    position: relative;
    z-index: 2;
  }
  
  .user-loan-area .option ul {
    margin: 0;
    padding: 0;
  }
  
  .user-loan-area .option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
  }
  
  /* .user-loan-area .card a {
    position: relative;
    z-index: 2;
    background: #fff;
    color: #262626;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    display: block;
    text-align: center;
    margin: 20px auto 0;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  } */
  
  /* .user-loan-area .card a:hover {
    text-decoration: none;
  } */
  
  .user-loan-area .form-control {
    border: 1px solid #596280;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 0.825rem;
    background: #1a233a;
    color: #bcd0f7;
    padding: 0.75rem 1rem;
  }
  
  .user-loan-area .submit-btn {
    padding: 0.325rem 1rem;
    border: none;
    border-radius: 5px;
    background: #ffd302;
    color: #1a233a !important;
    font-weight: 600;
    min-width: 140px;
  }
  