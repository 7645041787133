.mobile-trade-offcanvas-area {
    width: 70% !important;
    background: #FCD535;
}

.mobile-trade-offcanvas-area  .charts .nav {
    display: flex;
}

.mobile-trade-offcanvas-area  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #014670;
}

.mobile-trade-offcanvas-area  .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: .25rem;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
}

.mobile-trade-offcanvas-area  .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: .25rem;
    color: #000;
    font-weight: 500;
}

.mobile-trade-offcanvas-area  .coin_canvas_menu .coin_menu {
    list-style: none;
    padding-left: 0 !important; 
}



.mobile-trade-offcanvas-area  .coin_canvas_menu .coin_menu .menu-item.active {
    background: #014670;
    color: #fff !important;
}

.mobile-trade-offcanvas-area  .coin_canvas_menu .coin_menu .menu-item {
    padding: 12px;
    border-radius: 8px;
    color: #000;
    display: block;
}

.mobile-trade-offcanvas-area  .font-light {
    font-weight: 300;
}

.mobile-trade-offcanvas-area  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}





.mobile-trade-offcanvas-area  .coin_canvas_menu .coin_menu .menu-item .coin-percent.up {
    color: #00ff00;
}

.mobile-trade-offcanvas-area  .text-gray-400 {
    color: rgb(0 255 15);
    font-weight: 600 !important;
}

.mobile-trade-offcanvas-area  .trade-sidebar-price{
    font-weight: 400;
}

.mobile-trade-offcanvas-area  .coin_menu li {
    margin-bottom: 8px;
    border-bottom: 1px solid rgb(2 156 254 / 34%);
}

.mobile-trade-offcanvas-area  .nav li {
    padding-bottom: 8px;
}


.mobile-trade-offcanvas-area  .offcanvas-body {
   padding-top: 0px !important;
}

.mobile-trade-offcanvas-area  .offcanvas-header {
 padding-bottom: 0px !important;
}