
.assets-user-balance-area {
  padding: 20px 0;
}
.assets-user-balance-area h4 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: #848e9c;
  padding-bottom: 5px;
}
.assets-user-balance {
  display: flex;
  align-items: end;
}
.assets-user-balance h5 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.assets-user-balance span {
  font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding-left: 5px;
}

.assets-user-sub-balance p {
  font-size: 14px;
  font-weight: 400;
  color: #848e9c;
}

.assets-link-area {
  /* width: 60%; */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 20px 0;
}
.assets-link-area a {
  font-size: 14px;
  color: #848e9c;
}
.assets-link-area a svg{
  width: 30px;
  height: 30px;
  color: #FCD535;
  padding: 15px;
  border: 1px solid #2b3139;
  border-radius: 10px;
}

.assets-bottom-balance-area{
  padding: 30px 0;
}


.assets-bottom-balances-item{
  border-bottom: 1px solid #2b3139;
  padding-top: 20px;
  padding-bottom: 5px;
}
.assets-bottom-balances{
  display: flex;
  justify-content: space-between;
}
.assets-bottom-balance-name h5{
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.assets-bottom-balance-name p{
  font-size: 15px;
  color: #848e9c;
  font-weight: 400;
}
.assets-bottom-balance{
  text-align: end;
}

.assets-bottom-balance h6{
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.assets-bottom-balance p{
  font-size: 15px;
  color: #848e9c;
  font-weight: 600;
  padding: 5px 0;
}
.assets-bottom-balance span{
  font-size: 15px;
  color: #179C85;
  font-weight: 600;
  padding: 5px 0;
}



.user-mobile-market button{
  color: #FCD535;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #000;
  background-color: #FCD535;
}

/* @media (min-width: 0px) and (max-width: 250px) {
  .assets-link-area {
    width: 100%;
  }
}
@media (min-width: 250.1px) and (max-width: 350px) {
  .assets-link-area {
    width: 200px;
  }
}
@media (min-width: 350.1px) and (max-width: 450px) {
  .assets-link-area {
    width: 250px;
  }
}
@media (min-width: 450.1px)  {
  .assets-link-area {
    width: 300px;
  }
} */

@media (max-width: 310px)  {
 
.assets-link-area a svg{
  width: 25px;
  height: 25px;
  padding: 10px;
  border-radius: 8px;
}

}