.user-profile-area-btn a{
    font-size: 3vw;
    padding: 1.5vw 5vw;
}

.user-profile-kyc-data{
    padding: 3vw 0;
}
.user-profile-kyc-data h5{
    font-size: 3vw;
    color: rgb(167, 14, 14) !important;
}
.user-profile-kyc-data h6{
    font-size: 3vw;
    color: #FCD535 !important;
}