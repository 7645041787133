.PlatformApps-btn-area{
    padding: 20px 0;
    text-align: center;

}

.PlatformApps-btn:nth-child(1){
    padding-bottom: 35px;
}

.PlatformApps-btn button{ 
    background-color: #181A20;
    border-radius: 10px;
    color: #fff;
    padding: 10px 15px;
    display: block;
width: 100%;
} 
.PlatformApps-btn button span{ 
   margin-right: 10px;

}