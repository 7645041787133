:root {

  --background: #ffffff;
  --primary-blue: #4281ff;
  --tab-secondary: #1a3466;
  --text-primary: #0d0e0f;
  --text-secondary: #87909f;
  --accent: #8e05c2;
  --border: #333;
  --form-bg: #fff;
  --sub-menu-h6: #ffffff;

  --bg-tertiary: #111421;
  /* --bg-secondary: #0d0f18; */
  --border-card: #282a2c;

  --green-base: #52c41a;
  --green-1: #f6ffed;
  --green-2: #d9f7be;
  --green-3: #b7eb8f;
  --green-4: #95de64;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --green-8: #237804;
  --green-9: #135200;
  --green-10: #092b00;
  --magenta-base: #eb2f96;
  --magenta-1: #fff0f6;
  --magenta-2: #ffd6e7;
  --magenta-3: #ffadd2;
  --magenta-4: #ff85c0;
  --magenta-5: #f759ab;
  --magenta-6: #eb2f96;
  --magenta-7: #c41d7f;
  --magenta-8: #9e1068;
  --magenta-9: #780650;
  --magenta-10: #520339;
  --pink-base: #eb2f96;
  --pink-1: #fff0f6;
  --pink-2: #ffd6e7;
  --pink-3: #ffadd2;
  --pink-4: #ff85c0;
  --pink-5: #f759ab;
  --pink-6: #eb2f96;
  --pink-7: #c41d7f;
  --pink-8: #9e1068;
  --pink-9: #780650;
  --pink-10: #520339;
  --red-base: #f5222d;
  --red-1: #fff1f0;
  --red-2: #ffccc7;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --red-7: #cf1322;
  --red-8: #a8071a;
  --red-9: #820014;
  --red-10: #5c0011;
  --orange-base: #fa8c16;
  --orange-1: #fff7e6;
  --orange-2: #ffe7ba;
  --orange-3: #ffd591;
  --orange-4: #ffc069;
  --orange-5: #ffa940;
  --orange-7: #d46b08;
  --orange-8: #ad4e00;
  --orange-9: #873800;
  --orange-10: #612500;
  --yellow-base: #fadb14;
  --yellow-1: #feffe6;
  --yellow-2: #ffffb8;
  --yellow-3: #fffb8f;
  --yellow-4: #fff566;
  --yellow-5: #ffec3d;
  --yellow-6: #fadb14;
  --yellow-7: #d4b106;
  --yellow-8: #ad8b00;
  --yellow-9: #876800;
  --yellow-10: #614700;
  --volcano-base: #fa541c;
  --volcano-1: #fff2e8;
  --volcano-2: #ffd8bf;
  --volcano-3: #ffbb96;
  --volcano-4: #ff9c6e;
  --volcano-5: #ff7a45;
  --volcano-6: #fa541c;
  --volcano-7: #d4380d;
  --volcano-8: #ad2102;
  --volcano-9: #871400;
  --volcano-10: #610b00;
  --geekblue-base: #2f54eb;
  --geekblue-1: #f0f5ff;
  --geekblue-2: #d6e4ff;
  --geekblue-3: #adc6ff;
  --geekblue-4: #85a5ff;
  --geekblue-5: #597ef7;
  --geekblue-6: #2f54eb;
  --geekblue-7: #1d39c4;
  --geekblue-8: #10239e;
  --geekblue-9: #061178;
  --geekblue-10: #030852;
  --lime-base: #a0d911;
  --lime-1: #fcffe6;
  --lime-2: #f4ffb8;
  --lime-3: #eaff8f;
  --lime-4: #d3f261;
  --lime-5: #bae637;
  --lime-6: #a0d911;
  --lime-7: #7cb305;
  --lime-8: #5b8c00;
  --lime-9: #3f6600;
  --lime-10: #254000;
  --gold-base: #faad14;
  --gold-1: #fffbe6;
  --gold-2: #fff1b8;
  --gold-3: #ffe58f;
  --gold-4: #ffd666;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --gold-8: #ad6800;
  --gold-9: #874d00;
  --gold-10: #613400;
  --mocha-6: #3db485;
  --mocha-shadow: rgba(22, 185, 121, .15);
  --mocha-lighter: rgba(22, 185, 121, .08);
  --orange-6: #fa8c16;
  --blue-6: #1890ff;
  --bg-banner: #0d202d;
  --border-radius-base: 4px;
  --border-radius-sm: 2px;
  --height-base: 40px;
  --height-lg: 48px;
  --height-sm: 32px;
  --height-xs: 24px;
  --height-xl: 56px;
  --gray-1-white: #fff;
  --gray-2: #f7f8fa;
  --gray-3: #f2f4f6;
  --gray-4: #ebedf1;
  --gray-5: #d7dadf;
  --gray-6: #bac0ca;
  --gray-7: #a2a9b6;
  --gray-8: #87909f;
  --gray-9: #6e7583;
  --gray-10: #535963;
  --gray-11: #3c4047;
  --gray-12: #33373d;
  --gray-13: #2b2e33;
  --gray-14: #222429;
  --gray-15: #18191c;
  --gray-16: #111214;
  --gray-17: #0d0e0f;
  --gray-18-black: #000;
  --mask: rgba(0, 0, 0, .6);
  --toast: rgba(0, 0, 0, .8);

  --bg-5: #151617;
  --bg-4: #1a1b1c;
  --bg-3: #222324;
  --bg-2: #2c2d2e;
  --bg-1: #4b4e52;
  --divider: #1e1f20;
  --border-2: #333435;
  --border-1: #484b4f;
  --secondary-text: #73797f;
  --primary-text: #959da4;
  --subtitle: #cdcecf;
  --title: #151617;
  --shadow-color-0: rgba(0, 0, 0, .1);
  --shadow-color-1: rgba(0, 0, 0, .2);
  --shadow-color-2: rgba(0, 0, 0, .3);
  --shadow-color-3: rgba(0, 0, 0, .4);
  --skeleton: #1c3343;
  --skeleton-to: #2a475b;
  --scrollbar-thumb: #555a5f;
  --hongdou-6: #e9485a;

  --bg-top-banner: #000c1d;

  --text-button: #fff;
  --text-button-trade: #fff;
  --text-tooltip-primary: #ebedf0;
  --text-tooltip-secondary: #505152;
  --text-inverse: #151617;
  --up: var(--success-6-seed);


  --border-button: #2f3133;
  --border-input: #2f3133;
  --border-card-modal: #31353d;
  --border-button-modal: #49535c;
  --border-input-modal: #49535c;
  --border-inverse: #dde0e2;

  --fill-switch-bg: #25282e;
  --fill-tag: #19302a;
  --fill-button-disabled: #28292c;
  --fill-input: #1a1b1f;
  --fill-input-modal: #222429;
  --fill-button-secondary: #404347;
  --error-base: #e9485a;
  --error-shadow: rgba(233, 72, 90, .2);
  --warning-base: #faad14;
  --warning-shadow: rgba(250, 173, 20, .2);
  --info-base: #328ee3;
  --bg-11: #fff;
  --blue-1: #070d1a;
  --blue-2: #0f1e3b;
  --blue-3: #13274c;
  --blue-4: #284d99;
  --blue-5: #3567cc;

  --blue-7: #689aff;
  --blue-8: #8eb3ff;
  --blue-9: #b3cdff;
  --blue-10: #d9e6ff;
  --success-1: #01130c;
  --success-2: #022b1b;
  --success-3: #033d26;
  --success-4: #077046;
  --success-5: #09955d;
  --success-6-seed: #0bba74;
  --success-7: #3cc890;
  --success-8: #6dd6ac;
  --success-9: #9de3c7;
  --success-10: #cef1e3;
  --error-1: #1a070a;
  --error-2: #381015;
  --error-3: #4c151d;
  --error-4: #992b3a;
  --error-5: #cc394e;
  --error-6-seed: #ff4761;
  --error-7: #ff6c81;
  --error-8: #ff91a0;
  --error-9: #ffb5c0;
  --error-10: #ffdadf;
  --warning-1: #180c03;
  --warning-2: #361b06;
  --warning-3: #4c2708;
  --warning-4: #8d4710;
  --warning-5: #bc5f15;
  --warning-6-seed: #eb771a;
  --warning-7: #ef9248;
  --warning-8: #f3ad76;
  --warning-9: #f7c9a3;
  --warning-10: #fbe4d1;
  --korea-red-1: #150707;
  --korea-red-2: #290f0e;
  --korea-red-3: #521d1c;
  --korea-red-4: #7c2c2a;
  --korea-red-5: #a53a38;
  --korea-red-6-seed: #ce4946;
  --korea-red-7: #d86d6b;
  --korea-red-8: #e29290;
  --korea-red-9: #ebb6b5;
  --korea-red-10: #f5dbda;
  --korea-blue-1: #050b15;
  --korea-blue-2: #0a152b;
  --korea-blue-3: #142a55;
  --korea-blue-4: #1e4080;
  --korea-blue-5: #2855aa;
  --korea-blue-6-seed: #326ad5;
  --korea-blue-7: #5b88dd;
  --korea-blue-8: #84a6e6;
  --korea-blue-9: #adc3ee;
  --korea-blue-10: #d6e1f7;
  --pink-1: #190713;
  --pink-2: #310e25;
  --pink-3: #621c4a;
  --pink-4: #942b6f;
  --pink-5: #c53994;
  --pink-6: #f647b9;
  --pink-7: #f86cc7;
  --pink-8: #fa91d5;
  --pink-9: #fbb5e3;
  --pink-10: #fddaf1;
  --yellow-1: #160f02;
  --yellow-2: #2c1f04;
  --yellow-3: #583e09;
  --yellow-4: #845c0d;
  --yellow-5: #b07b12;
  --yellow-6: #dc9a16;
  --yellow-7: #e3ae45;
  --yellow-8: #eac273;
  --yellow-9: #f1d7a2;
  --yellow-10: #f8ebd0;
  --cyan-1: #021316;
  --cyan-2: #04252c;
  --cyan-3: #074b58;
  --cyan-4: #0b7084;
  --cyan-5: #0e96b0;
  --cyan-6: #12bbdc;
  --cyan-7: #41c9e3;
  --cyan-8: #71d6ea;
  --cyan-9: #a0e4f1;
  --cyan-10: #d0f1f8;
  --purple-1: #0f0917;
  --purple-2: #1f122e;
  --purple-3: #3d245c;
  --purple-4: #5c3789;
  --purple-5: #7a49b7;
  --purple-6: #995be5;
  --purple-7: #ad7cea;
  --purple-8: #c29def;
  --purple-9: #d6bdf5;
  --purple-10: #ebdefa;
  --kl-1: #f4742c;
  --kl-2: #714dd9;
  --kl-3: #29d6e1;
  --kl-4: #de7fc3;
  --kl-5: #f0b94c;
  --kl-6: #a3c581;
  --kl-7: #d6a595;
  --kl-8: #969999;
  --kl-9: #9ddcae;
  --kl-10: #aeb6f8;
  --warning-1: #fcf1e8;
  --opacity-1: rgba(180, 191, 214, .04);
  --opacity-2: rgba(180, 191, 214, .06);
  --opacity-3: rgba(180, 191, 214, .08);
  --opacity-4: rgba(180, 191, 214, .12);
  --opacity-5: rgba(180, 191, 214, .18);
  --opacity-6: rgba(180, 191, 214, .24);
  --opacity-7: rgba(180, 191, 214, .32);
  --opacity-8: rgba(180, 191, 214, .42);
  --opacity-9: rgba(180, 191, 214, .58);
  --opacity-10: rgba(180, 191, 214, .72);
  --text-disabled: var(--gray-6);
  --tint-orange-base: var(--warning-6-seed);
  --tint-orange-smooth: var(--warning-1);
  --tint-yellow-base: var(--yellow-6);
  --bg-modal: var(--gray-1-white);


  --divider-modal: var(--gray-4);
  --divider-modal-primary: #e6e9eb;
  --divider-modal-secondary: #edf0f3;
  --divider-modal-tertiary: #e9e9eb;
  --divider-trade-primary: #e7eaeb;
  --sidebar-menu-color: #25272C;

  --text-tertiary: var(--gray-7);
  --up: var(--success-6-seed);
  --pc-h1-text-color: #000000;
  --divider-primary: var(--gray-4);
  --home-community-gradient-color: rgba(247, 248, 249, .85);
  --bg-secondary: var(--gray-2);
  --bg111: #F7F8FA;
  --bg222: #ffffff;
  --gradient-bg-1: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 1) 59%, rgba(244, 237, 236, 0.7793242296918768) 100%);

  --home-btn-bg-1: #F7F8FA;
  --bs-border-color: var(--bg-5);
  --bs-border-color-2: #ebedf1;
  --black: #ffffff;
  --button-simple-black: #ffffff;
  --bg-101: #e4e5ec;
  --fill-menu-selected: var(--gray-2);
  --trade-card-bg: #e3e6f0;

  --skeleton-bg: #ebebeb;
  --skeleton-base-color: #ebebeb;
  --skeleton-highlight-color: #f5f5f5;
  --label-bg: #eee;
  --chat-p-bg-color: #f5f6f7;
  --chat-scrollbar-bg1: #878181;
  --pagination-bg: #dadbdd;
  --bonus-login-button: #79cef5;
}