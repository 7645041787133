.mobile-announcement-page-area{
    padding: 10px 0 90px 0;
}
.mobile-announcement-page-area .announcement-page {
    margin: 0 auto !important;
}

.mobile-announcement-page-area  .announcement-page-item .announcement-page-item-title {
    line-height: 20px;
    color: #848E9C;
}
.mobile-announcement-page-area  .announcement-page-item .announcement-page-item-title span {
    font-size: 14px;
    color: #FCD535;
}   

.mobile-announcement-page-area  .announcement-page-item {
    border: 1px solid #3b434c;
    background-color: #2B3139;
}


.mobile-announcement-page-area .announcement-page {
    max-width: 100%;
    min-width: 100%;
}

.announcement-page-item img{
    width: 100%;
    height: auto;
}

.notices-modal-show p{
font-size: 5vw;
color: #fff;
line-height: 6vw;
padding-bottom: 10px !important;
}
.notices-modal-show span{
font-size: 3.5vw;
color: #fff;
line-height: 3.5vw;
}

.notices-modal-show a{
    display: block;
    font-size: 14px;
    color: #FCD535;
    padding-top: 10px;
    cursor: pointer;
}

.mobile-announcement-title{
    text-align: center;
    padding: 5vw 0;
}

.mobile-announcement-title h5{
    font-size: 6vw;
    color: #FCD535 !important;
}

.announcement-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  2vw;
}

.announcement-header button svg{
    background-color: #FCD535 !important;
    width: 3.5vw;
    height: 3.5vw;
    padding: 1vw;
    border-radius: 50%;
    color: red;
}
.announcement-header h4{
    color: #FCD535 !important;
    font-size: 4vw;
}